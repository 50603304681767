/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonRow,
  IonSearchbar,
  IonToolbar,
  useIonLoading,
  useIonModal,
} from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import './Map.scss';

import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { fromLonLat } from 'ol/proj';
import { arrowUpCircle, informationCircle, locate, navigateCircle, options } from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { useSelector } from 'react-redux';
import MapWrapper from '../components/MapWrapper/MapWrapper';
import getUserLocation from '../lib/Geo';
import buildMarker from '../lib/buildMapMarker';
import client from '../lib/feathers';
import InfoModal from '../components/InfoModal';
import InformationCircleModal from '../components/InformationCircleModal';
import { BackgroundImage, checkHasImage } from '../components/ListingHeader';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

library.add(fas);

const Tab1 = (props) => {
  const { history } = props;
  const auth = useSelector((state) => state.auth);
  const sliderEl = useRef(null);
  const searchInputRef = React.useRef();
  const [present, dismiss] = useIonLoading();
  const [location, setLocation] = useState();
  const [features, setFeatures] = useState([]);
  const [venues, _setVenues] = useState();
  const [slideKey, setSlideKey] = useState();
  const [homeMarker, setHomeMarker] = useState();
  const [selectedVenue, setSelectedVenue] = useState();
  const [mapCenterLocation, setMapCenterLocation] = useState();
  const [mapCenter, setMapCenter] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [geoErrorMessage, setGeoErrorMessage] = useState();
  const [showNoResults, setShowNoResults] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  const setShowGeoError = (code) => {
    switch (code.toString()) {
      case 'Error: 0':
        setGeoErrorMessage('Please allow the browser access to your location.');
        break;
      case 'Error: 1':
        setGeoErrorMessage('Please change your system permissions to allow the browser access to location services.');
        break;

      case 'Error: 2':
        setGeoErrorMessage('We were unable to find your location at this time.');
        break;

      case 'Error: 3':
        setGeoErrorMessage('Location services timed out when trying to find your location.');
        break;
      default:
        break;
    }
  };

  const onGetLocation = async () => {
    await present('Looking for your location...');
    try {
      const result = await getUserLocation(true);
      const newCoords = {
        latitude: result.coords.latitude,
        longitude: result.coords.longitude,
        accuracy: result.coords.accuracy,
      };
      await dismiss();
      setLocation(newCoords);
    } catch (e) {
      await dismiss();
      setShowGeoError(e);
    }
  };

  const [presentInitialInfoModal, dismissInitialInfoMap] = useIonModal(InfoModal, {
    onDismiss: () => {
      dismissInitialInfoMap();
      onGetLocation();
    },
  });

  const [presentMarkerInfo, dismissMarkerInfo] = useIonModal(InformationCircleModal, {
    onDismiss: () => {
      dismissMarkerInfo();
    },
  });
  const [
    mapFilters,
    // setMapFilters
  ] = useState([]);
  const [
    isStrict,
    // setIsStrict
  ] = useState(false);
  const isMounted = useRef(false);
  const venuesStateRef = React.useRef(venues);
  const setVenues = (data) => {
    const sorted = data.sort((a, b) => {
      if (a.distance < b.distance) {
        return -1;
      }
      if (a.distance > b.distance) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    venuesStateRef.current = sorted;
    setSlideKey(new Date().getTime());
    _setVenues(sorted);
    setShowSlider(true);
  };
  const onInfoClick = () => {
    presentMarkerInfo();
  };

  const onMapMarkerClick = async (venueId) => {
    let clickedVenue;
    let venueIndex;
    for (let i = 0; i < venuesStateRef.current.length; i += 1) {
      if (venuesStateRef.current[i].venueId === venueId) {
        clickedVenue = venuesStateRef.current[i];
        venueIndex = i;
        break;
      }
    }

    setSelectedVenue(clickedVenue);
    setShowSlider(true);
    if (sliderEl.current) {
      sliderEl.current.swiper.slideTo(venueIndex);
    }
  };

  const onSlideChange = (index) => {
    setSelectedVenue(venues[index]);
  };

  const onMapMove = (loc) => {
    setMapCenterLocation({ longitude: loc[0], latitude: loc[1] });
    setShowSlider(false);
  };
  const formatDistance = (dInMeters) => {
    const inMiles = dInMeters * 0.00062137;
    const formatted = Math.round(inMiles * 100) / 100;
    return `${formatted} mi`;
  };

  const onFirstLoadInfoModalPopUp = () => new Promise((resolve) => {
    if (localStorage.getItem('hasVisited')) {
      dismissMarkerInfo();
      resolve();
    } else {
      localStorage.setItem('hasVisited', 'true');
      presentInitialInfoModal({
        onDismiss: () => {
          dismissMarkerInfo();
          resolve();
        },
      });
    }
  });

  const filterVenues = (venuesArray) => {
    if (mapFilters.length > 0) {
      const filteredVenues = venuesArray.map((venue) => {
        const tempVenue = { ...venue };
        if (venue.accommodationListings.length === mapFilters.length) {
          tempVenue.exactMatch = true;
        } else {
          tempVenue.exactMatch = false;
        }
        return tempVenue;
      });
      if (isStrict) {
        return [...filteredVenues].filter((v) => v.exactMatch === true);
      }
      return filteredVenues;
    }
    return venuesArray;
  };

  const getVenuesByLocation = async (loc, source) => {
    let result = [];
    try {
      await present('Loading listings...');
      const service = client.service('client/listing-search');
      result = await service.find({
        query: {
          ll: `${loc.longitude},${loc.latitude}`,
          source,
          accommodation: mapFilters,
        },
      });
      await dismiss();
    } catch (err) {
      await dismiss()
        .then(() => {
          throw new Error('Could not load listings');
        });
    }
    return filterVenues(result);
  };

  const onSearchClick = async () => {
    try {
      const sorted = await getVenuesByLocation(mapCenterLocation, 'center');
      setMapCenter('fit');
      setVenues(sorted);
    } catch (err) {
      console.log('unable to find listings from center', err);
      alert('Sorry, We are having trouble loading the listings right now');
    }
  };

  const getVenuesByName = async (e) => {
    if (e) {
      const sEl = await searchInputRef.current.getInputElement();
      sEl.blur();

      e.preventDefault();
    }
    try {
      await present('Loading listings...');
      const loc = mapCenterLocation;
      const service = client.service('client/listing-search');
      const result = await service.find({
        query: {
          ll: `${loc.longitude},${loc.latitude}`,
          name: searchQuery,
          source: 'center',
          accommodation: mapFilters,
        },
      });
      await dismiss();
      // setShowLoading(false);
      setMapCenter('fit');
      setVenues(result);
    } catch (err) {
      await dismiss();
      console.log('Unable to load listings:', err);
      // setShowLoading(false);
    }
  };

  const onSearchFilterClick = async () => {
    setShowFilter(true);
  };

  const onCardClick = (e, venue) => {
    e.preventDefault();

    history.push(`/listing/${venue.id}`);
  };

  const getGeolocationInitial = async () => {
    await onFirstLoadInfoModalPopUp();
    onGetLocation();
  };

  useEffect(() => {
    getGeolocationInitial();
  }, []); // eslint-disable-line

  useEffect(() => {
    // when location is set build the home marker
    if (location) {
      const dot = new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          color: '#800000',
          crossOrigin: 'anonymous',
          src: '/assets/bigdot.png',
          scale: 0.15,
        }),
      });
      const markerFeature = new Feature({
        geometry: new Point(fromLonLat([location.longitude, location.latitude])),
      });
      markerFeature.setStyle(dot);
      setHomeMarker(markerFeature);
    }
  }, [location]);

  const setCenterMapLocation = async () => {
    if (location) {
      try {
        setMapCenterLocation(location);
        const sorted = await getVenuesByLocation(location, 'user');
        setVenues(sorted);
      } catch (err) {
        console.log('Unable to find listings from center:', err);
        alert('Sorry, we are having trouble loading the listings right now');
      }
    }
  };
  useEffect(setCenterMapLocation, [location]);

  /*eslint-disable */
  useEffect(() => {
    if (isMounted.current) {
      return onSearchClick();
    } else {
      isMounted.current = true;
    }
  }, [mapFilters]);
  /* eslint-enable */

  useEffect(() => {
    // When venues change update the map
    if (venues) {
      const f = [];
      venues.forEach((v, i) => {
        f.push(buildMarker(v, i + 1, venues.length));
      });

      if (venues.length === 0) {
        setShowNoResults(true);
      }

      setFeatures([...f]);

      if (venues.length !== 0) {
        // setSelectedVenue(venues[0]);
        if (sliderEl.current) {
          sliderEl.current.swiper.slideTo(0);
        }
      }
    }
  }, [venues]);

  return (
    <IonPage className="mapPage">
      <IonHeader>
        <form action="#" method="POST" onSubmit={getVenuesByName}>
          <IonToolbar className="more-padding">
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonButtons slot="primary">
              <IonButton type="button" tabIndex="0" onClick={() => onSearchFilterClick()}>
                <IonIcon slot="icon-only" icon={options} />
              </IonButton>
            </IonButtons>
            <IonSearchbar ref={searchInputRef} placeholder="Search Wineries" value={searchQuery} onIonChange={(e) => setSearchQuery(e.detail.value)} enterkeyhint="search" inputmode="text" />
          </IonToolbar>
        </form>
      </IonHeader>
      <IonContent scrollX={false} scrollY={false} style={{ overFlow: 'hidden' }}>
        <IonAlert
          isOpen={geoErrorMessage !== undefined}
          onDidDismiss={() => setGeoErrorMessage(undefined)}
          cssClass="my-custom-class"
          header="Alert"
          subHeader="Location Error"
          message={`This application needs permission to use your location to find Wineries near you.<br/><br/>${geoErrorMessage}`}
          buttons={['OK']}
        />
        <IonAlert
          isOpen={showNoResults}
          onDidDismiss={() => setShowNoResults(false)}
          cssClass="my-custom-class"
          header="Search Error"
          subHeader="No Matches Found"
          message={
            "If you can't find what you are looking for send us an email at <a href='mailto:service@vinoseeker.com?subject=Missing Winery Listing'>service@vinoseeker.com</a> and we will get it added to our system."
          }
          buttons={['OK']}
        />
        <IonAlert
          isOpen={showFilter}
          onDidDismiss={() => setShowFilter(false)}
          cssClass="my-custom-class"
          header="Coming Soon"
          subHeader="Search Filters"
          message="Currently you can only display Wineries and Tasting Rooms. Soon you will be able to filter for Tasting Rooms, Wineries, Vinyards, Wine Bars and Wine Shops."
          buttons={['OK']}
        />

        <>
          {/* <ResultFilter
            // showFilter={showFilter}
            setShowFilter={setShowFilter}
            mapFilters={mapFilters}
            setMapFilters={setMapFilters}
            isStrict={isStrict}
            setIsStrict={setIsStrict}
          /> */}
          <MapWrapper features={features} selectedVenue={selectedVenue} onMapMove={onMapMove} homeMarker={homeMarker} onMapMarkerClick={onMapMarkerClick} mapCenter={mapCenter} />
          {!showSlider && (
            <>
              <div className="ion-text-center">
                <IonButton size="small" onClick={onSearchClick}>
                  Search from center
                  <IonIcon slot="end" size="small" icon={locate} />
                </IonButton>
              </div>
              <IonFab vertical="center" horizontal="center" style={{ paddingTop: 18, paddingLeft: 18 }}>
                <IonIcon icon={locate} />
              </IonFab>
            </>
          )}
          <IonFab vertical="top" horizontal="end" slot="fixed">
            {/* <IonFabButton size="small" color="light" onClick={onSearchClick}><IonIcon icon={locate} /></IonFabButton> */}
            <IonFabButton size="small" color="light" onClick={onGetLocation}>
              <IonIcon icon={navigateCircle} />
            </IonFabButton>
          </IonFab>

          <IonFab vertical="top" horizontal="start" slot="fixed">
            <IonFabButton size="small" color="light" onClick={onInfoClick}>
              <IonIcon icon={informationCircle} />
            </IonFabButton>
          </IonFab>
          {venues && !showSlider && (
            <IonFab vertical="bottom" horizontal="center" slot="fixed">
              <IonFabButton size="small" color="light" onClick={() => setShowSlider(true)}>
                <IonIcon icon={arrowUpCircle} />
              </IonFabButton>
            </IonFab>
          )}
          {(!auth.user || !auth.user.id) && (
            <div className={`join-on-map ${showSlider ? 'open' : ''}`}>
              <IonButton size="small" routerLink="/register">
                Join for free
              </IonButton>
              <IonButton size="small" routerLink="/login" color="light">
                Login Now
              </IonButton>
            </div>
          )}
          {venues && venues.length && (
            <div className={`ion-text-center slideContainer ${showSlider ? 'showSlider' : 'hideSlider'}`}>
              {/* {!showSlider && (
                <IonButton size="small" color="light" onClick={() => setShowSlider(true)}>
                  <IonIcon icon={arrowUpCircle} />
                </IonButton>
                )} */}
              <div>
                <Swiper pagination={false} slidesPerView="auto" onSlideChange={(event) => onSlideChange(event.activeIndex)} spaceBetween={10} ref={sliderEl} key={slideKey}>
                  {venues.map((venue, index) => (
                    <SwiperSlide key={`vs-id-${venue.id}`}>
                      <IonCard
                        button
                        style={{
                          '--color': 'var(--ion-color-primary)',
                          borderColor: 'var(--ion-color-primary)',
                        }}
                        className="locationCard"
                        type="button"
                        onClick={(e) => onCardClick(e, venue)}
                      >
                        <BackgroundImage source={venue}>
                          <div
                            className={checkHasImage(venue) ? 'listingBannerGradient' : ''}
                            style={{
                              height: '100%',
                              padding: '13px 16px',
                            }}
                          >
                            <IonCardContent style={{ padding: '0px' }}>
                              <IonRow>
                                <IonCol>
                                  <div className="circle ion-float-left">{index + 1}</div>
                                  <div className="ion-float-left ion-padding-start">
                                    {venue.accommodationListings && venue.accommodationListings.map((icon) => (
                                      <span key={icon.accommodation.id}>
                                        <FontAwesomeIcon icon={fas[icon.accommodation.iconId]} />
                                        <span>&nbsp;</span>
                                      </span>
                                    ))}
                                  </div>
                                </IonCol>
                                <IonCol>
                                  <span className="ion-float-right">{formatDistance(venue.distance)}</span>
                                </IonCol>
                              </IonRow>
                              <IonRow>
                                <IonCol>
                                  <h1 className="ion-text-nowrap ion-text-start ion-float-clear">{venue.name}</h1>
                                </IonCol>
                              </IonRow>
                            </IonCardContent>
                          </div>
                        </BackgroundImage>
                      </IonCard>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          )}
        </>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
