import React from 'react';
import {
  IonCard, IonIcon, IonItem, IonLabel, IonButton,
} from '@ionic/react';
// import { useSelector } from 'react-redux';
import { arrowForward } from 'ionicons/icons';
import { useHistory } from 'react-router';
import SipList from './SipList';
import './RecentCheckins.scss';

const RecentSips = (props) => {
  // const recentSips = useSelector((state) => state.profile.sips.slice(0, 5));
  const { sips: recentSips } = props;
  const history = useHistory();

  return (
    <>
      <IonCard className="ion-no-margin">
        <IonItem lines="full">
          <IonLabel>Recent Sips</IonLabel>
          <IonButton expand="block" onClick={() => history.push('/me/sips')}>
            <IonIcon icon={arrowForward} size="small" slot="icon-only" className="hover-effect" />
          </IonButton>
        </IonItem>
        <SipList sips={recentSips} />
      </IonCard>
    </>
  );
};

export default RecentSips;
