import React from 'react';
import {
  IonCard, IonItem, IonLabel,
} from '@ionic/react';
// import { useSelector } from 'react-redux';
// import { arrowForward } from 'ionicons/icons';
// import { useHistory } from 'react-router';
import PhotoList from './PhotoList';
import './RecentCheckins.scss';

const RecentPhotos = (props) => {
  // const recentSips = useSelector((state) => state.profile.sips.slice(0, 5));
  const { attachments } = props;

  return (
    <>
      <IonCard className="ion-no-margin">
        <IonItem lines="full">
          <IonLabel>Recent Photos</IonLabel>
        </IonItem>
        <PhotoList attachments={attachments} editable />
      </IonCard>
    </>
  );
};

export default RecentPhotos;
