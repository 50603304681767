import { configureStore } from '@reduxjs/toolkit';
import listingsReducer from './features/listingsSlice';
import authSlice from './features/authSlice';
import profileReducer from './features/profileSlice';
import friendsReducer from './features/friendsSlice';

export const store = configureStore({
  reducer: {
    listings: listingsReducer,
    auth: authSlice,
    profile: profileReducer,
    friends: friendsReducer,
  },
});

export default store;
