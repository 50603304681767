import client from '../feathers';

const getAllFriends = async () => {
  try {
    const service = client.service('client/user-friend');
    const query = {
      $sort: {
        createdAt: -1,
      },
    };
    const results = await service.find({ query });
    return results;
  } catch (err) {
    console.log('error loading friends', err);
    return null;
  }
};

const getFriend = async (otherUser) => {
  try {
    const service = client.service('client/user-friend');
    const data = await service.get(otherUser.id);
    return data;
  } catch (err) {
    console.log('error loading friend', err);
    return null;
  }
};

const patchFriend = async (friendId, status) => {
  try {
    const service = client.service('client/user-friend');
    const result = await service.patch(friendId, { status });
    return result;
  } catch (err) {
    console.log('Error updating friend status', err);
    return null;
  }
};

const removeFriend = async (friendId) => {
  try {
    const service = client.service('client/user-friend');
    await service.remove(friendId);
  } catch (err) {
    console.log('Error removing friend', err);
  }
};

const getAllFriendInvites = async () => {
  try {
    const service = client.service('client/user-friend-invitation');
    const query = {
      $sort: {
        createdAt: -1,
      },
    };
    const results = await service.find({ query });
    return results;
  } catch (err) {
    console.log('error loading invitations', err);
    return null;
  }
};

const getFriendInvite = async (otherUser) => {
  try {
    const service = client.service('client/user-friend-invitation');
    const result = await service.get(otherUser.id);
    return result;
  } catch (err) {
    console.log('error loading invitation status', err);
    return null;
  }
};

const createFriendInvite = async (otherUser) => {
  try {
    const service = client.service('client/user-friend-invitation');
    const result = await service.create({ recipientId: otherUser.id });
    return result;
  } catch (err) {
    console.log('There was an error sending a friend invitation:', err);
    return null;
  }
};

const patchFriendInvite = async (requestId, status) => {
  try {
    const service = client.service('client/user-friend-invitation');
    const result = await service.patch(requestId, { status });
    return result;
  } catch (err) {
    console.log('error updating friend request', err);
    return null;
  }
};

const removeFriendInvite = async (requestId) => {
  try {
    const service = client.service('client/user-friend-invitation');
    await service.remove(requestId);
  } catch (err) {
    console.log('Error removing friend', err);
  }
};

export {
  // friends
  getAllFriends,
  getFriend,
  patchFriend,
  removeFriend,
  // friend invites
  getAllFriendInvites,
  getFriendInvite,
  createFriendInvite,
  patchFriendInvite,
  removeFriendInvite,
};
