const feathers = require('@feathersjs/feathers');
// const socketio = require('@feathersjs/socketio-client');
const rest = require('@feathersjs/rest-client');
const axios = require('axios');
// const io = require('socket.io-client');
const auth = require('@feathersjs/authentication-client');

const API_URL = process.env.REACT_APP_API_URL;

const app = feathers();

// Socket.io is exposed as the `io` global.
// const options = {transports: ['websocket'], forceNew: true};
const options = {};
if (API_URL.indexOf('/api') !== -1) {
  options.path = '/api/socket.io';
}

// const socket = io(API_URL, options);
// app.configure(socketio(socket));

const restClient = rest(API_URL);
app.configure(restClient.axios(axios));

// incase we later have to do authentication
app.configure(
  auth({}),
);

export default app;
