import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonItem,
  IonIcon,
  IonNote,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonSpinner,
  IonTextarea,
  IonTitle,
  IonToolbar,
  IonChip,
  IonRefresher,
  IonRefresherContent,
  useIonAlert,
} from '@ionic/react';
import {
  camera,
  wine,
  chevronDownCircleOutline,
} from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import {
  setCheckinAttachments,
  setRecentCheckins,
  setSelectedCheckin,
  setListingFlights,
} from '../lib/features/listingsSlice';
import client from '../lib/feathers';
import { loadCheckin, loadCheckins } from '../lib/api-utils';
import { updateCheckinItem } from '../lib/features/profileSlice';
import scrollToBottom from '../lib/ScrollToBottom';
import SipList from '../components/SipList';
import ListingCheckinFlightListModal from '../components/ListingCheckinFlightListModal';
import VinoMarkdown from '../components/VinoMarkdown';
import ListingHeader from '../components/ListingHeader';

const ListingCheckinForm = (props) => {
  const allowedFileTypes = ['image/jpeg', 'image/webp', 'image/heic'];
  const maxImageSizeMB = 10;

  const { history, match } = props;

  const checkinId = match.params.id;

  const user = useSelector((state) => state.auth?.user);

  const {
    selectedCheckin: checkin,
    recentCheckins: checkins,
    listingFlights: flights,
    checkinAttachments: attachments,
  } = useSelector((state) => state.listings);

  const mostRecentCheckin = ([...checkins].reverse())[0];
  const sips = checkin?.sips;

  const isOwner = user?.id === checkin?.userId;

  const dispatch = useDispatch();

  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [editingNote, setEditingNote] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState();
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [noteText, setNoteText] = useState(checkin?.notes);
  const [isNoteDisabled, setIsNoteDisabled] = useState(false);

  const [showFlights, setShowFlights] = useState(false);

  const [presentAlert] = useIonAlert();

  const fileRef = useRef();

  const setAttachments = (atmts) => {
    dispatch(setCheckinAttachments(atmts));
  };

  // eslint-disable-next-line no-unused-vars
  const onEditNoteStart = async () => {
    if (isOwner) {
      setNoteText(checkin.notes);
      setEditingNote(true);
    }
  };

  // Handle opening Search Modal:
  const onNewSipClick = () => {
    setSearchIsOpen(true);
  };

  // eslint-disable-next-line no-unused-vars
  const onEditNoteComplete = async (e) => {
    e.preventDefault();

    if (!isNoteDisabled) {
      setIsNoteDisabled(true);
      try {
        const service = client.service('client/checkin');
        const checkinData = await service.patch(checkin.id, {
          notes: noteText,
        });
        // update the selected checkin
        dispatch(setSelectedCheckin(checkinData));
        dispatch(updateCheckinItem(checkinData));

        // update the item in the list in case we leave and come back
        const updatedCheckins = checkins.map((lc) => (lc.id !== checkinData.id ? lc : checkinData));
        dispatch(setRecentCheckins(updatedCheckins));

        setNoteText(checkin.notes);
      } catch (err) {
        console.log('Error editing note:', err);
      }
      setEditingNote(false);
      setIsNoteDisabled(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingNote(false);
    setNoteText(checkin.notes);
  };

  const openFileDialog = () => {
    fileRef.current.click();
  };

  const checkFileSize = (size) => {
    const mb = size / 1024 / 1024;
    return mb < maxImageSizeMB;
  };

  const onPhotoInputChange = (e) => {
    const invalidFiles = [];

    setFilesToUpload(Array.from(e.target.files).filter((file) => {
      if (allowedFileTypes.includes(file.type) && checkFileSize(file.size)) {
        return true;
      }
      invalidFiles.push(file.name);
      return false;
    }));
    if (invalidFiles.length) {
      presentAlert({
        message: `Only jpeg/webp/heic images under ${maxImageSizeMB}mb accepted. Cannot upload the following files: ${invalidFiles}`,
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          }],
      });
    }
  };

  const updateUploadingFiles = (file) => {
    const uf = { ...uploadingFiles };
    uf[file.path] = file;
    setUploadingFiles(uf);
  };

  const removeUploadingFile = (file) => {
    const uf = { ...uploadingFiles };
    delete uf[file.path];
    setUploadingFiles(uf);
  };

  const loadAttachments = async () => {
    const service = client.service('client/attachment-checkin');
    const query = {
      $limit: 100,
      $sort: {
        createdAt: 1,
      },
      checkinId,
    };

    const result = await service.find({
      query,
    });
    const attached = result.data.map((ac) => ac.attachment);
    setAttachments(attached);
  };

  const loadListingFlightsById = async (listingId) => {
    const service = client.service('client/flight');
    const query = {
      listingId,
      status: 'active',
    };
    const response = await service.find({ query });
    dispatch(setListingFlights(response.data));
  };

  const onLoad = async () => {
    try {
      const thisCheckin = await loadCheckin(checkinId);
      dispatch(setSelectedCheckin(thisCheckin));

      if (user.id === thisCheckin.userId) {
        const recentCheckins = await loadCheckins(thisCheckin.listing.id);
        dispatch(setRecentCheckins(recentCheckins));

        loadListingFlightsById(thisCheckin.listingId);
      }

      loadAttachments();
    } catch (err) {
      console.log(err);
      presentAlert({
        header: 'Whoops!',
        message: 'There has been an issue loading this Check-In.',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              history.push('/');
            },
          },
        ],
      });
    }
  };

  useEffect(async () => {
    await onLoad();
  }, [match]);

  useEffect(() => {
    if (filesToUpload) {
      const uploads = filesToUpload.map(
        async (aFile) => new Promise((resolve) => {
          const uploadFile = async (file) => {
            updateUploadingFiles(file);

            const id = uuidv4();
            const uploadService = client.service('client/attachment-upload');
            const data = {
              'file-name': `user-images/${id}`,
              'file-type': file.type,
              id: checkin.listing.id,
            };
            const urls = await uploadService.create(data);
            const signedUrl = urls.signedRequest;

            const options = {
              headers: {
                'Content-Type': file.type,
              },
              onUploadProgress(progressEvent) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                );
                file.progress = percentCompleted;
                updateUploadingFiles(file);
              },
            };

            await axios.put(signedUrl, file, options);

            const atd = {
              id,
              path: urls.path,
              type: 'photo',
              mime: file.type,
              userId: user.id,
              attachmentCheckins: [
                {
                  attachmentId: id,
                  checkinId: checkin.id,
                },
              ],
            };
            const attachmentService = client.service('client/attachment');
            const attachment = await attachmentService.create(atd);

            removeUploadingFile(file);
            resolve(attachment);
          };
          uploadFile(aFile);
        }),
      );

      Promise.all(uploads).then(() => {
        // if (onAttachmentsChange) {
        //   onAttachmentsChange([...listing.attachments, ...attachments]);
        // }

        setFilesToUpload(undefined);
        loadAttachments();
      });
    }
  }, [filesToUpload]);

  const onListingClick = () => {
    history.push(`/listing/${checkin.listing.id}`);
  };

  // "/listing/checkin/:id/photo/:attachmentId"
  const onPhotoClick = (attachment) => {
    history.push(`/listing/checkin/${checkin.id}/photo/${attachment.id}`);
  };

  const handleManualRefresh = async (e) => {
    await onLoad();
    e.detail.complete();
  };

  const getCheckinNotes = () => {
    if (checkin.notes) {
      return checkin.notes;
    }
    if (isOwner) {
      return 'Tap here to add notes about your visit.';
    }
    return 'No notes were added from this check-in.';
  };

  const getNoAttachmentText = () => {
    if (isOwner) {
      return 'Add photos to remember your check-in.';
    }
    return 'No photos were added from this check-in.';
  };

  if (checkin) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton defaultHref="/listing" />
            </IonButtons>
            <IonTitle>
              {moment(checkin.createdAt).tz(user.locale).calendar()}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          <IonRefresher slot="fixed" onIonRefresh={handleManualRefresh}>
            <IonRefresherContent
              pullingIcon={chevronDownCircleOutline}
              pullingText="Pull to refresh"
              refreshingSpinner="circles"
              refreshingText="Refreshing..."
            />
          </IonRefresher>
          {!isOwner && checkin.user && (
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', paddingTop: '16px' }}>
              <IonNote onClick={() => history.push(`/u/${checkin.user.username}`)}>
                {`@${checkin.user.username} checked into:`}
              </IonNote>
            </div>
          )}
          <IonCard onClick={onListingClick}>
            <ListingHeader listingData={checkin.listing} listingId={checkin.listing.id} height="15vh" />
          </IonCard>

          {checkin && (
            <IonCard>
              <IonItem lines="full">
                <IonLabel>
                  Notes
                </IonLabel>
              </IonItem>
              <IonCardContent>

                <form onSubmit={onEditNoteComplete}>
                  {!editingNote && (
                    <IonLabel onClick={onEditNoteStart}>
                      <VinoMarkdown>
                        {getCheckinNotes()}
                      </VinoMarkdown>
                    </IonLabel>
                  )}
                  {editingNote && (
                    <>
                      <IonTextarea
                        rows="4"
                        value={noteText}
                        disabled={isNoteDisabled}
                        onIonChange={(e) => setNoteText(e.target.value)}
                        onIonFocus={scrollToBottom}
                        placeholder="Add notes here..."
                        autocapitalize="sentences"
                        style={{ '--color': 'var(--ion-color-dark)' }}
                      />
                      {isNoteDisabled
                        && (
                          <IonSpinner name="dots" style={{ position: 'absolute', left: '45%', top: '40%' }} />
                        )}
                    </>
                  )}
                  {editingNote
                    && (
                      <IonLabel className="ion-justify-content-between ion-flex ion-no-margin">
                        <IonButton disabled={isNoteDisabled} onClick={handleCancelEdit} className="ion-margin" fill="clear">Cancel</IonButton>
                        <IonButton disabled={isNoteDisabled} type="submit" className="ion-margin" fill="outline">Save</IonButton>
                      </IonLabel>
                    )}
                </form>
              </IonCardContent>
            </IonCard>
          )}

          {/* Available Flights, but only if viewing most recent Check-In */}
          {isOwner && flights.length > 0 && (checkinId === mostRecentCheckin?.id)
            && (
              <IonCard onClick={() => setShowFlights(true)}>
                <IonItem lines="none">
                  <IonLabel>
                    <span>View Available Flights</span>
                  </IonLabel>
                  <IonChip>
                    <IonLabel>{flights.length}</IonLabel>
                    <IonIcon icon={wine} size="small" color="primary" />
                  </IonChip>
                </IonItem>
              </IonCard>
            )}

          <ListingCheckinFlightListModal
            isOpen={showFlights}
            dismissFlightListModal={() => setShowFlights(false)}
          />

          <IonCard>
            <IonItem lines="full">
              <IonLabel>
                Sips
              </IonLabel>
              {isOwner && (
                <IonButton
                  id="open-sip-modal"
                  fill="outline"
                  slot="end"
                  onClick={onNewSipClick}
                >
                  Add&nbsp;
                  <IonIcon icon={wine} size="1x" className="fa-fw" />
                </IonButton>
              )}
            </IonItem>

            <SipList
              sips={sips}
              checkin={checkin}
              searchIsOpen={searchIsOpen}
              closeSearch={() => setSearchIsOpen(false)}
              allowDelete={isOwner}
            />

            {isOwner && (
              <IonButton size="small" expand="full" fill="clear" onClick={onNewSipClick}>
                Add A New Sip
              </IonButton>
            )}
          </IonCard>

          <IonCard>
            <IonItem lines="full">
              <IonLabel>
                Photos
              </IonLabel>
              {isOwner && (
                <IonButton
                  disabled={filesToUpload}
                  fill="outline"
                  slot="end"
                  onClick={openFileDialog}
                >
                  Add&nbsp;
                  <IonIcon icon={camera} />
                </IonButton>
              )}
            </IonItem>

            <IonCardContent className="attachmentCard">
              <input
                type="file"
                ref={fileRef}
                id="photo-upload"
                multiple
                accept="image/jpeg"
                style={{ display: 'none' }}
                onChange={onPhotoInputChange}
              />
              {Object.keys(uploadingFiles).length > 0 && filesToUpload.map((uploadingFile) => (
                <div className="attachementWrapper" key={uploadingFile.lastModified}>
                  {uploadingFile.progress && (
                    <span className="label">{`Uploading ${uploadingFile.progress}%`}</span>
                  )}
                  <img
                    alt="gallery item"
                    src={URL.createObjectURL(uploadingFile)}
                  />
                </div>
              ))}
              {attachments.map((attachment) => (
                <div className="attachementWrapper" key={attachment.path}>
                  <div className="loader">
                    <IonIcon src={camera} size="large" />
                  </div>
                  <button
                    type="button"
                    onClick={() => onPhotoClick(attachment)}
                  >
                    <img
                      className="photo"
                      alt="gallery item"
                      src={`${process.env.REACT_APP_ATTACHMENT_URL}/${attachment.path}?w=1080&h=1080`}
                    />
                  </button>
                </div>
              ))}
              {Object.keys(uploadingFiles).length === 0 && attachments.length === 0 && (
                <IonLabel>
                  <VinoMarkdown>
                    {getNoAttachmentText()}
                  </VinoMarkdown>
                </IonLabel>
              )}
            </IonCardContent>
          </IonCard>
        </IonContent>
      </IonPage>
    );
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/listing" />
          </IonButtons>
          <IonTitle>Loading Check-In</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonProgressBar type="indeterminate" />
      </IonContent>
    </IonPage>
  );
};

export default ListingCheckinForm;
