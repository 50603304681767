import React, { useState } from 'react';
import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonItemDivider,
  IonPage,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from '@ionic/react';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import client from '../lib/feathers';
import scrollToBottom from '../lib/ScrollToBottom';

const SuggestionForm = (props) => {
  const { match, history } = props;
  const [reason, setReason] = useState();
  const [staff, setStaff] = useState(false);
  const [description, setDescription] = useState('');
  const [staffEmail, setStaffEmail] = useState('');
  const [validationMessage, setValidationMessage] = useState('');
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const { keyboardHeight } = useKeyboardState();
  const [present, dismiss] = useIonLoading();
  const emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/; // eslint-disable-line no-control-regex

  const onSubmitClick = async () => {
    const venueId = match.params.id;
    console.log('click', venueId, reason);
    if (!reason) {
      setValidationMessage('Please select the type of suggestion');
      setShowValidationMessage(true);
      return;
    }
    if (staff && staffEmail.toString().length === 0) {
      setValidationMessage('Please enter your email address');
      setShowValidationMessage(true);
      return;
    }
    if (staffEmail.toString().length !== 0 && emailRegEx.test(staffEmail.toString()) === false) {
      setValidationMessage('Please enter a valid email address');
      setShowValidationMessage(true);
      return;
    }
    if (reason === 'bad_info' && description.toString().length === 0) {
      setValidationMessage('Please tell us about what information is bad');
      setShowValidationMessage(true);
      return;
    }
    const data = {
      listingId: venueId,
      type: reason,
      staff,
      suggestion: description,
      email: staffEmail,
      status: 'new',
    };
    // console.log('all good in the hood', data);
    await present('Loading...');
    const service = client.service('client/suggestion');
    await service.create(data);
    setShowConfirmationMessage(true);
    await dismiss();
  };

  return (
    <IonPage>
      <IonAlert isOpen={showValidationMessage} onDidDismiss={() => setShowValidationMessage(false)} cssClass="my-custom-class" header="Form Error" message={validationMessage} buttons={['OK']} />
      <IonAlert
        isOpen={showConfirmationMessage}
        onDidDismiss={() => {
          setShowConfirmationMessage(false);
          history.goBack();
        }}
        cssClass="my-custom-class"
        header="Submission Complete"
        message="Thank you for your suggestion! We will review this ASAP!"
        buttons={['OK']}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Suggest an Edit</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        fullscreen
        style={{
          paddingBottom: keyboardHeight,
        }}
      >
        <IonCard>
          <IonRadioGroup value={reason} onIonChange={(e) => setReason(e.detail.value)}>
            <IonItemDivider>What type of suggestion are you making?</IonItemDivider>

            <IonItem>
              <IonRadio labelPlacement="end" justify="start" value="good_info">
                This is a real place
              </IonRadio>
            </IonItem>

            <IonItem>
              <IonRadio labelPlacement="end" justify="start" value="not_a_venue">
                This is not a real place
              </IonRadio>
            </IonItem>

            <IonItem>
              <IonRadio labelPlacement="end" justify="start" value="bad_info">
                Some data for this place is wrong
              </IonRadio>
            </IonItem>
          </IonRadioGroup>

          <IonItemDivider>Business Relation</IonItemDivider>

          <IonItem>
            <IonCheckbox checked={staff} onIonChange={(e) => setStaff(e.detail.checked)} justify="start" labelPlacement="end">
              I work at/own this business
            </IonCheckbox>
          </IonItem>
          {staff && (
            <IonItem>
              <IonInput label="Email" justify="start" labelPlacement="floating" value={staffEmail} onIonChange={(e) => setStaffEmail(e.detail.value)} onIonFocus={scrollToBottom} placeholder="me@winery.com" type="email" />
            </IonItem>
          )}

          <IonItemDivider>What do you suggest we change?</IonItemDivider>

          <IonItem>
            <IonTextarea rows="5" value={description} onIonChange={(e) => setDescription(e.detail.value)} onIonFocus={scrollToBottom} autocapitalize="sentences" />
          </IonItem>
        </IonCard>
        <IonButton expand="block" size="default" color="primary" onClick={onSubmitClick}>
          Submit
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default SuggestionForm;
