import React from 'react';
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonTitle,
  IonButton,
  IonButtons,
  IonHeader,
} from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import MapMarkers from './InfoModalSlides/MapMarkers';

const InformationCircleModal = (props) => {
  const { onDismiss } = props;

  return (
    <IonPage>
      <IonContent>
        <Swiper className="swiper-container custom-swiper">
          <IonHeader className="header">
            <IonToolbar color="primary">
              <IonTitle align="center">VinoSeeker Map Key</IonTitle>
              <IonButtons slot="end">
                <IonButton color="light" onClick={onDismiss}>Close</IonButton>
              </IonButtons>
              <IonButtons slot="start" style={{ minWidth: 60 }} />
            </IonToolbar>
          </IonHeader>
          <SwiperSlide className="swiper-wrapper">
            <div className="slide">
              <MapMarkers />
            </div>
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonPage>
  );
};

export default InformationCircleModal;
