import React from 'react';
import {
  IonButtons,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import { patchFriendInvite, removeFriendInvite } from '../../lib/api/friend-services';

const FriendInviteActionButtons = (props) => {
  const {
    invite,
    isSent,
    isDisabled,
    onUpdate: updateInviteState,
  } = props;

  const handleUpdate = async (status) => {
    if (status === 'withdraw') {
      await removeFriendInvite(invite.id);
    } else {
      await patchFriendInvite(invite.id, status);
    }
    updateInviteState(status);
  };

  return (
    <IonButtons>
      {isSent ? (
        <IonButton
          style={{ '--background': '#800', color: 'white' }}
          onClick={() => handleUpdate('withdraw')}
          disabled={isDisabled}
        >
          <IonIcon src={closeOutline} />
          Withdraw
        </IonButton>
      ) : (
        <>
          <IonButton
            style={{ '--background': '#800', color: 'white' }}
            onClick={() => handleUpdate('ignored')}
            disabled={isDisabled}
          >
            <IonIcon src={closeOutline} />
            Ignore
          </IonButton>
          <IonButton
            style={{ '--background': '#076b1d', color: 'white' }}
            onClick={() => handleUpdate('accepted')}
            disabled={isDisabled}
          >
            <IonIcon src={checkmarkOutline} />
            Accept
          </IonButton>
        </>
      )}
    </IonButtons>
  );
};

export default FriendInviteActionButtons;
