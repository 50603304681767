import React, { useEffect, useState } from 'react';
import {
  IonButtons, IonButton, IonBackButton, IonContent, IonHeader, IonItem, IonList, IonPage, IonTitle, IonToolbar, IonCard, IonCardContent, IonFooter, IonText, IonImg, useIonModal, IonIcon,
} from '@ionic/react';
import { Link } from 'react-router-dom';
import { arrowDownCircleOutline, shareOutline } from 'ionicons/icons';
import { useSelector } from 'react-redux';
import './Settings.scss';
import InfoModal from '../components/InfoModal';
import pjson from '../../package.json';
import client from '../lib/feathers';

const Settings = (props) => {
  const { history } = props;
  const auth = useSelector((state) => state.auth);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [showInstall, setShowInstall] = useState(false);
  const [registration, setRegistration] = useState();
  let deferredPrompt;

  const [presentInitialInfoModal, dismissInitialInfoMap] = useIonModal(InfoModal, {
    onDismiss: () => {
      dismissInitialInfoMap();
    },
  });

  const onEditProfileClick = () => {
    history.push('/me/edit');
  };

  const onChangePasswordClick = () => {
    history.push('/me/changePassword');
  };

  const sendSubscriptionToServer = async (sub, user) => {
    const service = client.service('client/notification-subscription');
    await service.create({ subscription: sub, userId: user.id });
  };

  const onInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, throw it away
      deferredPrompt = null;
    } else {
      console.log('show install prompt');
      setShowInstall(true);
    }
  };

  const updateNotificationSubscription = () => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted' || Notification.permission === 'denied') {
        setNotificationsEnabled(Notification.permission);
      } else {
        setNotificationsEnabled(Notification.permission);
      }
    }
  };

  const enableNotifications = async () => {
    const showNotifications = window.confirm('Would you like VinoSeeker to be able to send you update notifications?');
    if (showNotifications) {
      if ('Notification' in window) {
        Notification.requestPermission().then(async (permission) => {
          updateNotificationSubscription();
          if (permission === 'granted') {
            console.log('Notification permission granted.');
            if (registration) {
              const subscription = await registration.pushManager.getSubscription();
              if (subscription) {
                console.log('User is already subscribed:', subscription, auth.user);
                sendSubscriptionToServer(subscription, auth.user);
              } else {
                const newSubscription = await registration.pushManager.subscribe({
                  userVisibleOnly: true,
                  applicationServerKey: process.env.REACT_APP_VAPID_PUBLIC_KEY,
                });
                console.log('User subscribed:', newSubscription, auth.user);
                sendSubscriptionToServer(newSubscription, auth.user);
              }
            }
          } else if (permission === 'denied') {
            console.warn('Notification permission denied.');
          }
        });
      } else {
        alert('Your browser does not appear to support notifications');
      }
    }
  };

  useEffect(() => {
    updateNotificationSubscription();
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      console.log('beforeinstallprompt event was fired.', deferredPrompt);
    });
    window.navigator.serviceWorker.ready.then(async (swReg) => {
      setRegistration(swReg);
    });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/me/settings" />
          </IonButtons>
          <IonTitle>Account Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader className="ion-text-center">
          <IonToolbar className="menuHeader" color="white">
            <Link to="/">
              <IonImg src="./assets/HorizontalSeekerLogo@520w.png" className="logo" />
            </Link>
          </IonToolbar>
        </IonHeader>
        {auth.authenticated ? (
          <IonCard>
            <IonCardContent style={{ padding: 0 }}>
              <IonList lines="full">
                <IonItem button detail className="item-text-wrap" onClick={onEditProfileClick}>
                  Edit Profile & Account Info
                </IonItem>
                <IonItem button detail onClick={onChangePasswordClick}>
                  Change Password
                </IonItem>
                <IonItem button lines="full" onClick={enableNotifications}>
                  {notificationsEnabled === 'default' && (<span> Enable Notifications</span>)}
                  {notificationsEnabled === 'granted' && (<span>Notifications - Enabled</span>)}
                  {notificationsEnabled === 'denied' && (<span>Notifications - Disabled</span>)}
                </IonItem>
                <IonItem button detail lines="none" href="/logout">
                  Sign Out
                </IonItem>
              </IonList>
            </IonCardContent>
          </IonCard>
        ) : null}
        <IonList>
          <IonItem button lines="full" onClick={presentInitialInfoModal}>
            Feature Tour
          </IonItem>
          <IonItem lines="full" button className="item-text-wrap" href="https://vinoseeker.com/privacy-policy" target="_blank">
            Privacy Policy
          </IonItem>
          <IonItem lines="full" button href="https://vinoseeker.com/terms-and-conditions" target="_blank">
            Terms and Conditions
          </IonItem>
        </IonList>
        <IonFooter>
          {window.matchMedia('(display-mode: standalone)').matches ? '' : (
            <>
              {!showInstall ? (
                <IonButton size="small" expand="full" onClick={onInstallClick}>
                  <IonIcon slot="start" icon={arrowDownCircleOutline} />
                  Install
                </IonButton>
              ) : (
                <p className="ion-text-center">
                  Just tap &nbsp;
                  <IonIcon icon={shareOutline} />
                  &nbsp; then &apos;Add to Home Screen&apos;
                </p>
              )}
            </>
          )}

          <IonText color="primary">
            <div className="ion-text-center">
              v.
              {pjson.version}
              <p>
                &copy;
                {(new Date()).getFullYear()}
                &nbsp;VinoSeeker LLC
              </p>
            </div>
          </IonText>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
