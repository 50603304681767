import { IonChip, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { checkmarkDoneCircleOutline, list, person, wine } from 'ionicons/icons';
import React from 'react';

const UserProfileCounts = (props) => {
  const {
    profile,
    onListCountClick,
    onCheckinCountClick,
    onSipCountClick,
    onFriendCountClick,
  } = props;

  const checkinCountClick = () => {
    if (onCheckinCountClick) {
      onCheckinCountClick();
    }
  };
  const listCountClick = () => {
    if (onListCountClick) {
      onListCountClick();
    }
  };
  const sipCountClick = () => {
    if (onListCountClick) {
      onSipCountClick();
    }
  };

  const friendCountClick = () => {
    if (onListCountClick) {
      onFriendCountClick();
    }
  };

  return (
    <IonRow>
      <IonCol>
        <IonGrid>
          <IonRow>
            <IonCol className="center-col">
              <IonChip onClick={checkinCountClick}>
                <IonIcon src={checkmarkDoneCircleOutline} />
                <IonLabel>
                  {profile?.counts?.checkins}
                  {' '}
                  Check-Ins
                </IonLabel>
              </IonChip>
            </IonCol>
            <IonCol className="center-col">
              <IonChip onClick={listCountClick}>
                <IonIcon src={list} />
                <IonLabel>
                  {`${profile?.counts?.lists} Lists`}
                </IonLabel>
              </IonChip>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="center-col">
              <IonChip onClick={friendCountClick}>
                <IonIcon src={person} />
                <IonLabel>{` ${profile?.counts?.friends} Friends`}</IonLabel>
              </IonChip>
            </IonCol>
            {/* <IonCol className="center-col">
              <IonChip>
                <IonIcon src={person} />
                <IonLabel>{`${profile?.counts?.following} Friends`}</IonLabel>
              </IonChip>
            </IonCol>
          </IonRow>
          <IonRow> */}
            <IonCol className="center-col">
              <IonChip onClick={sipCountClick}>
                <IonIcon src={wine} />
                <IonLabel>{` ${profile?.counts?.sips} Sips`}</IonLabel>
              </IonChip>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCol>
    </IonRow>
  );
};

export default UserProfileCounts;
