import {
  IonAlert,
  IonButton,
  IonIcon,
  useIonAlert,
  useIonLoading,
  IonRow,
  IonCol,
} from '@ionic/react';
import {
  bookmark,
  calendarOutline,
  call,
  checkmarkCircleOutline,
  checkmarkDoneCircleOutline,
  map,
  open,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import getUserLocation from '../lib/Geo';
import {
  logCall,
  logWebsite,
  logSave,
  logCheckin,
  logDirections,
  logReservations,
} from '../lib/logAction';
import client from '../lib/feathers';
import featureLevels from '../lib/featureLevels';
import { loadCheckins } from '../lib/api-utils';
import { setRecentCheckins, setSelectedCheckin } from '../lib/features/listingsSlice';
import { addNewCheckin } from '../lib/features/profileSlice';

const ListingButtons = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const recentCheckIn = useSelector((state) => state.listings.hasRecentCheckin);

  const [checkinWarning, setCheckinWarning] = useState(false);
  const [saveWarning, setSaveWarning] = useState(false);
  const history = useHistory();
  const {
    phone, website, location, listingId, featureLevel, reservationUrl, atListing,
  } = props;
  const [showListWarning, setShowListWarning] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(false);
  const [locationLoaded, setLocationLoaded] = useState(false);
  const [present, dismiss] = useIonLoading();
  const [alert] = useIonAlert();

  const onSaveClick = () => {
    logSave(listingId);
    if (auth.authenticated) {
      history.push(`/listing/saveToList/${listingId}`);
    } else {
      setSaveWarning(true);
      // alert('Silly Seeker, Lists are for Users. Login to manage your lists.');
    }
  };
  // showSuccess();
  const onCheckInClick = async (e) => {
    e.preventDefault();
    if (auth.authenticated) {
      logCheckin(listingId);
      // <IonSpinner name="dots" duration="500"/>
      try {
        present('Checking You In Now');

        const service = client.service('client/checkin');

        await service.create({
          listingId,
          location: {
            type: 'Point',
            coordinates: [currentLocation.longitude, currentLocation.latitude],
          },
        });

        const checkins = await loadCheckins(listingId);
        dispatch(setRecentCheckins(checkins));
        dismiss();
        const lastCheckin = ([...checkins].reverse())[0];
        dispatch(setSelectedCheckin(lastCheckin));
        dispatch(addNewCheckin(lastCheckin));
        history.push(`/listing/checkin/${lastCheckin.id}`);
      } catch (err) {
        console.log('error checking in', err);
        dismiss();
        alert('Sorry we were unable to check you in');
      }
    } else {
      setCheckinWarning(true);
    }
  };

  useEffect(() => {
    if (listingId) {
      const g = async () => {
        try {
          const geo = await getUserLocation();
          setCurrentLocation(geo.coords);
          setLocationLoaded(true);
          // console.log('geo', geo.coords)
        } catch (err) {
          console.log('unable to load location', err);
          setLocationLoaded(true);
        }
      };
      g();
    }
  }, [listingId]);
  return (
    <>
      <IonAlert
        isOpen={showListWarning}
        onDidDismiss={() => setShowListWarning(false)}
        cssClass="my-custom-class"
        header="Coming Soon"
        subHeader="Personal Lists"
        message="Coming very soon, you will be able to bookmark wineries and save them to your own lists.  Keep track of places you are a member, places you want to visit, or maybe places you never want to go again."
        buttons={['OK']}
      />
      <IonAlert
        isOpen={saveWarning}
        onDidDismiss={() => setSaveWarning(false)}
        cssClass="my-custom-class"
        message="Silly Seeker, lists are for users. Login to manage your lists."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Login',
            id: 'confirm-button',
            handler: () => {
              history.push('/login');
            },
          },
        ]}
      />
      <IonAlert
        isOpen={checkinWarning}
        onDidDismiss={() => setCheckinWarning(false)}
        cssClass="my-custom-class"
        message="Silly Seeker, check-ins are for users. Login to check in."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Login',
            id: 'confirm-button',
            handler: () => {
              history.push('/login');
            },
          },
        ]}
      />
      {!atListing && (
        <IonRow>
          {phone && (
            <IonCol>
              <IonButton
                expand="block"
                href={`tel:${phone}`}
                onClick={() => {
                  logCall(listingId);
                }}
              >
                <IonIcon icon={call} slot="start" />
                <br />
                <div>Call</div>
              </IonButton>
            </IonCol>
          )}
          {website && (
            <IonCol>
              <IonButton
                expand="block"
                href={website}
                target="_blank"
                onClick={() => {
                  logWebsite(listingId);
                }}
              >
                <IonIcon icon={open} slot="start" />
                <div>Website</div>
              </IonButton>
            </IonCol>
          )}
          <IonCol>
            <IonButton
              expand="block"
              target="_blank"
              disabled={!locationLoaded && !currentLocation && !location}
              href={`https://www.google.com/maps/dir/?api=1&origin=${currentLocation.latitude},${currentLocation.longitude}&destination=${location.lat},${location.lng}`}
              onClick={() => {
                logDirections(listingId);
              }}
            >
              <IonIcon icon={map} slot="start" />
              <br />
              <div>Directions</div>
            </IonButton>
          </IonCol>
        </IonRow>
      )}
      {/* eslint-disable-next-line no-bitwise */}
      {(featureLevel & featureLevels.RESERVATION_LINK)
        === featureLevels.RESERVATION_LINK
        && reservationUrl?.length > 0 && (
          <IonRow>
            <IonCol>
              <IonButton
                expand="block"
                href={reservationUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  logReservations(listingId);
                }}
              >
                <IonIcon icon={calendarOutline} slot="start" />
                <div>Make a Reservation</div>
              </IonButton>
            </IonCol>
          </IonRow>
      )}
      <IonRow>
        <IonCol>
          <IonButton expand="block" onClick={onSaveClick}>
            <IonIcon icon={bookmark} slot="start" />
            <div>Save</div>
          </IonButton>
        </IonCol>
        <IonCol>
          {recentCheckIn ? (
            <IonButton expand="block" disabled>
              <IonIcon icon={checkmarkDoneCircleOutline} slot="start" />
              <div>Checked In</div>
            </IonButton>
          ) : (
            <IonButton expand="block" onClick={onCheckInClick}>
              <IonIcon icon={checkmarkCircleOutline} slot="start" />
              <div>Check In</div>
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </>
  );
};

export default ListingButtons;
