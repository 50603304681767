import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  useIonToast,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { store } from './lib/store';
import Map from './pages/Map';
import Profile from './pages/Profile';
import './App.scss';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';
import SaveToList from './pages/SaveToList';
import SuggestionForm from './pages/SuggestionForm';
import Listing from './pages/Listing';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import ManageLists from './pages/ManageLists';
import ManageCheckins from './pages/ManageCheckins';
import ManageFriends from './pages/ManageFriends';
import ManageSips from './pages/ManageSips';
import PublicProfile from './pages/PublicProfile';
import ListingCheckinPhoto from './pages/ListingCheckinPhoto';
import ListingList from './pages/ListingList';
import ListForm from './components/ListForm';
import ListingCheckin from './pages/ListingCheckin';
import ListingListListingSearch from './pages/ListingListListingSearch';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings';
import ChangePassword from './pages/ChangePassword';
import EditProfile from './pages/EditProfile';
import TabbedUI from './components/TabbedUI';
import SplitPane from './components/SplitPane';

const App = (props) => {
  const { serviceWorker } = props;
  const [present, dismiss] = useIonToast();
  const useTabs = true;

  const showUpdate = (worker) => {
    present({
      buttons: [{
        text: 'update',
        handler: () => {
          if (worker) worker.postMessage({ type: 'SKIP_WAITING' });
          dismiss();
          window.location.reload(true);
        },
      }],
      message: 'An update to VinoSeeker is Available.',
      // onDidDismiss: () => window.location.reload(),
    });
  };
  const showInstalled = () => {
    present({
      duration: 2000,
      message: 'An update to VinoSeeker has been installed.',
      // onDidDismiss: () => window.location.reload(),
    });
  };

  serviceWorker.register({
    onSuccess: () => {
      // console.log('service working success called', reg, worker);
      showInstalled();
    },
    onUpdate: (reg, worker) => {
      // console.log('service working update called', reg, worker);
      showUpdate(worker);
    },
    onSubscribe: (subscription) => {
      console.log('sw msg subscribe', subscription);
    },
  });

  const outlets = () => (
    <IonRouterOutlet id="main">
      {/* Public routes */}
      <Route path="/listing" component={Map} exact />
      <Route path="/lists/:id" component={ListingList} exact />
      <Route path="/login" component={Login} exact />
      <Route path="/logout" component={Logout} exact />
      <Route path="/register" component={Register} exact />
      <Route path="/settings" component={Settings} exact />
      <Route path="/forgotPassword" component={ForgotPassword} exact />
      <Route path="/resetPassword/:token" component={ResetPassword} exact />
      <Route path="/u/:username" component={PublicProfile} exact />
      {/* private paths */}
      <Route path="/listing/:id" component={Listing} exact />
      <Route path="/listing/suggestion/:id" component={SuggestionForm} exact />
      <Route path="/listing/saveToList/:id" component={SaveToList} exact />
      <Route path="/listing/listForm/:id" component={ListForm} exact />
      <Route path="/listing/checkin/:id" component={ListingCheckin} exact />
      <Route
        path="/listing/checkin/:id/photo/:attachmentId"
        component={ListingCheckinPhoto}
        exact
      />
      <Route path="/me" component={Profile} exact />
      <Route path="/me/settings" component={Settings} exact />
      <Route path="/me/changePassword" component={ChangePassword} exact />
      <Route path="/me/sips" component={ManageSips} exact />
      <Route path="/me/lists" component={ManageLists} exact />
      <Route path="/me/lists/:id" component={ListingList} exact />
      <Route path="/me/lists/listForm/:id" component={ListForm} exact />
      <Route
        path="/me/lists/:id/search"
        component={ListingListListingSearch}
        exact
      />
      <Route path="/me/checkins" component={ManageCheckins} exact />
      <Route path="/me/friends" component={ManageFriends} exact />
      <Route path="/me/edit" component={EditProfile} exact />

      <Route path="/" exact>
        <Redirect to="/listing" />
      </Route>
    </IonRouterOutlet>
  );

  return (
    <Provider store={store}>
      <IonApp>
        <Helmet>
          <title>VinoSeeker - Seek. Sip. Share.</title>
          <meta property="og:title" content="VinoSeeker" />
          <meta name="description" content="Seek. Sip. Share" />
          <meta property="og:image" content="/assets/HorizontalSeekerLogo@520w.png" />
        </Helmet>
        <IonReactRouter>
          {useTabs ? (
            <TabbedUI>{outlets()}</TabbedUI>
          ) : (
            <SplitPane>{outlets()}</SplitPane>
          )}
        </IonReactRouter>
      </IonApp>
    </Provider>
  );
};

export default App;
