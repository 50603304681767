import React, { useRef, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonItem,
  IonModal,
  IonPopover,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { copy, shareOutline } from 'ionicons/icons';

const QRModal = ({ qrOpen, setModalOpen }) => {
  let shareUrl;
  const modalRef = useRef();
  const popover = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);

  if (user) {
    shareUrl = `${process.env.REACT_APP_CLIENT_URL}/u/${user.username}`;
  }
  console.log('shareUrl', shareUrl);

  const onShareProfileClick = (e) => {
    if (navigator.share) {
      // setPopoverOpen(true);
      navigator.share({
        title: 'My Profile',
        text: `Check out ${user.firstName} ${user.lastName}'s profile on VinoSeeker.`,
        url: shareUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      if (popover.current) {
        popover.current.event = e;
      }
      setPopoverOpen(true);
    }
  };

  const onClipboardCopy = () => {
    navigator.clipboard.writeText(shareUrl);
    setPopoverOpen(false);
  };

  return (
    <IonModal ref={modalRef} isOpen={qrOpen} onWillDismiss={() => setModalOpen(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle className="ion-text-center">Friends</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => modalRef.current.dismiss()}>Close</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={onShareProfileClick}>
              <IonIcon icon={shareOutline} />
            </IonButton>
          </IonButtons>
          <IonPopover className="custom-popover" ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
            <IonItem>
              <IonInput value={shareUrl} />
              <IonButton
                slot="end"
                onClic
                k={onClipboardCopy}
                className="custom-button ion-button-hover"
                title="Copy Link"
              >
                <IonIcon src={copy} />
              </IonButton>
            </IonItem>
          </IonPopover>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard className="ion-padding">
          <IonCardHeader>
            <IonCardTitle>Share your Profile</IonCardTitle>
            <IonCardSubtitle>Copy the share link or scan the QR Code</IonCardSubtitle>
            <IonCardContent>
              <div className="ion-flex ion-justify-content-center">
                <img style={{ width: '300px', borderRadius: '10px' }} src={user.qrcode} alt="qr code" />
              </div>
            </IonCardContent>
          </IonCardHeader>
        </IonCard>
      </IonContent>
    </IonModal>
  );
};

export default QRModal;
