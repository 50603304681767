import React, { useEffect, useState } from 'react';
import {
  useIonLoading, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonItemDivider, IonNote, IonPage, IonRadio, IonRadioGroup, IonTextarea, IonTitle, IonToolbar,
} from '@ionic/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { addNewListToLists, updateListItem } from '../lib/features/profileSlice';
import scrollToBottom from '../lib/ScrollToBottom';
import client from '../lib/feathers';

const ListForm = (props) => {
  const { match } = props;
  const history = useHistory();
  const [listId, setListId] = useState();
  const [listName, setListName] = useState();
  const [listAccess, setListAccess] = useState('public');
  const [listDescription, setListDescription] = useState('');
  const [present, dismiss] = useIonLoading();

  const dispatch = useDispatch();

  const onSaveList = async (e) => {
    await present();
    if (e) {
      e.preventDefault();
    }
    if (!listName || listName.trim().length === 0) {
      await dismiss();
      alert('Please enter a name for your list');
      return;
    }
    if (!listAccess || listAccess.length === 0) {
      await dismiss();
      alert('Please select the access type for your list');
      return;
    }

    const service = client.service('client/listing-list');
    const data = {
      name: listName.trim(),
      access: listAccess,
      description: listDescription,
    };

    if (listId) {
      const response = await service.patch(listId, data);
      dispatch(updateListItem(response));
    } else {
      const response = await service.create(data);
      dispatch(addNewListToLists(response));
    }
    await dismiss();
    history.goBack();
  };

  const loadList = async (lid) => {
    present();
    const service = client.service('client/listing-list');
    const listToEdit = await service.get(lid);

    setListName(listToEdit.name);
    setListAccess(listToEdit.access);
    setListDescription(listToEdit.description);
    dismiss();
  };

  useEffect(() => {
    if (match.params.id.toString().indexOf('-') !== -1) {
      setListId(match.params.id);
      loadList(match.params.id);
    }
  }, [match.params]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>
            {listId ? 'Edit List' : 'New List'}
          </IonTitle>
          <IonButtons slot="primary">
            <IonButton type="button" tabIndex="0" onClick={() => onSaveList()}>
              Save
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form onSubmit={onSaveList}>
          <IonItemDivider>Name</IonItemDivider>
          <IonItem>
            <IonInput value={listName} onIonChange={(e) => setListName(e.target.value)} onIonFocus={scrollToBottom} placeholder="Name of List" type="text" required maxlength="255" />
          </IonItem>
          <IonItem>
            <IonTextarea
              rows="5"
              value={listDescription}
              onIonChange={(e) => setListDescription(e.target.value)}
              onIonFocus={scrollToBottom}
              placeholder="Any notes on what makes this list special"
              autocapitalize="sentences"
            />
          </IonItem>
          <IonRadioGroup value={listAccess} onIonChange={(e) => setListAccess(e.target.value)}>
            <IonItemDivider>Who can see this list?</IonItemDivider>

            <IonItem lines="full">
              <IonRadio slot="start" value="public" labelPlacement="end" justify="start">
                Public&nbsp;
                <IonNote>Anyone can see</IonNote>
              </IonRadio>
            </IonItem>

            <IonItem lines="full">
              <IonRadio slot="start" value="protected" labelPlacement="end" justify="start">
                Protected&nbsp;
                <IonNote>Visible to followers</IonNote>
              </IonRadio>
            </IonItem>

            <IonItem lines="full">
              <IonRadio slot="start" value="private" labelPlacement="end" justify="start">
                Private&nbsp;
                <IonNote>Visible to only you</IonNote>
              </IonRadio>
            </IonItem>

            <IonItem lines="full">
              <IonRadio slot="start" value="unlisted" labelPlacement="end" justify="start">
                Unlisted&nbsp;
                <IonNote>Visible w/ direct link</IonNote>
              </IonRadio>
            </IonItem>
          </IonRadioGroup>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ListForm;
