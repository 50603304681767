import React, { useEffect, useState } from 'react';
import {
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import client from '../lib/feathers';
import featureLevels from '../lib/featureLevels';

const Gradient = ({ children }) => (
  <div
    style={{
      height: '100%',
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%)',
      padding: '16px',
    }}
  >
    {children}
  </div>
);

const LogoImage = ({ src }) => (
  <img
    style={{
      borderRadius: '50%',
      width: '15%',
      minWidth: '100px',
      maxWidth: '150px',
      aspectRatio: '1/1',
      border: '1px gray solid',
      position: 'absolute',
      bottom: '-45px',
      left: '15px',
    }}
    alt="logo"
    src={src}
  />
);

// export const checkHasImage = (venue) => !!venue?.profileAttachmentId || !!venue?.meta?.bestPhoto;
export const checkHasImage = (venue) => !!venue?.profileAttachmentId;

export const BackgroundImage = ({ source, height = null, children }) => {
  const hasCoverImage = !!source?.profileAttachmentId;
  const metaImageURL = undefined; // `url(${source?.meta?.bestPhoto?.prefix}300x300${source?.meta?.bestPhoto?.suffix})`;
  const coverImageURL = `url(${process.env.REACT_APP_ATTACHMENT_URL}/${source?.profileAttachment?.path}?w=600&f=inside)`;

  const imageURL = hasCoverImage ? coverImageURL : metaImageURL;

  return (
    <div
      style={{
        backgroundImage: imageURL,
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height,
        width: '100%',
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
};

function ListingHeader(props) {
  const {
    listingData,
    listingId,
    height = '20vh',
  } = props;

  const [listing, setListing] = useState();

  /* eslint-disable-next-line no-bitwise */
  const hasLogo = (listing?.featureLevel & featureLevels.LOGO) === featureLevels.LOGO && listing?.logoAttachmentId && listing?.logoAttachment;
  const hasImage = checkHasImage(listing);

  // We fetch the listing data ONLY if we pass the listingId (from WineDetail, or SipForm)
  // otherwise we use the listingData object (passed from either Listing or ListingCheckin)
  useEffect(() => {
    if (listingId) {
      const loadListingById = async (lid) => {
        const service = client.service('client/listing-search');
        const l = await service.get(lid);

        // do not show the logo from sipForm or wineDetail. Manually set to null
        setListing({ ...l, logoAttachmentId: null });
      };

      loadListingById(listingId);
    } else {
      setListing(listingData);
    }
  }, [listingId, listingData]);

  return (
    <IonCardHeader
      className={hasImage ? 'ion-no-padding' : 'ion-justify-content-center'}
    // style={!listing ? { height } : {}}
    >
      {listing && hasImage
        && (
          <BackgroundImage source={listing} height={height}>
            <Gradient>
              <IonGrid className="ion-no-padding ion-no-margin" style={{ height: '100%' }}>
                <IonRow style={{ height: '100%' }}>
                  <IonCol>
                    <IonRow>
                      <IonCardSubtitle style={{ '--color': '#d7d8da' }}>
                        {listing && listing.address && listing.address.city ? listing.address.city : ''}
                        {listing && listing.address && listing.address.city && listing.address.state ? ', ' : ''}
                        {listing && listing.address && listing.address.state ? listing.address.state : ''}
                      </IonCardSubtitle>
                    </IonRow>
                    <IonRow>
                      <IonCardTitle style={{ '--color': 'white', position: 'relative' }}>
                        {listing.name}
                      </IonCardTitle>
                    </IonRow>
                  </IonCol>
                  {listingId && (
                    <IonCol size="auto" className="ion-flex ion-align-items-center">
                      <IonIcon style={{ color: 'white' }} size="small" icon={chevronForwardOutline} />
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>
            </Gradient>
            {/* eslint-disable-next-line */}
            {hasLogo
              && (
                <LogoImage src={`${process.env.REACT_APP_ATTACHMENT_URL}/${listing.logoAttachment.path}?h=300&w=300`} />
              )}
          </BackgroundImage>
        )}
      {listing && !hasImage
        && (
          <>
            <IonGrid className="ion-no-padding ion-no-margin">
              <IonRow>
                <IonCol>
                  <IonRow>
                    {listing.address
                      && (
                        <IonCardSubtitle>
                          {listing.address.city ? listing.address.city : ''}
                          {listing.address.city && listing.address.state ? ', ' : ''}
                          {listing.address.state ? listing.address.state : ''}
                        </IonCardSubtitle>
                      )}
                  </IonRow>
                  <IonRow>
                    {/* If there is no listing.name, then our prop is a custom listingName */}
                    <IonCardTitle>{listing.name || listing}</IonCardTitle>
                  </IonRow>
                </IonCol>
                {listingId && (
                  <IonCol size="auto" className="ion-flex ion-align-items-center">
                    <IonIcon style={{ color: 'white' }} size="small" icon={chevronForwardOutline} />
                  </IonCol>
                )}
              </IonRow>
            </IonGrid>
          </>
        )}
      {!listing
        && (
          <div className="ion-align-content-center">
            <IonCardTitle>
              {listingData?.name || listingData}
            </IonCardTitle>
          </div>
        )}
    </IonCardHeader>
  );
}

export default ListingHeader;
