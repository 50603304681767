import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, useIonLoading, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonSearchbar, IonTitle, IonToolbar, useIonRouter, IonMenuButton,
} from '@ionic/react';

import { useHistory } from 'react-router';
import {
  add, chevronDownCircleOutline,
} from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import client from '../lib/feathers';
import UserLists from '../components/UserLists';
import { setLists } from '../lib/features/profileSlice';
import './ManageLists.scss';

const ManageLists = () => {
  const router = useIonRouter();
  const history = useHistory();
  const searchInputRef = useRef();
  // const ionListRef = useRef();
  // const [present] = useIonAlert();
  const [keywords, setKeywords] = useState();
  const [, setList] = useState();
  // const [showLoading, setShowLoading] = useState();

  const auth = useSelector((state) => state.auth);
  const lists = useSelector((state) => state.profile.lists);
  const [present, dismiss] = useIonLoading();

  const dispatch = useDispatch();

  const loadListingLists = async () => {
    // load the lists on manual refresh, search list
    const service = client.service('client/listing-list');

    // we currently fetch the 5 most recent listingsLists in Profile when fetching User data,
    // Here, upon render and refresh, we'll be sending another query for our entire list of lists:
    const query = {
      $limit: 1000000,
      $sort: {
        createdAt: -1,
      },
    };

    if (keywords && keywords.length) {
      query.name = { $iLike: `%${keywords}%` };
    }
    await present('Loading...');
    try {
      const result = await service.find({
        query,
      });
      dispatch(setLists(result.data));
    } catch (err) {
      alert(err.message);
      history.push('/me');
    }

    await dismiss();
  };

  const loadListsByName = async (e) => {
    e.preventDefault();
    await loadListingLists();
  };

  const onListRefresh = async (e) => {
    await loadListingLists();
    e.detail.complete();
  };

  const onListsClick = async (list) => {
    setList(list);
    if (list.followedList === true) {
      history.push(`/lists/${list.sourceId}`);
    } else {
      history.push(`/me/lists/${list.id}`);
    }
  };

  const onNewListClick = () => {
    history.push('/me/lists/listForm/new');
  };

  const loadUserList = () => {
    if (auth.authCheckComplete) {
      if (auth.authenticated) {
        loadListingLists();
      }
    } else {
      history.push('/me');
    }
  };

  useEffect(loadUserList, [auth]);

  return (

    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {router.canGoBack()
              ? <IonBackButton defaultHref="/" />
              : <IonMenuButton />}
          </IonButtons>
          <IonTitle>Manage My Lists</IonTitle>
          <IonButtons slot="primary">
            <IonButton type="button" tabIndex="0" onClick={onNewListClick}>
              <IonIcon slot="icon-only" icon={add} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={onListRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        {lists
          ? (
            <>
              <form action="#" method="POST" onSubmit={loadListsByName}>
                <IonToolbar color="primary" className="more-padding">
                  <IonSearchbar ref={searchInputRef} placeholder="Search Lists" value={keywords} onIonChange={(e) => setKeywords(e.detail.value)} enterkeyhint="search" inputmode="text" />
                </IonToolbar>
              </form>
              <UserLists lists={lists} onListsClick={onListsClick} loadLists={loadListingLists} />
            </>
          )

          : (
            <>
              <IonProgressBar type="indeterminate" />
            </>
          )}
      </IonContent>
    </IonPage>
  );
};

export default ManageLists;
