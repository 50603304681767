import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Zoom } from 'swiper';
import { IonModal, IonButtons, IonButton, IonHeader, IonContent, IonToolbar, IonTitle } from '@ionic/react';
import './ListingGalleryModal.scss';
import EditPhotoCaptionAndPrivacyForm from '../components/EditPhotoCaptionandPrivacyForm';

const ListingGalleryModal = (props) => {
  const { attachments, genericAltText, currentImageIndex, isOpen, setIsOpen, editable = false } = props;
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);

  return (
    <IonModal className="listing-gallery-modal" isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color="medium" onClick={() => setIsOpen(false)}>
              Back
            </IonButton>
          </IonButtons>
          <IonTitle>Photos</IonTitle>
          {editable === true && (
            <IonButtons slot="end">
              <IonButton
                color="medium"
                onClick={() => {
                  setEditModalIsOpen(true);
                }}
              >
                Edit
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Swiper initialSlide={currentImageIndex} modules={[Pagination, Navigation, Zoom]} centeredSlides zoom navigation pagination={{ type: 'bullets', clickable: 'true' }} style={{ '--swiper-pagination-color': '#800' }} slidesPerView="auto">
          {attachments.map((attachment) => (
            <SwiperSlide className="swiper-modal" key={attachment.path}>
              <div className="swiperImageContainer">
                <div className="swiper-zoom-container">
                  <img src={`${process.env.REACT_APP_ATTACHMENT_URL}/${attachment.path}?w1920&h=1080&f=inside`} alt={attachment.caption ? attachment.caption : genericAltText} />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <div className="overlay">
          <h3>{currentAttachment.listing}</h3>
          <h6>
            {formattedDate(currentAttachment.createdAt)}
            <IonIcon style={{ marginLeft: '4px' }} slot="start" size="medium" icon={privacyIcon} />
          </h6>
          <p style={{ fontSize: 12 }}>{currentAttachment.caption}</p>
        </div> */}
      </IonContent>
      {editModalIsOpen && <EditPhotoCaptionAndPrivacyForm closeModal={setEditModalIsOpen} photo={attachments[currentImageIndex]} attachments={attachments} />}
    </IonModal>
  );
};

export default ListingGalleryModal;
