import { IonProgressBar } from '@ionic/react';
import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import client from '../lib/feathers';

import { setUser } from '../lib/features/authSlice';

const Logout = (props) => {
  const { history } = props;
  const dispatch = useDispatch();

  const remoteLogout = async () => {
    try {
      await client.logout();
    } catch (e) {
      console.log('logout failed');
    }

    dispatch(setUser(undefined));
    history.push('/');
  };

  useEffect(remoteLogout, []);

  return (
    <>
      <IonProgressBar type="indeterminate" />
      <div>Logging You Out...</div>
    </>

  );
};

export default Logout;
