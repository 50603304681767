import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { lockClosedOutline } from 'ionicons/icons';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import client from '../lib/feathers';

// on load grab the token and check with the server to see if its the most recent.
// if not show a message that this reset token is invalid and send them back to the login screen

// on form submit, check that the passwords match
//   if they do send the password and the token to the server to update the user
//   if they do not match show an error

const ResetPassword = (props) => {
  const { history, match } = props;
  const { token } = match.params;
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [present] = useIonToast();

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Sorry, the token provided is either invalid or expired.');

  const { keyboardHeight } = useKeyboardState();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      present('The passwords must match!', 2000);
    } else {
      try {
        const service = client.service('client/forgot-password');
        await service.patch(token, { password });
        setShowSuccess(true);
      } catch (err) {
        // console.log('error resetting password', err);
        setErrorMsg('We ran into an issue saving your password. Please try resetting your password again.');
        setShowError(true);
      }
    }
  };

  const checkToken = async () => {
    try {
      const service = client.service('client/forgot-password');
      const data = await service.get(token);

      if (!data.valid) {
        setShowError(true);
      }
    } catch (err) {
      setShowError(true);
    }
  };
  useEffect(checkToken, []);

  return (
    <IonPage>
      <IonAlert
        isOpen={showSuccess}
        onDidDismiss={() => { setShowSuccess(false); history.push('/login'); }}
        cssClass="my-custom-class"
        header="Reset Success"
        subHeader="Please Log In"
        message="You have successfully reset your password. Hit 'OK' Below to login to VinoSeeker."
        buttons={['OK']}
      />
      <IonAlert
        isOpen={showError}
        onDidDismiss={() => {
          setShowError(false);
          history.push('/login');
        }}
        cssClass="my-custom-class"
        header="Password Reset Issue"
        subHeader="Invalid Token"
        message={errorMsg}
        buttons={['OK']}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Reset Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{
          paddingBottom: keyboardHeight,
        }}
      >
        <form action="#" onSubmit={handleFormSubmit} method="POST">
          <IonRow>
            <IonCol className="ion-text-center">
              <IonIcon
                color="primary"
                style={{ fontSize: '70px' }}
                icon={lockClosedOutline}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              Please enter a new password below. You can not use the same password currently assigned to your account.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  label="Password"
                  labelPlacement="floating"
                  type="password"
                  value={password}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                  onIonChange={(e) => setPassword(e.detail.value)}
                  required
                  helperText="Must contain at least one number, one uppercase letter, lowercase letter, and be at least 8 characters."
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  label="Confirm Password"
                  labelPlacement="floating"
                  type="password"
                  value={passwordConfirm}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must match password above"
                  onIonChange={(e) => setPasswordConfirm(e.detail.value)}
                  required
                  helperText="Must match password above"
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton expand="block" type="submit">Reset My Password</IonButton>
              <p style={{ fontSize: 'medium' }}>
                Remember your password?
                &nbsp;
                <Link to="/login">Login Here</Link>
              </p>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
