import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTitle,
  IonToolbar,
  IonBadge,
  IonText,
  IonSegment,
  IonSegmentButton,
  useIonAlert,
  useIonLoading,
} from '@ionic/react';
import './Profile.css';
import { chevronDownCircleOutline, settingsOutline, peopleOutline, checkmarkDoneCircleOutline, list, wine, camera } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import client from '../lib/feathers';
import RecentCheckins from '../components/RecentCheckins';
import RecentLists from '../components/RecentLists';
import { setProfile } from '../lib/features/profileSlice';
import UserProfileCounts from '../components/UserProfileCounts';
import RecentSips from '../components/RecentSips';
import RecentPhotos from '../components/RecentPhotos';

const Profile = (props) => {
  const { history } = props;

  const dispatch = useDispatch();

  const authCheckComplete = useSelector((state) => state.auth.authCheckComplete);
  const authenticated = useSelector((state) => state.auth.authenticated);
  const user = useSelector((state) => state.auth.user);

  const profile = useSelector((state) => state.profile);

  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonLoading();

  const [profileContent, setProfileContent] = useState('checkins');

  const onEditProfileClick = () => {
    history.push('/me/settings');
  };
  const onCheckinCountClick = () => {
    history.push('/me/checkins');
  };
  const onListCountClick = () => {
    history.push('/me/lists');
  };
  const onSipCountClick = () => {
    history.push('/me/sips');
  };
  const onFriendsClick = () => {
    history.push('/me/friends');
  };

  const handleContentChange = (e) => {
    e.preventDefault();
    setProfileContent(e.detail.value);
  };

  const loadProfile = async () => {
    if (authenticated) {
      await present('Loading...');
      try {
        const service = client.service('client/profile');
        const result = await service.get(user.id);

        dispatch(setProfile(result));
      } catch (err) {
        await dismiss();
        console.log('client/profile/me error: ', err);
        presentAlert({
          header: 'Hold Up!',
          message: 'There has been an error loading your profile',
          buttons: [
            {
              text: 'OK',
              handler: () => {
                history.push('/');
              },
            },
          ],
        });
      }

      await dismiss();
    }
  };

  const onRefresh = async (e) => {
    await loadProfile();
    e.detail.complete();
  };

  useEffect(() => {
    if (authCheckComplete) {
      if (!authenticated) {
        history.push('/');
      } else {
        loadProfile();
      }
    }
  }, [authCheckComplete, authenticated]);

  const monthList = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
  let monthName;
  let year;

  if (authenticated) {
    monthName = monthList[parseInt(user.createdAt.substring(5, 7), 2) - 1];
    year = user.createdAt.substring(0, 4);
  }

  const renderProfileContent = (content) => {
    switch (content) {
      case 'lists':
        return <RecentLists lists={profile.lists.slice(0, 5)} />;
      case 'sips':
        return <RecentSips sips={profile.sips.slice(0, 5)} />;
      case 'attachments':
        return <RecentPhotos attachments={profile.attachments.slice(0, 5)} />;
      default:
        return <RecentCheckins checkins={profile.checkins.slice(0, 5)} />;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonButton onClick={onFriendsClick}>
              <IonIcon src={peopleOutline} />
              {profile && profile?.counts?.invitations > 0 && <span className="unread">{profile.counts.invitations}</span>}
            </IonButton>
          </IonButtons>
          <IonTitle className="ion-text-center">Profile</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => onEditProfileClick()}>
              <IonIcon src={settingsOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      {authCheckComplete && authenticated ? (
        <>
          <IonContent fullscreen forceOverscroll={false}>
            <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
              <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText="Pull to refresh" refreshingSpinner="circles" refreshingText="Refreshing..." />
            </IonRefresher>
            <IonCard className="ion-no-margin">
              <IonCardHeader>
                <IonCardTitle className="ion-text-center">
                  {user ? user.firstName : ''}
                  &nbsp;
                  {user ? user.lastName : ''}
                </IonCardTitle>
                <IonCardSubtitle className="ion-text-center">
                  @
                  {user ? user.username : ''}
                </IonCardSubtitle>
              </IonCardHeader>
              <IonGrid>
                <IonRow>
                  <IonCol className="center-col">
                    {user && (
                      <div className="avatar" title={user.username}>
                        {user.firstName.charAt(0) + user.lastName.charAt(0)}
                      </div>
                    )}
                  </IonCol>
                </IonRow>
                <IonGrid>
                  <IonRow>
                    <IonCol className="center-col">
                      <IonBadge className="ion-badge ion-text-capitalize">{user.role}</IonBadge>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="center-col">
                      <IonText>
                        <h5>
                          Member Since: &nbsp;
                          <strong>
                            {monthName}
                            &nbsp;
                            {year}
                          </strong>
                        </h5>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <UserProfileCounts
                  profile={user}
                  onListCountClick={onListCountClick}
                  onCheckinCountClick={onCheckinCountClick}
                  onSipCountClick={onSipCountClick}
                  onFriendCountClick={onFriendsClick}
                />
              </IonGrid>
            </IonCard>
            {/* // ! SEGMENT TABS BEGIN */}
            <div className="segmentContent">
              <IonSegment value={profileContent} scrollable onIonChange={handleContentChange} style={{ display: 'flex', borderRadius: 0, justifyContent: 'center' }}>
                <IonSegmentButton value="checkins">
                  <IonIcon src={checkmarkDoneCircleOutline} />
                </IonSegmentButton>
                <IonSegmentButton value="lists">
                  <IonIcon src={list} />
                </IonSegmentButton>
                <IonSegmentButton value="sips">
                  <IonIcon src={wine} />
                </IonSegmentButton>
                <IonSegmentButton value="attachments">
                  <IonIcon src={camera} />
                </IonSegmentButton>
              </IonSegment>
              {/* // ! SEGMENT TABS END */}
              {renderProfileContent(profileContent)}
            </div>
          </IonContent>
        </>
      ) : (
        <IonProgressBar type="indeterminate" />
      )}
    </IonPage>
  );
};

export default Profile;
