import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonLabel,
  useIonAlert,
  IonAlert,
} from '@ionic/react';
import { useSelector } from 'react-redux';
import { pin } from 'ionicons/icons';
import client from '../lib/feathers';

const RequestAccess = ({ listing }) => {
  const auth = useSelector((state) => state.auth);
  const [requestWarning, setRequestWarning] = useState(false);
  const [showRequestConfirmation, setShowRequestConfirmation] = useState(false);
  const history = useHistory();
  const [alert] = useIonAlert();

  const createListingUserInvitation = async () => {
    const service = client.service('client/listing-user-invitation');
    const listingUserInvitation = {
      listingId: listing.id,
      status: null,
    };
    await service.create(listingUserInvitation).then((result) => {
      console.log('Created listingUserInvitation:', result);
    }).catch((error) => {
      console.error('Error creating listingUserInvitation:', error);
      alert(error.message);
    });
  };

  const onRequestClick = async (e) => {
    e.preventDefault();
    if (auth.authenticated) {
      setShowRequestConfirmation(true);
    } else {
      setRequestWarning(true);
    }
  };

  return (
    <>
      <IonAlert
        isOpen={requestWarning}
        onDidDismiss={() => setRequestWarning(false)}
        cssClass="my-custom-class"
        message="Silly Seeker, requests are for logged-in users. Log in to request access."
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Login',
            id: 'confirm-button',
            handler: () => {
              history.push('/login');
            },
          },
        ]}
      />
      <IonAlert
        isOpen={showRequestConfirmation}
        onDidDismiss={() => {
          setShowRequestConfirmation(false);
        }}
        cssClass="my-custom-class"
        header="Request Confirmation"
        message="This will send your info to grant access"
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Confirm',
            id: 'confirm-button',
            handler: () => {
              createListingUserInvitation();
            },
          },
        ]}
      />
      <IonCard>
        <IonItem>
          <IonIcon icon={pin} slot="start" />
          <IonLabel>Request Access</IonLabel>
          <IonButton expand="block" onClick={onRequestClick}>
            <div>Request</div>
          </IonButton>
        </IonItem>
        <IonCardContent>
          {
            `Do you work at ${listing.name}? Login and request access to manage this listing and help keep the information up to date. `
          }
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default RequestAccess;
