import React from 'react';
import {
  useDispatch,
  // useSelector
} from 'react-redux';
import {
  IonCard, IonIcon, IonItem, IonLabel, IonButton,
  // IonRippleEffect,
} from '@ionic/react';
import { arrowForward } from 'ionicons/icons';
import { useHistory } from 'react-router';
import CheckinList from './CheckinList';
import { setSelectedCheckin } from '../lib/features/listingsSlice';
import './RecentCheckins.scss';

const RecentCheckins = (props) => {
  // const recentCheckins = useSelector((state) => state.profile.checkins.slice(0, 5));
  const { checkins: recentCheckins } = props;
  const dispatch = useDispatch();

  const history = useHistory();

  const onCheckinClick = async (checkin) => {
    dispatch(setSelectedCheckin(checkin)); // Resets our selectedCheckin state
    history.push(`/listing/checkin/${checkin.id}`);
  };

  return (
    <>
      <IonCard className="ion-no-margin">
        <IonItem lines="full">
          <IonLabel>Recent Check-Ins</IonLabel>
          <IonButton expand="block" onClick={() => history.push('/me/checkins')}>
            <IonIcon icon={arrowForward} size="small" slot="icon-only" className="hover-effect" />
          </IonButton>
        </IonItem>
        <CheckinList checkins={recentCheckins} onCheckinClick={onCheckinClick} />
      </IonCard>
    </>
  );
};
export default RecentCheckins;
