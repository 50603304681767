import React, { useState } from 'react';
import {
  IonAlert, IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar,
} from '@ionic/react';
import {
  personCircleOutline,
  eyeOutline,
  eyeOffOutline,
  settingsOutline,
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import client from '../lib/feathers';
import { setUser } from '../lib/features/authSlice';

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();
  const [passwordDisplay, setPasswordDisplay] = useState(false);
  let hideOrShow = 'password';
  let iconType = eyeOutline;

  const showErrorWithMessage = (msg) => {
    setErrorMessage(msg);
    setShowError(true);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log('do some login', email);
    try {
      if (!email || email === '') {
        showErrorWithMessage('Please enter an email address.');
        return;
      }
      if (!password || password === '') {
        showErrorWithMessage('Please enter a password.');
        return;
      }

      const lowerCaseEmail = email.toLowerCase();

      const auth = await client.authenticate({
        strategy: 'local',
        email: lowerCaseEmail,
        password,
      });

      dispatch(setUser(auth.user));
      history.push('/');
    } catch (err) {
      console.log('auth failed', err);
      showErrorWithMessage('No user with that email and password were found.');
    }
  };

  const onSettingsClick = () => {
    history.push('/settings');
  };

  if (!passwordDisplay) {
    hideOrShow = 'password';
  } else {
    hideOrShow = 'text';
    iconType = eyeOffOutline;
  }

  return (
    <IonPage>
      <IonAlert
        isOpen={showError}
        onDidDismiss={() => setShowError(false)}
        cssClass="my-custom-class"
        header="Login Error"
        // subHeader={'Personal Lists'}
        message={errorMessage}
        buttons={['OK']}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Login</IonTitle>
          <IonButtons slot="end">

            <IonButton onClick={() => onSettingsClick()}>
              <IonIcon src={settingsOutline} />
            </IonButton>

          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <form action="#" onSubmit={handleLogin}>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonIcon
                color="primary"
                style={{ fontSize: '70px' }}
                icon={personCircleOutline}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Email"
                  type="email"
                  value={email}
                  inputMode="email"
                  autocomplete="email"
                  onIonChange={(e) => setEmail(e.detail.value)}
                  pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                  title="Must be a valid email address"
                  required
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Password"
                  type={hideOrShow}
                  value={password}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                  onIonChange={(e) => setPassword(e.detail.value)}
                  required
                />
                <IonIcon
                  slot="end"
                  icon={iconType}
                  style={{
                    display: 'flex', justifyItems: 'right', alignItems: 'right', cursor: 'pointer',
                  }}
                  onClick={() => setPasswordDisplay(!passwordDisplay)}
                />
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol className="ion-text-center">
              <p style={{ fontSize: 'small' }}>
                By clicking Login you agree to our
                &nbsp;
                <a href="hhttps://vinoseeker.com/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                &nbsp;and our&nbsp;
                <a href="https://vinoseeker.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
              </p>
              <IonButton expand="block" type="submit">Login</IonButton>
              <input type="submit" style={{ visibility: 'hidden' }} />
              <p style={{ fontSize: 'medium' }}>
                Don&apos;t have an account?
                &nbsp;
                <Link to="/register">Join for Free</Link>
              </p>
              <p style={{ fontSize: 'medium' }}>
                Forgot your password?
                &nbsp;
                <Link to="/forgotPassword">Reset it Now</Link>
              </p>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Login;
