import React from 'react';
import ReactDOM from 'react-dom';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { Provider } from 'react-redux';
import { setupIonicReact } from '@ionic/react';
import { store } from './lib/store';
import * as serviceWorker from './serviceWorker';
import App from './App';

setupIonicReact({
  innerHTMLTemplatesEnabled: true,
  // mode: 'ios',
});

ReactDOM.render(
  <Provider store={store}>
    <App serviceWorker={serviceWorker} />
  </Provider>,
  document.getElementById('root'),
);

defineCustomElements(window);
