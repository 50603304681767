import React from 'react';
import '../InfoModal.scss';
import { Swiper, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import { Pagination } from 'swiper';

import {
  lockClosed,
  lockOpenOutline,
  lockClosedOutline,
  lockOpenSharp,
} from 'ionicons/icons';

import {
  IonLabel,
  IonItem,
  IonText,
  IonRow,
  IonCol,
  IonGrid,
  IonIcon,
} from '@ionic/react';

const Lists = () => (
  <div className="wrapper">
    <IonItem>
      <IonLabel>
        <IonText className="ion-text-wrap ion-text-center">
          <h1><strong>Custom Lists</strong></h1>
          <p>Customizable based on your experience and supports personalized itineraries</p>
        </IonText>
      </IonLabel>
    </IonItem>
    <IonItem>
      <Swiper
        modules={[Pagination]}
        pagination
      >
        <SwiperSlide>
          <img src=".../../assets/infoPopup/listMap.png" alt="map" className="image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="../../assets/infoPopup/listSummary.png" alt="wine tasting itinerary" className="image" />
        </SwiperSlide>
      </Swiper>
    </IonItem>
    <IonGrid>
      <IonRow className="topRow">
        <IonCol>
          <div className="center">
            <IonIcon
              size="large"
              src={lockOpenSharp}
              alt="Open Lists"
              color="dark"
            />
            <IonLabel>
              <IonText>
                <h2><strong>Public Lists</strong></h2>
                <em>Available to everyone</em>
              </IonText>
            </IonLabel>
          </div>
        </IonCol>
        <IonCol>
          <div className="center">
            <IonIcon
              size="large"
              src={lockClosed}
              alt="Private Lists"
              color="dark"
            />
            <IonLabel>
              <h2><strong>Private Lists</strong></h2>
              <em>Available only to you</em>
            </IonLabel>
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <div className="center">
            <IonIcon
              size="large"
              src={lockClosedOutline}
              alt="Protected Lists"
              color="dark"
            />
            <IonLabel>
              <h2><strong>Protected Lists</strong></h2>
              <em>Available to friends</em>
            </IonLabel>
          </div>
        </IonCol>
        <IonCol>
          <div className="center">
            <IonIcon
              size="large"
              src={lockOpenOutline}
              alt="Unlisted Lists"
              color="dark"
            />
            <IonLabel>
              <h2><strong>Unlisted Lists</strong></h2>
              <em>Available to anyone you share the link with</em>
            </IonLabel>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  </div>
);

export default Lists;
