import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import {
  IonContent,
  IonModal,
  IonTitle,
  IonItem,
  IonNote,
} from '@ionic/react';

import ListingFlightList from './ListingFlightList';

function ListingCheckinFlightListModal(props) {
  const { isOpen, dismissFlightListModal } = props;

  const flights = useSelector((state) => state.listings?.listingFlights);

  const modal = useRef();

  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      onDidDismiss={dismissFlightListModal}
      style={{ '--width': '100%', '--height': '75%' }}
    >
      <IonItem lines="none ion-margin-top" style={{ '--border-radius': '20px' }}>
        <IonTitle className="ion-no-padding ion-text-left">
          Available Flights
        </IonTitle>
      </IonItem>
      <IonContent className="ion-padding-top">
        <IonNote className="ion-padding">
          Select a flight to view more details:
        </IonNote>
        <ListingFlightList
          flights={flights}
          dismissFlightListModal={dismissFlightListModal}
        />
      </IonContent>
    </IonModal>
  );
}
export default ListingCheckinFlightListModal;
