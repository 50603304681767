import React, { useState } from 'react';
import './InfoModal.scss';
import { Swiper, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import { Controller } from 'swiper';

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonPage,
} from '@ionic/react';

import MapMarkers from './InfoModalSlides/MapMarkers';
import Welcome from './InfoModalSlides/Welcome';
import Lists from './InfoModalSlides/Lists';
import Checkins from './InfoModalSlides/Checkins';

const InfoModal = (props) => {
  const { onDismiss } = props;
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const onSlidePrevious = () => {
    controlledSwiper.slidePrev();
  };

  const onSlideNext = () => {
    if (activeSlideIndex < controlledSwiper.slides.length - 1) {
      controlledSwiper.slideNext();
    } else {
      onDismiss();
    }
  };

  return (
    <div>
      <IonPage>
        <IonContent>
          <IonHeader className="header">
            <IonToolbar color="primary">
              <IonButtons slot="start" style={{ minWidth: 60 }}>
                {activeSlideIndex > 0 && (
                <IonButton onClick={onSlidePrevious}>
                  Prev
                </IonButton>
                )}
              </IonButtons>
              <IonTitle align="center"><div className="ion-text-wrap">Welcome to VinoSeeker!</div></IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={onSlideNext}>
                  {controlledSwiper?.slides.length === activeSlideIndex + 1 ? 'Close' : 'Next'}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <div className="swiper-wrapper">
            <Swiper
              className="swiper-container custom-swiper"
              modules={[Controller]}
              pagination
              onSwiper={setControlledSwiper}
              onSlideChange={() => setActiveSlideIndex(controlledSwiper.activeIndex)}
            >
              <SwiperSlide className="slide">
                <Welcome />
              </SwiperSlide>
              <SwiperSlide className="slide">
                <MapMarkers />
              </SwiperSlide>
              <SwiperSlide className="slide">
                <Lists />
              </SwiperSlide>
              <SwiperSlide className="slide">
                <Checkins />
              </SwiperSlide>
            </Swiper>
          </div>
        </IonContent>
      </IonPage>
    </div>
  );
};

export default InfoModal;
