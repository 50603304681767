import React, { useEffect, useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonBackButton,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from '@ionic/react';
import { chevronDownCircleOutline, personCircle } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import client from '../lib/feathers';
import { setUser } from '../lib/features/authSlice';

const EditProfile = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.auth.user);
  const [present, dismiss] = useIonLoading();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const loadProfile = async () => {
    if (auth.authenticated) {
      await present('Loading...');
      const service = client.service('client/user');
      const result = await service.get(auth.user.id);
      dispatch(setUser(result));
      setFirstName(result.firstName);
      setLastName(result.lastName);
      setUsername(result.username);
      setEmail(result.email);
      await dismiss();
    }
  };

  const onRefresh = async (e) => {
    await loadProfile();
    e.detail.complete();
  };

  useEffect(loadProfile, []);

  const showErrorWithMessage = (msg) => {
    setErrorMessage(msg);
    setShowError(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (firstName.length < 1) {
      showErrorWithMessage('Please enter a First Name');
      return;
    }
    if (lastName.length < 1) {
      showErrorWithMessage('Please enter a Last Name');
      return;
    }
    if (username.length < 4) {
      showErrorWithMessage('Please enter a new profile name that has at least 4 characters');
      return;
    }
    if (email === '') {
      showErrorWithMessage('Please enter a new email');
      return;
    }

    const data = {
      firstName,
      lastName,
      username,
      email,
    };

    try {
      const service = client.service('client/user');
      present('Saving Your Updates');
      const result = await service.patch(profile.id, data);
      dispatch(setUser(result));
      await dismiss();
      setShowSuccess(true);
    } catch (err) {
      const issue = err.errors[0];
      console.log('error registering', issue.path);
      await dismiss();
      switch (issue.path) {
        case 'username':
          alert('It appears that username is already in use. Please try another.');
          break;
        case 'email':
          alert('It appears that email is already in use. Please login or choose another email address.');
          break;
        default:
          alert('Sorry, we had an issue updating your account. Please try again later. If the issue persists contact service@vinoseeker.com');
          break;
      }
    }
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={showError}
        onDidDismiss={() => setShowError(false)}
        cssClass="my-custom-class"
        header="Registration Error"
        // subHeader={'Personal Lists'}
        message={errorMessage}
        buttons={['OK']}
      />
      <IonAlert
        isOpen={showSuccess}
        onDidDismiss={() => {
          setShowSuccess(false);
          history.go(-1);
        }}
        cssClass="my-custom-class"
        header="Edit Success"
        // subHeader="Welcome to VinoSeeker!"
        message="Your account has been edited! Click ok below to go back to your profile."
        buttons={['OK']}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/me" />
          </IonButtons>
          <IonTitle>Edit Account Info</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText="Pull to refresh" refreshingSpinner="circles" refreshingText="Refreshing..." />
        </IonRefresher>
        <form action="#" onSubmit={handleFormSubmit} method="POST">
          <IonRow>
            <IonCol className="ion-text-center">
              <IonIcon color="primary" style={{ fontSize: '70px' }} icon={personCircle} />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="First Name"
                  type="text"
                  value={firstName}
                  autocapitalize="true"
                  autocomplete="given-name"
                  minlength="1"
                  maxlength="30"
                  title="Must only contain letters and must be at least 1 characters long"
                  onIonChange={(e) => setFirstName(e.detail.value)}
                  required
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Last Name"
                  type="text"
                  value={lastName}
                  autocapitalize="true"
                  autocomplete="family-name"
                  minlength="1"
                  maxlength="50"
                  title="Must only contain letters and must be at least 1 characters long"
                  onIonChange={(e) => setLastName(e.detail.value)}
                  required
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Profile Name"
                  type="text"
                  value={username}
                  pattern="[a-zA-Z0-9]+"
                  minlength="4"
                  maxlength="32"
                  helperText="Must be at least 4 characters and can only include numbers and letters"
                  title="Must be at least 4 characters and can only include numbers and letters"
                  onIonChange={(e) => setUsername(e.detail.value)}
                  required
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Email"
                  type="email"
                  value={email}
                  inputMode="email"
                  autocomplete="email"
                  onIonChange={(e) => setEmail(e.detail.value)}
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                  title="Must be a valid email address"
                  required
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton expand="block" type="submit">
                Confirm Edits
              </IonButton>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default EditProfile;
