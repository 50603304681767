import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFriends } from '../lib/features/friendsSlice';
import { getAllFriends } from '../lib/api/friend-services';

const useFriends = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth?.user);
  const { friends: _friends } = useSelector((state) => state.friends);

  const friends = useMemo(() => (
    [..._friends].filter((frnd) => frnd.status === 'default')
  ), [_friends]);

  const muted = useMemo(() => (
    [..._friends].filter((frnd) => frnd.status === 'muted')
  ), [_friends]);

  const blocked = useMemo(() => (
    [..._friends].filter((frnd) => frnd.status === 'blocked')
  ), [_friends]);

  const [isLoading, setIsLoading] = useState(false);

  const loadAllFriends = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = await getAllFriends();
      dispatch(setFriends(results));
    } catch (err) {
      console.log('error loading friends', err);
    }
    setIsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    if (user.id) {
      loadAllFriends();
    }
  }, [user.id]);

  const updateFriend = useCallback((friendId, status) => {
    const updatedList = [..._friends].map((aFriend) => (aFriend.id === friendId ? { ...aFriend, status } : aFriend));
    dispatch(setFriends(updatedList));
  }, [_friends, dispatch]);

  const unfollowFriend = useCallback((friendId) => {
    const updatedList = [..._friends].filter((aFriend) => aFriend.id !== friendId);
    dispatch(setFriends(updatedList));
  }, [_friends, dispatch]);

  return {
    isLoading,
    data: { friends, muted, blocked },
    refetch: loadAllFriends,
    updateFriend,
    unfollowFriend,
  };
};

export default useFriends;
