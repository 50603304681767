import React, { useEffect, useState, useRef } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonPopover,
  IonProgressBar,
  IonTitle,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';
import { copy, shareOutline, chevronDownCircleOutline } from 'ionicons/icons';
import client from '../lib/feathers';
import ListingDetailVerified from '../components/ListingDetailVerified';
import ListingDetailUnverified from '../components/ListingDetailUnverified';
import { setFocusItem, setSelectedListing } from '../lib/features/listingsSlice';

const Listing = (props) => {
  let shareUrl;
  const { match } = props;
  const dispatch = useDispatch();
  const listing = useSelector((state) => state.listings.selectedListing);

  const popover = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (listing) {
    shareUrl = `${process.env.REACT_APP_CLIENT_URL}/listing/${listing.id}`;
  }

  const getListingById = async (listingID) => {
    const service = client.service('client/listing-search');
    const l = await service.get(listingID);

    dispatch(setFocusItem(l));

    return l;
  };

  const onLoad = async () => {
    const l = await getListingById(match.params.id);
    dispatch(setSelectedListing(l));
  };

  const onShareProfileClick = (e) => {
    if (navigator.share) {
      navigator.share({
        title: 'Listing Profile',
        text: `Check out ${listing.name}'s profile on VinoSeeker.`,
        url: shareUrl,
      })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      if (popover.current) {
        popover.current.event = e;
      }
      setPopoverOpen(true);
    }
  };

  const onClipboardCopy = () => {
    navigator.clipboard.writeText(shareUrl);
    setPopoverOpen(false);
  };

  const handleManualRefresh = async (e) => {
    await onLoad();
    e.detail.complete();
  };

  useEffect(onLoad, [match]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{listing ? listing.name : 'Loading Winery Data'}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={onShareProfileClick}>
              <IonIcon icon={shareOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>

        <IonRefresher slot="fixed" onIonRefresh={handleManualRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>

        {listing
          ? (
            <>
              <IonPopover className="custom-popover" ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
                <IonItem>
                  <IonInput value={shareUrl} />
                  <IonButton
                    slot="end"
                    onClick={onClipboardCopy}
                    className="custom-button ion-button-hover"
                    title="Copy Link"
                  >
                    <IonIcon src={copy} />
                  </IonButton>
                </IonItem>
              </IonPopover>
              {listing.status === 'unverified'
                ? <ListingDetailUnverified venue={listing.meta} listing={listing} />
                : <ListingDetailVerified listing={listing} />}
            </>
          )

          : (
            <>
              <IonProgressBar type="indeterminate" />
            </>
          )}
      </IonContent>
    </IonPage>
  );
};

export default Listing;
