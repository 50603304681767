import React, {
  useState, useEffect,
} from 'react';
import {
  IonButtons,
  IonBackButton,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  IonText,
  useIonRouter,
  IonItem,
  IonProgressBar,
  IonSegmentButton,
  IonIcon,
  IonSegment,
  useIonAlert,
  IonLabel,
  IonButton,
  useIonLoading,
} from '@ionic/react';
import {
  checkmarkDoneCircleOutline, list, wine, personOutline, images,
} from 'ionicons/icons';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedCheckin } from '../lib/features/listingsSlice';
import client from '../lib/feathers';
import UserLists from '../components/UserLists';
import CheckinList from '../components/CheckinList';
import UserProfileCounts from '../components/UserProfileCounts';
import SipList from '../components/SipList';
import PhotoList from '../components/PhotoList';
import FriendInviteSendButton from '../components/friends/FriendInviteSendButton';
import FriendInviteActionButtons from '../components/friends/FriendInviteActionButtons';
import FriendActionSheet from '../components/friends/FriendActionSheet';
import { getFriend, getFriendInvite } from '../lib/api/friend-services';

const FriendInviteActions = ({ recipient, onAccept: refreshFriend }) => {
  const user = useSelector((state) => state.auth?.user);

  const [isLoading, setIsLoading] = useState();
  const [invite, setInvite] = useState();
  const [present, dismiss] = useIonLoading();

  const isRecipient = invite?.recipientId === user.id;
  const isPending = invite?.status === 'pending';
  const isIgnored = invite?.status === 'ignored';

  const isSender = !isRecipient && (isPending || isIgnored);

  useEffect(() => {
    const loadInvite = async () => {
      await present('Loading...');
      setIsLoading(true);
      try {
        const result = await getFriendInvite(recipient);
        setInvite(result);
      } catch (err) {
        console.log('error loading invite', err);
      }
      setIsLoading(false);
      await dismiss();
    };

    loadInvite();
  }, []);

  const handleUpdateInvite = async (status) => {
    if (status === 'accepted') await refreshFriend();
    setInvite(null);
  };

  if (invite && !(isRecipient && isIgnored)) {
    return (
      <FriendInviteActionButtons
        invite={invite}
        isSent={isSender}
        onUpdate={handleUpdateInvite}
        isDisabled={isLoading}
      />
    );
  }

  return (
    <FriendInviteSendButton
      to={recipient}
      onSend={setInvite}
      isDisabled={isLoading}
    />
  );
};

const PublicProfile = (props) => {
  const { match } = props;

  const router = useIonRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth?.user);

  const [presentAlert] = useIonAlert();

  const [present, dismiss] = useIonLoading();
  const [profileContent, setProfileContent] = useState('checkins');
  const [profile, setProfile] = useState();
  const [friend, setFriend] = useState();

  const isFriend = !!friend;

  const loadFriend = async (userData) => {
    await present('Please wait...');
    try {
      const result = await getFriend(userData);
      setFriend(result);
    } catch (err) {
      console.log('error loading friend', err);
    }
    await dismiss();
  };

  const handleUpdateFriend = (status) => setFriend((prevState) => ({ ...prevState, status }));
  const handleRemoveFriend = () => setFriend(null);

  const loadProfile = async () => {
    await present('Please wait...');
    try {
      const service = client.service('client/profile');
      const result = await service.get(match.params.username);

      setProfile(result);
    } catch (err) {
      console.log('client/profile/:username Error: ', err.message);
      presentAlert({
        header: 'Error',
        message: 'Unable to find user profile.',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              history.push('/');
            },
          },
        ],
      });
    }
    await dismiss();
  };

  const onPublicCheckinClick = async (checkin) => {
    let path = checkin.listing.id;
    if (isFriend) {
      path = `checkin/${checkin.id}`;
      dispatch(setSelectedCheckin(checkin));
    }
    history.push(`/listing/${path}`);
  };

  const onPublicListsClick = async (listId) => {
    history.push(`/lists/${listId.id}`);
  };

  const onPublicSipClick = async (sip) => {
    history.push(`/lists/${sip.id}`);
  };

  const onPublicImageClick = async (image) => {
    // history.push(`/lists/user-images/${image.id}`);
    console.log('Public image click', image);
  };

  const monthList = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
  let monthName;
  let year;

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    if (user.id && profile) {
      loadFriend(profile);
    }
  }, [user.id, profile]);

  if (profile) {
    monthName = monthList[parseInt(profile.createdAt.substring(5, 7), 2) - 1];
    year = profile.createdAt.substring(0, 4);
  }

  const handleContentChange = (e) => {
    e.preventDefault();
    setProfileContent(e.detail.value);
  };

  const PublicCard = ({ label, children }) => (
    <IonCard className="ion-no-margin">
      <IonItem>
        <IonLabel>{label}</IonLabel>
      </IonItem>
      {children}
    </IonCard>
  );

  const renderProfileContent = (content) => {
    switch (content) {
      case 'lists':
        return (
          <PublicCard label="Recent List">
            <UserLists lists={profile.listingLists} onPublicListsClick={onPublicListsClick} allowDelete={false} />
          </PublicCard>
        );
      case 'sips':
        return (
          <PublicCard label="Recent Sips">
            <SipList sips={profile.sips} onPublicSipClick={onPublicSipClick} allowDelete={false} />
          </PublicCard>
        );
      case 'attachments':
        return (
          <PublicCard label="Recent Photos">
            <PhotoList attachments={profile.attachments} onPublicImageClick={onPublicImageClick} allowDelete={false} editable={false} />
          </PublicCard>
        );
      default:
        return (
          <PublicCard label="Recent Check-Ins">
            <CheckinList checkins={profile.checkins} onPublicCheckinClick={onPublicCheckinClick} allowDelete={false} />
          </PublicCard>
        );
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {router.canGoBack()
              ? <IonBackButton defaultHref="/" />
              : <IonMenuButton />}
          </IonButtons>
          <IonTitle className="ion-text-center">Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      {profile ? (
        <>
          <IonContent fullscreen>
            <IonCard className="ion-no-margin">
              <IonCardHeader>
                <IonCardTitle className="ion-text-center">
                  {profile ? profile.firstName : ''}
                  {' '}
                  {profile ? profile.lastName : ''}
                </IonCardTitle>
                <IonCardSubtitle className="ion-flex ion-text-center ion-align-items-center ion-justify-content-center">
                  @
                  {profile ? profile.username : ''}
                </IonCardSubtitle>
              </IonCardHeader>
              <IonGrid>
                <IonRow>
                  <IonCol className="center-col">
                    {profile
                      && (
                        <div className="avatar" title={profile.username}>{profile.firstName.charAt(0) + profile.lastName.charAt(0)}</div>
                      )}
                  </IonCol>
                </IonRow>
                <IonGrid>
                  <IonRow>
                    <IonCol className="center-col">
                      <IonItem lines="none">
                        {user && profile && user.id && (user.id !== profile.id) && (
                          isFriend ? (
                            <IonButtons>
                              <IonButton
                                style={{ '--background': '#076b1d', color: 'white' }}
                                onClick={() => history.push('/me/friends')}
                              >
                                <IonIcon src={personOutline} style={{ marginRight: '4px' }} />
                                Friends
                              </IonButton>
                              <FriendActionSheet
                                onUpdate={handleUpdateFriend}
                                onUnfollow={handleRemoveFriend}
                                friend={friend}
                                // isDisabled={showLoading}
                              />
                            </IonButtons>
                          ) : (
                            <FriendInviteActions
                              recipient={profile}
                              onAccept={() => loadFriend(profile)}
                            />
                          )
                        )}
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol className="center-col">
                      <IonText>
                        <h5>
                          Member Since:
                          &nbsp;
                          <strong>
                            {monthName}
                            &nbsp;
                            {year}
                          </strong>
                        </h5>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <UserProfileCounts profile={profile} />
              </IonGrid>
            </IonCard>
            <IonSegment
              value={profileContent}
              scrollable
              onIonChange={handleContentChange}
              style={{ display: 'flex', borderRadius: 0, justifyContent: 'center' }}
            >
              <IonSegmentButton value="checkins">
                <IonIcon src={checkmarkDoneCircleOutline} />
              </IonSegmentButton>
              <IonSegmentButton value="lists">
                <IonIcon src={list} />
              </IonSegmentButton>
              <IonSegmentButton value="sips">
                <IonIcon src={wine} />
              </IonSegmentButton>
              <IonSegmentButton value="attachments">
                <IonIcon src={images} />
              </IonSegmentButton>
            </IonSegment>
            {renderProfileContent(profileContent)}
          </IonContent>
        </>
      ) : (
        <IonProgressBar type="indeterminate" />
      )}
    </IonPage>
  );
};

export default PublicProfile;
