import React from 'react';
import '../InfoModal.scss';

import {
  IonLabel,
  IonItem,
  IonText,
} from '@ionic/react';

const WelcomeMessage = () => (
  <div className="wrapper">
    <IonItem>
      <IonLabel>
        <h1 className="ion-text-center"><strong>VinoSeeker uses geolocation</strong></h1>
        <p className="ion-text-center">Map Tiles are &copy;OpenStreetMaps</p>
      </IonLabel>
    </IonItem>
    <IonItem>
      <img className="image" alt="geolocation" src="../../assets/infoPopup/welcomeImage.png" />
    </IonItem>
    <IonItem>
      <IonLabel className="welcome">
        <IonText class="ion-text-wrap">
          <h4 className="welcomeHeading4">For the best experience, allow VinoSeeker to access your location.</h4>
          <h4 className="welcomeHeading4">If you haven&apos;t already enabled your location settings, you can do that by:</h4>
          <ul>
            <li className="welcomeListItem">Step 1 - Go to Device Settings</li>
            <li className="welcomeListItem">Step 2 - Go to Privacy & Security and turn on Location Services</li>
            <li className="welcomeListItem">Step 3 - Enjoy all the VinoSeeker goodness around you.</li>
          </ul>
        </IonText>
      </IonLabel>
    </IonItem>
  </div>
);

export default WelcomeMessage;
