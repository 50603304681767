import React from 'react';
import {
  IonIcon,
  useIonAlert,
  IonButton,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import { createFriendInvite } from '../../lib/api/friend-services';

const FriendInviteSendButton = (props) => {
  const {
    to: recipient,
    onSend: handleInviteState,
    isDisabled,
  } = props;

  const [presentAlert] = useIonAlert();

  const handleSend = async () => {
    const result = await createFriendInvite(recipient);

    if (result) {
      handleInviteState(result);
      presentAlert({
        header: 'Request Sent',
        message: `A friend request has been sent to ${recipient.username}!`,
        buttons: [{ text: 'OK' }],
      });
    } else {
      presentAlert({
        header: 'Whoops!',
        message: `There was a problem sending a friend invite to ${recipient.username}. Try again.`,
        buttons: [{ text: 'OK' }],
      });
    }
  };

  return (
    <IonButton
      style={{ '--background': '#076b1d', color: 'white' }}
      onClick={handleSend}
      disabled={isDisabled}
    >
      <IonIcon icon={add} />
      Friend
    </IonButton>
  );
};

export default FriendInviteSendButton;
