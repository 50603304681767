import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonSearchbar, IonTitle, IonToolbar, useIonRouter, IonMenuButton, useIonLoading,
} from '@ionic/react';

import { useHistory } from 'react-router';
import {
  add, chevronDownCircleOutline,
} from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setSips } from '../lib/features/profileSlice';
import client from '../lib/feathers';
import SipList from '../components/SipList';
import './ManageLists.scss';

const ManageSips = () => {
  const router = useIonRouter();
  const history = useHistory();
  const searchInputRef = useRef();

  const [keywords, setKeywords] = useState('');
  // const [showLoading, setShowLoading] = useState();
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [present, dismiss] = useIonLoading();

  const auth = useSelector((state) => state.auth);
  const sips = useSelector((state) => state.profile.sips);

  const dispatch = useDispatch();

  const loadSips = async () => {
    const service = client.service('client/sip-search');

    const query = {
      keywords,
    };

    await present('Loading...');
    try {
      const result = await service.find({ query });
      dispatch(setSips(result));
    } catch (err) {
      alert(err.message);
      history.push('/me');
    }

    await dismiss();
  };

  const loadSipsBySearch = async (e) => {
    e.preventDefault();
    await loadSips();
  };

  const handleManualRefresh = async (e) => {
    await loadSips();
    e.detail.complete();
  };

  // const onListsClick = async (list) => {
  //   setList(list);
  //   history.push(`/me/lists/${list.id}`);
  // };
  const handleOpenSearch = () => {
    setSearchIsOpen(true);
  };

  const loadUserSips = () => {
    if (auth.authCheckComplete) {
      if (auth.authenticated) {
        loadSips();
      }
    } else {
      history.push('/me');
    }
  };

  useEffect(loadUserSips, [auth]);

  return (

    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {router.canGoBack()
              ? <IonBackButton defaultHref="/" />
              : <IonMenuButton />}
          </IonButtons>
          <IonTitle>Manage My Sips</IonTitle>
          <IonButtons slot="primary">
            <IonButton type="button" tabIndex="0" onClick={handleOpenSearch}>
              <IonIcon slot="icon-only" icon={add} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleManualRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        {sips
          ? (
            <>
              <form action="#" method="POST" onSubmit={loadSipsBySearch}>
                <IonToolbar color="primary" className="more-padding">
                  <IonSearchbar
                    ref={searchInputRef}
                    placeholder="Search Sips"
                    value={keywords}
                    onIonInput={(e) => setKeywords(e.target.value)}
                    enterkeyhint="search"
                    inputmode="text"
                  />
                </IonToolbar>
              </form>

              <SipList
                sips={sips}
                searchIsOpen={searchIsOpen}
                closeSearch={() => setSearchIsOpen(false)}
              />

            </>
          )
          : (
            <>
              <IonProgressBar type="indeterminate" />
            </>
          )}
      </IonContent>
    </IonPage>
  );
};

export default ManageSips;
