import {
  IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs,
} from '@ionic/react';
import { map, person } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import client from '../lib/feathers';
import { setAuthCheckComplete, setUser } from '../lib/features/authSlice';

const TabbedUI = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.authenticated);

  useEffect(() => {
    const check = async () => {
      try {
        const auth = await client.authenticate();
        dispatch(setUser(auth.user));
      } catch (e) {
        console.log('auth failed', e);
      }
      dispatch(setAuthCheckComplete(true));
    };
    check();
  }, []);

  return (
    <IonTabs>
      {children}
      <IonTabBar slot="bottom">
        <IonTabButton tab="tab1" href="/listing">
          <IonIcon icon={map} />
          <IonLabel>Seek</IonLabel>
        </IonTabButton>
        <IonTabButton tab="tab2" href={isAuthenticated ? '/me' : '/login'}>
          <IonIcon icon={person} />
          <IonLabel>Profile</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default TabbedUI;
