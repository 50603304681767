import React from 'react';
import {
  IonToolbar,
  IonTitle,
  IonButton,
  IonItem,
  IonHeader,
  IonLabel,
} from '@ionic/react';
import VinoMarkdown from './VinoMarkdown';
import Dialog from './Dialog';

const QRWelcomeModal = (props) => {
  const { isOpen, listing, onDismiss } = props;
  return (
    <Dialog isOpen={isOpen} onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle class="ion-text-center">
            {listing.promotions?.welcomeMessage?.title || 'Welcome!'}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <div>
        <IonItem lines="none">
          <IonLabel>
            <VinoMarkdown>
              {listing.promotions?.welcomeMessage?.description}
            </VinoMarkdown>
          </IonLabel>
          <IonButton style={{ paddingTop: 5, paddingBottom: 5, width: '100%' }} onClick={onDismiss}>Close</IonButton>
        </IonItem>
      </div>

    </Dialog>
  );
};

export default QRWelcomeModal;
