import client from './feathers';

const logAction = async (action, listingId, data) => {
  const service = client.service('client/listing-action');

  await service.create({
    action,
    data,
    result: [listingId],
  });
};

const logCall = async (listingId) => {
  logAction('call', listingId);
};
const logWebsite = async (listingId) => {
  logAction('website', listingId);
};
const logSave = async (listingId) => {
  logAction('save', listingId);
};
const logCheckin = async (listingId) => {
  logAction('checkin', listingId);
};
const logDirections = async (listingId) => {
  logAction('directions', listingId);
};
const logReservations = async (listingId) => {
  logAction('reservation', listingId);
};
const logSocial = async (listingId, source) => {
  logAction('social', listingId, { source });
};
const logHours = async (listingId) => {
  logAction('hours', listingId);
};

export {
  logAction,
  logCall,
  logWebsite,
  logSave,
  logCheckin,
  logDirections,
  logReservations,
  logSocial,
  logHours,
};
