import React, { useState } from 'react';
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonTextarea,
  IonNote,
  IonButtons,
  IonButton,
  IonItemGroup,
  useIonActionSheet,
  IonReorder,
  IonItemOptions,
  IonItemOption,
  useIonAlert,
  IonItemSliding,
  IonCol,
  IonRow,
  IonGrid,
  IonSpinner,
} from '@ionic/react';
import {
  ellipsisHorizontal,
} from 'ionicons/icons';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import scrollToBottom from '../lib/ScrollToBottom';
import VinoMarkdown from './VinoMarkdown';
import SipRating, { RatingIcon } from './SipRating';
import './Sips.scss';

const Sip = (props) => {
  const {
    sip,
    showSipDetails,
    showWineDetails,
    handleUpdateSip,
    handleDeleteSip,
    showAllInfo,
    allowDelete: isOwner = true,
  } = props;

  const [sipRating, setSipRating] = useState(sip.rating);
  const [sipNotes, setSipNotes] = useState(sip.notes);
  const [isEditing, setIsEditing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const locale = useSelector((state) => state.auth.user.locale || Intl.DateTimeFormat().resolvedOptions().timeZone);
  const checkin = useSelector((state) => state.listings.selectedCheckin);

  const [present] = useIonActionSheet();
  const [presentWarning] = useIonAlert();

  const onDeleteSip = async (sipToDelete) => {
    presentWarning({
      onDidDismiss: () => { },
      cssClass: 'my-custom-class',
      header: 'Warning',
      message: `Are you sure you want to delete your sip for ${sipToDelete.libation?.name}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'DELETE',
          role: 'destructive',
          handler: async () => {
            await handleDeleteSip(sipToDelete);
          },
        },
      ],
    });
  };

  const presentActionSheet = () => {
    present({
      header: sip.libation?.name,
      buttons: [
        ...(isOwner
          ? [{
            text: 'Delete',
            role: 'destructive',
            handler: () => onDeleteSip(sip),
          }] : []
        ),
        {
          text: 'Sip Details',
          role: 'edit',
          handler: showSipDetails,
        },
        {
          text: 'Wine Details',
          role: 'edit',
          handler: showWineDetails,
        },
        {
          text: 'Cancel',
          role: 'cancel',
        },
      ],
    });
  };

  const handleSubmitNotes = async (e) => {
    e.preventDefault();

    if (!isDisabled) {
      setIsDisabled(true);
      const updatedSip = {
        ...sip,
        rating: sipRating,
        notes: sipNotes,
      };

      await handleUpdateSip(updatedSip);
      setIsEditing(false);
      setIsDisabled(false);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setSipRating(sip.rating);
    setSipNotes(sip.notes);
  };

  // If allowed to edit, will set to true - will otherwise stay false.
  const handleBeginEdit = () => setIsEditing(isOwner);

  const handleChangeRating = async (value) => {
    if (!isDisabled) {
      setIsDisabled(true);
      setSipRating(value);

      const updatedSip = {
        ...sip,
        rating: value,
      };

      await handleUpdateSip(updatedSip);
      setIsDisabled(false);
    }
  };

  const getSipNotes = () => {
    if (sip.notes) {
      return sip.notes;
    }
    if (isOwner) {
      return 'Tap here to add notes to your Sip.';
    }
    return 'No notes were added for this Sip.';
  };

  return (
    <>
      {!showAllInfo && (
        <IonItemSliding>
          <IonItem button onClick={showSipDetails} lines="full">
            <IonGrid className="ion-no-padding-left">
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-no-padding-left">
                  <IonRow>
                    <span>{sip.libation?.name}</span>
                  </IonRow>
                  <IonRow>
                    <IonNote className="ion-flex ion-align-items-end small-text">{sip.libation?.listing?.name || sip.libation?.listingName}</IonNote>
                  </IonRow>
                  <IonRow>
                    <IonNote className="small-text">{`${sip.libation?.vintage} ${sip.libation?.varietal?.name || ''}`}</IonNote>
                  </IonRow>
                </IonCol>
                <IonCol size="2.5">
                  <IonRow className="ion-justify-content-center">
                    <RatingIcon rating={sip.rating} className="fa-xl" />
                  </IonRow>
                  <IonRow className="ion-justify-content-center" style={{ paddingTop: '2px' }}>
                    <IonNote className="small-text">{moment(sip.createdAt).tz(locale).format('l')}</IonNote>
                  </IonRow>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
          {isOwner && (
            <IonItemOptions side="end">
              <IonItemOption onClick={() => onDeleteSip(sip)}>Delete</IonItemOption>
              <IonItemOption color="secondary" onClick={showSipDetails}>Edit</IonItemOption>
            </IonItemOptions>
          )}
        </IonItemSliding>
      )}

      {showAllInfo && (
        <IonItemGroup>
          <IonItem lines="none">
            <IonButtons slot="end">
              <IonButton onClick={presentActionSheet}>
                <IonIcon icon={ellipsisHorizontal} />
              </IonButton>
            </IonButtons>
            <IonLabel>
              <h2 className="ion-text-wrap"><IonReorder className="inline"><b>{sip.libation?.name}</b></IonReorder></h2>
              <div className="ion-flex ion-flex-col">
                {checkin?.listingId !== sip?.libation?.listingId && (
                  <IonNote>{` ${sip.libation.listing?.name || sip.libation.listingName || ''}`}</IonNote>
                )}
                <IonNote>{`${sip.libation?.vintage} ${sip.libation?.varietal?.name || ''}`}</IonNote>
              </div>
            </IonLabel>
          </IonItem>
          {sip.libation?.tastingNotes?.length > 0
            && (
              <div className="ion-padding-start">
                <VinoMarkdown>
                  {sip.libation?.tastingNotes}
                </VinoMarkdown>
              </div>
            )}

          <SipRating
            rating={sip?.rating}
            isDisabled={isDisabled || !isOwner}
            handleChangeRating={handleChangeRating}
          />

          <form onSubmit={handleSubmitNotes}>
            <IonItem className="no-line">
              {!isEditing && (
                <IonLabel
                  onClick={handleBeginEdit}
                  color="medium"
                >
                  <VinoMarkdown>
                    {getSipNotes()}
                  </VinoMarkdown>
                </IonLabel>
              )}
              {isEditing && (
                <>
                  <IonTextarea
                    rows="4"
                    disabled={isDisabled}
                    value={sipNotes}
                    onIonChange={(e) => setSipNotes(e.target.value)}
                    onIonFocus={scrollToBottom}
                    placeholder="Add Sip notes here..."
                    autocapitalize="sentences"
                  />
                  {isDisabled
                    && (
                      <IonSpinner name="dots" style={{ position: 'absolute', left: '45%', top: '40%' }} />
                    )}
                </>
              )}
            </IonItem>
            {isEditing
              && (
                <IonLabel className="ion-justify-content-between ion-flex">
                  <IonButton disabled={isDisabled} onClick={handleCancelEdit} className="ion-margin" fill="clear">Cancel</IonButton>
                  <IonButton disabled={isDisabled} type="submit" className="ion-margin" fill="outline">Save</IonButton>
                </IonLabel>
              )}
          </form>
        </IonItemGroup>
      )}
    </>
  );
};

export default Sip;
