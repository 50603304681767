import React, { useEffect, useState } from 'react';
import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonLabel, IonIcon, IonGrid, IonRow, IonCol, IonList,
  IonChip,
} from '@ionic/react';
import {
  logoInstagram, logoTiktok, logoFacebook, logoSnapchat, globeOutline, logoTwitter, mailOutline, callOutline, calendarOutline,
} from 'ionicons/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChampagneGlasses, fas } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './ListingDetail.scss';
import { Swiper, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved

import ListingButtons from './ListingButtons';
import ListingHeader from './ListingHeader';
import RequestAccess from './RequestAccess';
import ListingMapWrapper from './ListingMapWrapper/ListingMapWrapper';
import featureLevels from '../lib/featureLevels';
import {
  logHours,
  logSocial,
  logCall,
  logReservations,
} from '../lib/logAction';
import ListingUserContent from './ListingUserContent';
import NearbyListings from './NearbyListings';
import ListingLibations from './ListingLibations';
import QRWelcomeModal from './QRWelcomeModal';
import ListingGalleryModal from '../pages/ListingGalleryModal';
import ListingPromotionsModal from './ListingPromotionsModal';
import useURLSearchParams from '../hooks/useURLSearchParams';

library.add(fas);

const ListingDetailVerified = (props) => {
  const { listing } = props;

  const searchParams = useURLSearchParams();
  const utmMedium = searchParams.get('utm_medium');
  const [openWelcomeModal, setOpenWelcomeModal] = useState(utmMedium === 'qrcode');

  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [openPromotionsModal, setOpenPromotionsModal] = useState(false);
  const openPromotions = () => setOpenPromotionsModal(true);
  const closePromotions = () => setOpenPromotionsModal(false);

  const [showHours, setShowHours] = useState(false);
  const [weekDay, setWeekDay] = useState(0);
  const [weekDayOrder, setWeekDayOrder] = useState([]);
  const [statusLabel] = useState(listing.status);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const timeStrings = [
    '12',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
  ];

  const accommodations = [
    { type: 'availability', label: 'Availability' },
    { type: 'services', label: 'Guest Services' },
    { type: 'safety', label: 'Health & Safety Precautions' },
    { type: 'certifications', label: 'Certifications' },
  ];

  const homeMarker = {
    lat: listing.location.coordinates[1],
    lon: listing.location.coordinates[0],
  };

  /* eslint-disable-next-line no-bitwise */
  const hasLogoImage = (listing.featureLevel & featureLevels.LOGO) === featureLevels.LOGO && listing.logoAttachmentId;
  const hasPromotionMessage = (listing.promotions?.currentPromo?.description.length > 0);
  /* eslint-disable-next-line no-bitwise */
  const hasPromotionFeature = ((listing.featureLevel & featureLevels.WINE_CLUB_PROMOTION) === featureLevels.WINE_CLUB_PROMOTION) && hasPromotionMessage;

  const formatTime = (dbTimeString) => { // 13 15 == '1:15 PM'
    const hour = parseInt(dbTimeString.split(' ')[0], 10);
    const minute = parseInt(dbTimeString.split(' ')[1], 10);
    const hourStr = timeStrings[parseInt(hour, 10)];
    const minuteStr = minute === 0 ? '00' : minute.toString();
    const meridiem = hour < 13 ? 'AM' : 'PM';

    const str = `${hourStr}:${minuteStr} ${meridiem}`;
    return str;
  };

  const showHoursForDay = (day) => {
    let resp = '';

    if (!listing.hours.days) {
      listing.hours.days = {};
    }
    if (!listing.hours.days[day]) {
      listing.hours.days[day] = { hours: [] };
    }
    const thisDayHours = listing.hours.days[day].hours;
    let thisDayAllHours;

    if (thisDayHours.length >= 1) {
      thisDayAllHours = thisDayHours.map((hour) => (` ${formatTime(hour.opens)} - ${formatTime(hour.closes)}`));
    }

    if (thisDayAllHours) {
      resp += thisDayAllHours;
    } else {
      resp += 'Closed';
    }

    return resp;
  };

  const formatPhone = (dbPhone) => {
    if (!dbPhone) return dbPhone;
    const onlyNumbers = dbPhone.replace(/\D/g, '');
    if (onlyNumbers.length === 10) {
      return `${onlyNumbers.slice(0, 3)}-${onlyNumbers.slice(3, 6)}-${onlyNumbers.slice(6)}`;
    }
    if (onlyNumbers.length > 10) {
      return `(+ ${onlyNumbers.slice(0, -10)})${onlyNumbers.slice(-10, -7)}-${onlyNumbers.slice(-7, -4)}-${onlyNumbers.slice(-4)}`;
    }
    return onlyNumbers;
  };

  useEffect(() => {
    const today = new Date();
    const wd = today.getDay();
    const ds = [];

    setWeekDay(wd);
    for (let i = 0; i < 7; i += 1) {
      const t = wd + i;

      if (t > 6) {
        ds.push(t - 7);
      } else {
        ds.push(t);
      }
    }
    setWeekDayOrder(ds);
  }, []);

  const SocialLink = ({ social, url, listing: linkListing }) => {
    let link;
    let logoIcon;
    // let socialHandle = url.split('.com')[1].split('/')[1];
    if (social === 'phone') {
      logoIcon = callOutline;
      link = <a href={`tel:${formatPhone(url)}`} onClick={() => { logCall(linkListing.id); }}>{url}</a>;
    } else if (social === 'reservationUrl') {
      /* eslint-disable-next-line no-bitwise */
      if ((listing.featureLevel & featureLevels.RESERVATION_LINK) === featureLevels.RESERVATION_LINK && url.length > 0) {
        logoIcon = calendarOutline;
        link = <a href={url} onClick={() => { logReservations(linkListing.id); }}>Reservations</a>;
      } else {
        return '';
      }
    } else if (social === 'email') {
      logoIcon = mailOutline;
      link = <a href={`mailto:${url}`} target="_blank" rel="noreferrer" onClick={() => { logSocial(linkListing.id, social); }}>{url}</a>;
    } else if (social === 'instagram') {
      logoIcon = logoInstagram;
      link = <a href={url} rel="noreferrer" onClick={() => { logSocial(linkListing.id, social); }}>{url.split('.com')[1].split('/')[1]}</a>; //eslint-disable-line
    } else if (social === 'snapchat') {
      logoIcon = logoSnapchat;
      link = <a href={social} rel="noreferrer" onClick={() => { logSocial(linkListing.id, social); }}>{url.split('.com')[1].split('/')[1]}</a>; //eslint-disable-line
    } else if (social === 'tiktok') {
      logoIcon = logoTiktok;
      link = <a href={url} rel="noreferrer" onClick={() => { logSocial(linkListing.id, social); }}>{url.split('.com')[1].split('/')[1]}</a>; //eslint-disable-line
    } else if (social === 'facebook') {
      logoIcon = logoFacebook;
      link = <a href={url} rel="noreferrer" onClick={() => { logSocial(linkListing.id, social); }}>{url.split('.com')[1].split('/')[1]}</a>; //eslint-disable-line
    } else if (social === 'website') {
      logoIcon = globeOutline;
      link = <a href={url} rel="noreferrer" onClick={() => { logSocial(linkListing.id, social); }}>{url.split('//')[url.split('//').length - 1]}</a>;
    } else if (social === 'twitter') {
      logoIcon = logoTwitter;
      link = <a href={url} rel="noreferrer" onClick={() => { logSocial(linkListing.id, social); }}>{url.split('.com')[1].split('/')[1]}</a>; //eslint-disable-line
    } else {
      link = <a href={url} target="_blank" rel="noopener noreferrer" onClick={() => { logSocial(linkListing.id, social); }}>{social}</a>;
    }

    return (
      <IonItem key={social}>
        <IonIcon aria-hidden="true" icon={logoIcon} slot="start" color="primary" />
        <IonLabel>
          {link}
        </IonLabel>
      </IonItem>
    );
  };

  const openModal = (i) => {
    setCurrentImageIndex(i);
    setIsOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>
          {listing.name}
          {' '}
          - VinoSeeker - Seek. Sip. Share.
        </title>
      </Helmet>
      <IonCard>
        <div
          style={{
            marginBottom: hasLogoImage ? '40px' : '0px',
          }}
        >
          <ListingHeader listingData={listing} height="20vh" />
        </div>

        {/* eslint-disable-next-line */}
        {(listing.featureLevel & featureLevels.SHORT_DESCRIPTION) === featureLevels.SHORT_DESCRIPTION && listing.shortDescription.length > 0 && (
          <IonCardContent style={{ paddingTop: '20px' }}>
            {listing.shortDescription}
          </IonCardContent>
        )}

        <ListingButtons
          listingId={listing.id}
          featureLevel={listing.featureLevel}
          phone={listing.contact ? formatPhone(listing.contact.phone) : ''}
          website={listing.contact ? listing.contact.website : ''}
          reservationUrl={listing.contact.reservationUrl}
          location={{
            lat: listing.location.coordinates[1],
            lng: listing.location.coordinates[0],
          }}
          atListing={utmMedium === 'qrcode'}
        />
      </IonCard>

      {hasPromotionFeature && (
        <>
          <IonCard onClick={openPromotions} style={{ padding: '8px' }}>
            <IonItem lines="none" button detail className="ion-no-padding">
              <IonChip style={{ margin: '8px' }}>
                <span style={{ color: 'var(--ion-color-primary)' }}><FontAwesomeIcon icon={faChampagneGlasses} /></span>
              </IonChip>
              <IonLabel>
                <b>View Promotions</b>
              </IonLabel>
            </IonItem>
          </IonCard>
          <ListingPromotionsModal
            isOpen={openPromotionsModal}
            onDismiss={closePromotions}
            promotions={listing?.promotions}
          />
        </>
      )}

      {/* // ! WINE LIST AND FLIGHTS */}
      <ListingLibations />

      <ListingUserContent />

      {listing.hours.status
        && (
          /* eslint-disable react/no-array-index-key */
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                Hours
                {listing.hours.status.value === 'Normal' && (
                  showHours
                    ? <IonButton fill="outline" size="small" className="ion-float-right" onClick={(e) => { e.preventDefault(); setShowHours(false); }}>Less</IonButton>
                    : <IonButton fill="outline" size="small" className="ion-float-right" onClick={(e) => { e.preventDefault(); setShowHours(true); logHours(listing.id); }}>More</IonButton>
                )}
              </IonCardSubtitle>
              {listing.hours.status.value === 'Normal'
                && (
                  <IonCardTitle>
                    Today
                    <br />
                    {listing.hours.days.length > 0 && showHoursForDay(weekDay).split(', ').map((element, index) => (
                      <span key={index}>
                        {element}
                        <br />
                      </span>
                    ))}
                  </IonCardTitle>
                )}
              {listing.hours.status.value !== 'Normal'
                && (
                  <>
                    <IonCardTitle>
                      {listing.hours.status.value}
                    </IonCardTitle>
                    {listing.hours.status.message}
                  </>
                )}
            </IonCardHeader>
            {listing.hours && listing.hours.days.length > 0 && showHours && (
              <IonCardContent>
                <IonGrid>

                  {weekDayOrder.map((day, index) => {
                    if (index !== 0) {
                      return (
                        <IonRow class="nowrap" key={index}>
                          <IonCol class="ion-align-self-start">
                            {days[day]}
                          </IonCol>
                          <IonCol size="8" class="ion-align-self-end">
                            {showHoursForDay(day).split(', ').map((element, idx) => <p key={idx}>{element}</p>)}
                          </IonCol>
                        </IonRow>
                      );
                    }
                    return '';
                  })}
                </IonGrid>
              </IonCardContent>
            )}
          </IonCard>
        )}

      <NearbyListings
        location={{ latitude: listing.location.coordinates[1], longitude: listing.location.coordinates[0] }}
        listingId={listing.id}
      />

      {listing.contact && Object.keys(listing.contact).length > 0
        && (
          <IonCard>
            <IonItem>
              <IonLabel>Contact</IonLabel>
            </IonItem>
            <IonList>
              {Object.keys(listing.contact).map((social) => (
                listing.contact[social].length > 0 ? <SocialLink social={social} url={listing.contact[social]} listing={listing} key={listing.contact[social]} /> : ''
              ))}
            </IonList>
          </IonCard>
        )}

      <IonCard>
        <IonItem>
          <IonLabel className="ion-text-capitalize">
            Location
          </IonLabel>
        </IonItem>
        <IonCardContent>
          <div className="listing-map-container">
            <ListingMapWrapper homeMarker={homeMarker} className="listingMap" />
          </div>
        </IonCardContent>
      </IonCard>
      {/* eslint-disable-next-line */}
      {(listing.featureLevel & featureLevels.UPLOAD_GALLERY_PHOTOS) === featureLevels.UPLOAD_GALLERY_PHOTOS && listing && listing.attachments.length > 0
        && (
          <IonCard>
            <IonItem>
              <IonLabel className="ion-text-capitalize">
                Gallery Photos
              </IonLabel>
            </IonItem>
            <IonCardContent>
              <Swiper
                slidesPerView="auto"
              >
                {listing.attachments.map((attachment, i) => (
                  <SwiperSlide className="swiper-glide" key={i} onClick={() => openModal(i)}>
                    <img className="swiperImage" src={`${process.env.REACT_APP_ATTACHMENT_URL}/${attachment.path}`} alt={listing.name} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </IonCardContent>
          </IonCard>
        )}
      <ListingGalleryModal
        attachments={listing.attachments}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        genericAltText={listing.name}
        currentImageIndex={currentImageIndex}
      />

      {listing.accommodation && (
        accommodations.map((accom) => {
          if (listing.accommodation.filter((a) => a.type === accom.type).length > 0) {
            return (
              <IonCard>
                <IonItem>
                  <IonLabel>{accom.label}</IonLabel>
                </IonItem>
                <IonList>
                  {listing.accommodation.filter((a) => a.type === accom.type).map((item) => (
                    <IonItem key={item.id}>
                      <FontAwesomeIcon icon={fas[item.iconId]} className="w-4 fa-fw" />
                      &nbsp;
                      <IonLabel>
                        <span className="ion-text-capitalize">{item.name}</span>
                      </IonLabel>
                    </IonItem>
                  ))}
                </IonList>
              </IonCard>
            );
          }
          return null;
        })
      )}

      <IonCard>
        <IonItem>
          <IonLabel className="ion-text-capitalize">
            {statusLabel}
            {' '}
            Listing
          </IonLabel>
        </IonItem>

        <IonCardContent>
          We are working on building out our library of community and business verified listings. Do you know something about this business? Make a suggestion and help us improve this listing.
          <br />
          <br />
          <Link to={`/listing/suggestion/${listing.id}`}>
            Suggest An Edit
          </Link>
        </IonCardContent>
      </IonCard>
      {/* eslint-disable-next-line */}
      {(listing.featureLevel & featureLevels.WELCOME_MESSAGE) === featureLevels.WELCOME_MESSAGE && listing.promotions?.welcomeMessage?.description?.length > 0 && (
        <QRWelcomeModal listing={listing} isOpen={openWelcomeModal} onDismiss={() => setOpenWelcomeModal(false)} />
      )}
      <RequestAccess listing={listing} />
    </>
  );
};

export default ListingDetailVerified;
