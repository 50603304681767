import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { lockClosedOutline } from 'ionicons/icons';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import client from '../lib/feathers';

const ChangePassword = (props) => {
  const { history } = props;
  const auth = useSelector((state) => state.auth);
  const [password, setPassword] = useState();
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [present] = useIonToast();
  const [showSuccess, setShowSuccess] = useState(false);

  const { keyboardHeight } = useKeyboardState();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (auth.authenticated) {
      if (password !== passwordConfirm) {
        present('The passwords must match!', 2000);
      } else {
        const service = client.service('client/user');
        await service.patch(auth.user.id, { password });
        setShowSuccess(true);
        setPassword('');
        setPasswordConfirm('');
      }
    }
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={showSuccess}
        onDidDismiss={() => { setShowSuccess(false); history.push('/me/settings'); }}
        cssClass="my-custom-class"
        header="Success!"
        message="Your password has been updated."
        buttons={['OK']}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{
          paddingBottom: keyboardHeight,
        }}
      >
        <form action="#" onSubmit={handleFormSubmit} method="POST">
          <IonRow>
            <IonCol className="ion-text-center">
              <IonIcon
                color="primary"
                style={{ fontSize: '70px' }}
                icon={lockClosedOutline}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              Please enter a new password below. You can not use the same password currently assigned to your account.
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Password"
                  type="password"
                  value={password}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                  helperText="Must contain at least one number, one uppercase letter, lowercase letter, and be at least 8 characters."
                  onIonChange={(e) => setPassword(e.detail.value)}
                  required
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  labelPlacement="floating"
                  label="Confirm Password"
                  type="password"
                  value={passwordConfirm}
                  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                  title="Must match password above"
                  onIonChange={(e) => setPasswordConfirm(e.detail.value)}
                  required
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton expand="block" type="submit">Change My Password</IonButton>
              <p style={{ fontSize: 'medium' }}>
                Remember your password?
                &nbsp;
                <Link to="/login">Login Here</Link>
              </p>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
