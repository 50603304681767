import React, { useState, useEffect } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonTextarea,
  IonInput,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonAlert,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonNote,
} from '@ionic/react';
import { useHistory } from 'react-router';

import {
  chevronForwardOutline,
} from 'ionicons/icons';
import scrollToBottom from '../lib/ScrollToBottom';
import SipRating from './SipRating';
import ListingHeader from './ListingHeader';
import './Sips.scss';

const SipForm = (props) => {
  const {
    isOpen,
    onDismissForm,
    sip,
    handleUpdateSip,
    allowEdit,
    handleShowWineDetail,
  } = props;

  const [sipRating, setSipRating] = useState(sip?.rating);
  const [sipNotes, setSipNotes] = useState(sip?.notes);
  const [sipLocation, setSipLocation] = useState(sip?.location);
  const history = useHistory();

  const [presentAlert] = useIonAlert();

  const resetForm = () => {
    setSipRating(sip.rating);
    setSipNotes(sip.notes);
    setSipLocation(sip.location);
  };

  const dismissForm = () => {
    if (onDismissForm) {
      onDismissForm();
      resetForm();
    }
  };

  const handleSubmitSip = (e) => {
    e.preventDefault();

    if (sipRating === undefined || sipRating === null) {
      presentAlert({
        header: 'Hold Up!',
        message: 'Please make sure to Rate your Sip before submitting.',
        buttons: ['OK'],
      });
      return;
    }

    const data = {
      ...sip,
      rating: sipRating,
      notes: sipNotes,
      location: sipLocation,
    };

    handleUpdateSip(data);
    dismissForm();
  };

  // If we're clicking on a Sip, populate the form with its data:
  useEffect(() => {
    if (sip?.libation?.name) {
      resetForm();
    }
  }, [sip]);

  const handleNavigateListing = () => {
    if (sip.libation?.listing?.id) {
      dismissForm();
      history.push(`/listing/${sip.libation.listingId}`);
    }
  };

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={dismissForm}>Cancel</IonButton>
            </IonButtons>
            {allowEdit
              ? (
                <>
                  <IonTitle class="ion-text-center">Edit Sip</IonTitle>
                  <IonButtons slot="primary">
                    <IonButton strong type="button" onClick={handleSubmitSip}>Submit</IonButton>
                  </IonButtons>
                </>
              ) : (
                <IonTitle class="ion-text-center">Sip</IonTitle>
              )}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {/* WINE TITLE CARD */}
          {/* // todo: reorganize/combine cards to cleanup layout */}
          <IonCard onClick={() => handleShowWineDetail(sip?.libation)} style={{ cursor: 'pointer' }}>
            <IonCardHeader mode="ios">
              <IonGrid className="ion-no-padding ion-no-margin">
                <IonRow>
                  <IonCol>
                    <IonRow>
                      <IonNote>
                        {sip?.libation.listing?.name || sip?.libation.listingName}
                      </IonNote>
                    </IonRow>
                    <IonRow>
                      <IonCardTitle>
                        {sip?.libation.name || 'Sip'}
                      </IonCardTitle>
                    </IonRow>
                    <IonRow>
                      <IonCardSubtitle className="ion-margin-bottom">
                        <div>
                          {sip?.libation.vintage ? `${sip?.libation.vintage} ` : ''}
                          {sip?.libation.varietal?.name || 'wine'}
                          {sip?.libation.libationType?.name ? ` (${sip?.libation.libationType?.name})` : ''}
                        </div>
                      </IonCardSubtitle>
                    </IonRow>
                  </IonCol>
                  <IonCol size="auto" className="ion-flex ion-align-items-center">
                    <IonIcon size="small" icon={chevronForwardOutline} />
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCardHeader>

          </IonCard>
          <form onSubmit={handleSubmitSip}>
            {!sip?.checkinId
              && (
                <IonCard>
                  <IonItem lines="full">
                    <IonLabel>Location</IonLabel>
                  </IonItem>
                  <IonCardContent>
                    <IonInput
                      value={sipLocation}
                      onIonChange={(e) => setSipLocation(e.detail.value)}
                      placeholder="Where did you have this sip?"
                      type="text"
                      disabled={!allowEdit}
                      onIonFocus={scrollToBottom}
                    />
                  </IonCardContent>
                </IonCard>
              )}
            <IonCard>
              <IonItem lines="full">
                <IonLabel>What&apos;d you think?</IonLabel>
              </IonItem>
              <IonCardContent className="rating-card ion-padding-top">

                <SipRating
                  handleChangeRating={setSipRating}
                  rating={sipRating}
                  isDisabled={!allowEdit}
                />

              </IonCardContent>
            </IonCard>
            <IonCard>
              <IonItem lines="full">
                <IonLabel>Notes</IonLabel>
              </IonItem>
              <IonCardContent>
                {allowEdit && (
                  <IonTextarea
                    rows="5"
                    value={sipNotes}
                    disabled={!allowEdit}
                    onIonChange={(e) => setSipNotes(e.target.value)}
                    onIonFocus={scrollToBottom}
                    placeholder="Any notes on this sip?"
                    autocapitalize="sentences"
                  />
                )}
                {!allowEdit && (
                  <IonLabel>
                    {sipNotes || 'No notes were added for this Sip.'}
                  </IonLabel>
                )}
              </IonCardContent>
            </IonCard>
          </form>
          {/* LISTING TITLE CARD */}
          <IonCard onClick={handleNavigateListing}>
            <ListingHeader listingId={sip?.libation?.listingId} listingData={sip?.libation?.listing || sip?.libation.listingName} height="13vh" />
          </IonCard>
        </IonContent>
      </IonModal>
    </>
  );
};

export default SipForm;
