import React from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonChip,
  IonItem,
  IonLabel,
  IonModal,
  IonNote,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonLoading,
} from '@ionic/react';
import { add, arrowBackSharp, chevronForwardOutline, wine } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import getUserLocation from '../lib/Geo';
import {
  logCheckin,
} from '../lib/logAction';
import { loadCheckins } from '../lib/api-utils';
import { setRecentCheckins, setSelectedCheckin } from '../lib/features/listingsSlice';
import { updateCheckinItem, addNewCheckin, addNewSip } from '../lib/features/profileSlice';
import client from '../lib/feathers';
import VinoMarkdown from './VinoMarkdown';

const FlightDetail = (props) => {
  const {
    isOpen,
    onDismiss,
    handleShowWine,
    dismissFlightListModal,
  } = props;

  // paramID is either: listingId, or, checkinId
  const { id: paramId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    selectedCheckin: checkin,
    hasRecentCheckin,
    recentCheckins,
    selectedFlight: flight,
  } = useSelector((state) => state.listings);
  const auth = useSelector((state) => state.auth);
  const mostRecentCheckin = ([...recentCheckins].reverse())[0];
  const sips = checkin?.sips ?? [];

  const [present, dismiss] = useIonLoading();

  const [presentWarningAlert] = useIonAlert();
  const [presentCheckAlert] = useIonAlert();

  const showAlert = (textToDisplay) => {
    presentWarningAlert({
      header: 'Hold Up!',
      message: textToDisplay,
      buttons: [
        {
          text: 'OK',
        },
      ],
    });
  };

  const showSuccessAlert = (wasFiltered, checkinId) => {
    presentWarningAlert({
      header: 'Success!',
      message: `Wines from this flight have been added to your Sips.
        ${wasFiltered ? ' You had some duplicates, so we made sure not to add those.' : ''}`,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            onDismiss();
            // If we're not currently at a Check-In, navigate the user:
            if (paramId !== checkinId) {
              history.push(`/listing/checkin/${checkinId}`);
            }
            // if coming from a Check-In, close flight list modal:
            if (dismissFlightListModal) {
              dismissFlightListModal();
            }
          },
        },
      ],
    });
  };

  const handleAddingSips = async (checkinId) => {
    const { flightLibations } = flight;

    let filteredFlightLibations = flightLibations;

    // If the current check-in has sips, check if there are repeats:
    if (sips.length > 0) {
      filteredFlightLibations = flightLibations.filter((fLibation) => (
        ![...sips].some((sip) => sip.libationId === fLibation.libationId)
      ));
    }
    // Check if we indeed filtered out repeats:
    const wasFiltered = filteredFlightLibations.length !== flightLibations.length;
    // If we filtered all the flight items out, cancel everything:
    if ((filteredFlightLibations.length === 0)) {
      showAlert('You already have Sips for all of these wines.');
      return;
    }

    try {
      await present('Loading...');
      const sipsToAdd = await Promise.all(
        filteredFlightLibations.map(async (flightLibation) => {
          const service = client.service('client/sip');
          const sipData = {
            libationId: flightLibation.libationId,
            checkinId,
          };
          const response = await service.create(sipData);
          return { ...response, libation: flightLibation.libation };
        }),
      );

      sipsToAdd.forEach((sip) => {
        dispatch(addNewSip(sip)); // update profile slice
      });

      if (checkin) {
        const updatedCheckin = { ...checkin, sips: [...sips, ...sipsToAdd] };
        dispatch(setSelectedCheckin(updatedCheckin)); // update listing slice
        dispatch(updateCheckinItem(updatedCheckin)); // update profile slice
      }

      await dismiss();
      showSuccessAlert(wasFiltered, checkinId);
    } catch (error) {
      await dismiss();
      showAlert('Whoops! Something went wrong, we couldn\'t add this flight to your Sips.');
      console.log('Error adding Flight to Sips: ', error);
    }
  };

  const getUserCoordinates = async () => {
    try {
      const geoLocation = await getUserLocation();
      return geoLocation.coords;
    } catch (err) {
      console.log('Unable to load location', err);
      throw new Error('Unable to load user location.');
    }
  };

  const handleCheckin = async () => {
    logCheckin(paramId);
    try {
      await present('Loading...');

      const { longitude, latitude } = await getUserCoordinates();

      const service = client.service('client/checkin');

      await service.create({
        listingId: paramId,
        location: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      });

      const checkins = await loadCheckins(paramId);

      dispatch(setRecentCheckins(checkins));

      const lastCheckin = ([...checkins].reverse())[0];
      dispatch(setSelectedCheckin(lastCheckin));
      dispatch(addNewCheckin(lastCheckin));

      await handleAddingSips(lastCheckin.id);
      await dismiss();
    } catch (err) {
      await dismiss();
      console.log('Error checking in: ', err);
      showAlert('Sorry we were unable to check you in.');
    }
  };

  const showAuthAlert = () => {
    presentCheckAlert({
      header: 'Hold Up!',
      message: 'Silly Seeker, flights are for users. Login to manage your flights.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Login',
          id: 'confirm-button',
          handler: () => {
            history.push('/login');
          },
        },
      ],
    });
  };

  const showAddSipAlert = () => {
    presentCheckAlert({
      header: 'Add Sips',
      message: 'Would you like to add this flight to your sips?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: () => {
            handleAddingSips(checkin?.id);
          },
        },
      ],
    });
  };

  const showCheckinAlert = () => {
    presentCheckAlert({
      header: 'Hold Up!',
      message: 'Flights can only be added when checked in to a listing.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Check In & Add',
          handler: () => {
            handleCheckin();
          },
        },
      ],
    });
  };

  // Is the user adding a flight from a checkin? Check user is currently looking at that check-in. (Allow updates to old check-in.)
  const isAtCheckin = (checkin?.id === paramId);
  // Or is the user adding a flight from a listing where they have recently checked in? (Allow updates to most recent check-in.)
  // And does the most recent checkin.listingId match the current listing we're viewing? (Allow update to that recent check-in only if we're at the correct listing.)
  const atRecentlyCheckedInListing = (hasRecentCheckin && (mostRecentCheckin.listingId === paramId));
  const hasCheckinToUpdate = isAtCheckin || atRecentlyCheckedInListing;

  const checkUser = () => {
    if (!auth.authenticated) {
      // Need to log in.
      showAuthAlert();
    } else if (hasCheckinToUpdate) {
      // All is well, add to sips.
      showAddSipAlert();
    } else {
      // Need to check in.
      showCheckinAlert();
    }
  };

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onDismiss}>
                <IonIcon icon={arrowBackSharp} className="ion-size-md-sm" color="primary" />
              </IonButton>
            </IonButtons>
            <IonTitle class="ion-text-center">
              Flight Details
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton strong type="button" onClick={checkUser}>
                {/* Create Sips Icon */}
                <IonIcon icon={add} size="large" color="primary" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonCard>
            <IonCardHeader mode="ios">
              <IonNote>
                {flight?.listing?.name}
              </IonNote>
              <IonCardTitle className="ion-flex ion-justify-content-between ion-align-items-center">
                {flight?.name || 'Flight'}
              </IonCardTitle>
            </IonCardHeader>
            {flight?.description !== null
              && (
                <IonItem lines="none" className="ion-padding-bottom">
                  {/* Adding a className renders a new div within the ReactMarkdown component */}
                  <VinoMarkdown className="line-break">
                    {flight?.description}
                  </VinoMarkdown>
                </IonItem>
              )}
          </IonCard>
          <IonCard>
            <IonItem>
              <h2>Flight</h2>
            </IonItem>
            {flight?.flightLibations?.length > 0
              && (flight?.flightLibations?.map((flightLibation) => (
                <IonItem
                  button
                  detail={false}
                  key={flightLibation.id}
                  onClick={() => handleShowWine(flightLibation.libation)}
                >
                  <IonGrid className="ion-no-padding-left ion-no-padding-right">
                    <IonRow>
                      <IonCol className="ion-no-padding">
                        <IonRow className="ion-align-items-center ion-no-padding">
                          <IonCol size="auto" className="ion-no-padding-left">
                            <IonChip
                              className="wine-chip ion-no-margin"
                            >
                              <IonIcon
                                icon={wine}
                                size="large"
                                className="ion-no-margin"
                                // color="primary"
                                style={{ color: flightLibation.libation?.varietal ? `var(--${flightLibation.libation?.varietal?.color.replace(' ', '-')})` : 'primary-contrast' }}
                              />
                            </IonChip>
                          </IonCol>
                          <IonCol>
                            <IonLabel>
                              <IonRow>
                                <h2><b>{`${flightLibation.libation?.name}`}</b></h2>
                              </IonRow>
                              <IonRow>
                                <p>{`${flightLibation.libation?.vintage} ${flightLibation.libation?.varietal?.name || ''}`}</p>
                              </IonRow>
                            </IonLabel>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonNote style={{ paddingLeft: '8px', paddingRight: '8px' }}>
                            <VinoMarkdown className="line-break">
                              {flightLibation.libation.tastingNotes}
                            </VinoMarkdown>
                          </IonNote>
                        </IonRow>
                      </IonCol>
                      <IonCol size="auto" className="ion-align-self-center">
                        <IonIcon icon={chevronForwardOutline} size="small" />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonItem>
              )))}
          </IonCard>
        </IonContent>
      </IonModal>
    </>
  );
};

export default FlightDetail;
