import React from 'react';
import '../InfoModal.scss';

import {
  IonLabel,
  IonItem,
  IonText,
  IonThumbnail,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

const MapMarkers = () => (
  <div className="wrapper">
    <IonItem lines="full">
      <IonLabel>
        <h1 className="ion-text-center"><strong>VinoSeeker Marker System</strong></h1>
      </IonLabel>
    </IonItem>
    <IonItem lines="full">
      <img
        src="../../assets/infoPopup/markerSystem.png"
        alt="map with markers"
        className="image"
      />
    </IonItem>
    <IonGrid className="ion-align-items-baseline">
      <IonRow className="gridRow">
        <IonCol>
          <div className="center">
            <IonThumbnail slot="start" class="thumbnail">
              <img
                alt="Unverified marker"
                src="./assets/unverifiedMarker.png"
              />
            </IonThumbnail>
            <IonLabel>
              <h2><strong>Unverified</strong></h2>
              <em>Data provided by Foursquare, information not verified</em>
            </IonLabel>
          </div>
        </IonCol>
        <IonCol>
          <div className="center">
            <IonThumbnail slot="start" class="thumbnail">
              <img
                src="./assets/verifiedMarker.png"
                alt="Verified Marker"
              />
            </IonThumbnail>
            <IonLabel>
              <IonText>
                <h2><strong>Verified</strong></h2>
                <em>Information verified by VinoSeeker</em>
              </IonText>
            </IonLabel>
          </div>
        </IonCol>
      </IonRow>
      <IonRow className="gridRow">
        <IonCol>
          <div className="center">
            <IonThumbnail slot="start" class="thumbnail-wine">
              <img
                alt="Claimed Marker"
                src="./assets/claimedMarker.png"
              />
            </IonThumbnail>
            <IonLabel>
              <IonText>
                <h2><strong>Claimed</strong></h2>
                <em>Listing owner provided content</em>
              </IonText>
            </IonLabel>
          </div>
        </IonCol>
        <IonCol>
          <div className="center">
            <IonThumbnail slot="start" class="thumbnail-wine">
              <img
                src="./assets/sponsoredMarker.png"
                alt="Sponsored Listing Marker"
              />
            </IonThumbnail>
            <IonLabel>
              <IonText text-wrap>
                <h2><strong>Featured</strong></h2>
                <em>Listing currently offering promotions to VinoSeeker VIPs</em>
              </IonText>
            </IonLabel>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  </div>
);

export default MapMarkers;
