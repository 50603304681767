import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const VinoMarkdown = (props) => {
  const { children } = props;

  return (
    <Markdown
      rehypePlugins={[rehypeRaw]}
      className="vino-markdown"
      components={{
        a(aprops) {
          return (<a href={aprops.href} rel="noreferrer" target="_blank">{aprops.children}</a>);
        },
      }}
    >
      {children}
    </Markdown>
  );
};

export default VinoMarkdown;
