import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  listings: [],
  focusItem: undefined,
  recentCheckins: [],
  selectedListing: undefined,
  selectedCheckin: undefined,
  hasRecentCheckin: false,
  checkinAttachments: [],
  listingFlights: [],
  selectedFlight: undefined,
  listingLibations: [],
  selectedLibation: undefined,
};

export const listingsSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    setFocusItem: (state, action) => {
      state.focusItem = action.payload;
    },
    // *** LISTING ***
    setListings: (state, action) => {
      state.listings = action.payload;
    },
    setSelectedListing: (state, action) => {
      state.selectedListing = action.payload;
    },
    // *** CHECKINS ***
    setRecentCheckins: (state, action) => {
      const checkins = action.payload;
      state.recentCheckins = checkins;
      state.hasRecentCheckin = false; // default value to nope
      const stabletime = (new Date()).getTime() - 1000 * 60 * 60 * 4;
      if (checkins[checkins.length - 1]) {
        const recCheck = checkins[checkins.length - 1];
        const recCheckAt = (new Date(recCheck.createdAt)).getTime();
        // console.log('state', new Date(stabletime), (new Date(recCheck.createdAt)), stabletime - recCheckAt < 0, stabletime, recCheckAt)
        state.hasRecentCheckin = stabletime - recCheckAt < 0;
      }
    },
    updateRecentCheckinItem: (state, action) => {
      const updatedCheckinItem = action.payload;

      const checkinIndex = state.recentCheckins.findIndex((checkin) => checkin.id === updatedCheckinItem.id);
      const updatedCheckins = [...state.recentCheckins];
      const oldCheckinItem = updatedCheckins[checkinIndex];

      // Update the object in our Checkin at its original index, while making sure to maintain necessary properties.
      updatedCheckins[checkinIndex] = { ...oldCheckinItem, ...updatedCheckinItem };
      state.recentCheckins = updatedCheckins;
    },
    setSelectedCheckin: (state, action) => {
      state.selectedCheckin = action.payload;
    },
    setHasRecentCheckin: (state, action) => {
      state.hasRecentCheckin = action.payload;
    },
    setCheckinAttachments: (state, action) => {
      state.checkinAttachments = action.payload;
    },
    // *** LISTING FLIGHTS ****
    setListingFlights: (state, action) => {
      state.listingFlights = action.payload;
    },
    setSelectedFlight: (state, action) => {
      state.selectedFlight = action.payload;
    },
    // *** LISTING LIBATIONS ***
    setListingLibations: (state, action) => {
      state.listingLibations = action.payload;
    },
    updateLibation: (state, action) => {
      const updatedLibation = action.payload;

      const updatedLibationList = [...state.listingLibations].map((libation) => {
        if (libation.id === updatedLibation.id) {
          return { ...libation, ...updatedLibation };
        }
        return libation;
      });

      state.selectedLibation = { ...state.selectedLibation, ...updatedLibation };
      state.listingLibations = updatedLibationList;
    },
    setSelectedLibation: (state, action) => {
      state.selectedLibation = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setFocusItem,
  setListings,
  setRecentCheckins,
  setSelectedCheckin,
  setSelectedListing,
  setCheckinAttachments,
  setSelectedCheckinAttachment,
  setListingFlights,
  setListingLibations,
  setSelectedFlight,
  setSelectedLibation,
  updateLibation,
  updateRecentCheckinItem,
} = listingsSlice.actions;

export default listingsSlice.reducer;
