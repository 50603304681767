import {
  IonCard, IonCardContent, IonItem, IonLabel, IonProgressBar,
} from '@ionic/react';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { Swiper, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import client from '../lib/feathers';
import { BackgroundImage, checkHasImage } from './ListingHeader';

const NearbyListings = ({ location, listingId }) => {
  const history = useHistory();
  const [nearby, setNearby] = useState();
  const sliderEl = useRef(null);

  const getListingsByLocation = async (loc, source) => {
    const service = client.service('client/listing-search');
    const result = await service.find({
      query: {
        ll: `${loc.longitude},${loc.latitude}`,
        source,
        $limit: 6,
      },

    });

    const nearbyWOMe = result.filter((l) => l.id !== listingId);
    setNearby(nearbyWOMe.slice(0, 5));
  };

  const onNearbyClick = (lId) => {
    history.push(lId);
    sliderEl.current.swiper.slideTo(0);
  };

  const formatDistance = (dInMeters) => {
    const inMiles = dInMeters * 0.00062137;
    const formatted = Math.round(inMiles * 100) / 100;
    return `${formatted} mi`;
  };

  const loadNearby = () => {
    getListingsByLocation({
      latitude: location.latitude,
      longitude: location.longitude,
    }, 'nearby');
  };
  // useEffect(loadNearby, []);
  useEffect(loadNearby, [listingId]);

  return (
    <IonCard>
      <IonItem>
        <IonLabel>
          Nearby Listings
        </IonLabel>
      </IonItem>
      <IonCardContent className="ion-no-padding">
        {nearby && (
          <Swiper
            className="nearbySlides"
            slidesPerView={1.5}
            spaceBetween={10}
            ref={sliderEl}
          >
            {nearby.map((v, index) => (
              <SwiperSlide key={v.id} onClick={() => onNearbyClick(`/listing/${v.id}`)}>

                <IonCard button color="light" className="locationCard" type="button">
                  <BackgroundImage source={v}>
                    <div
                      className={checkHasImage(v) ? 'listingBannerGradient' : ''}
                      style={{
                        height: '100%',
                        padding: '8px 5px',
                      }}
                    >
                      <IonCardContent className="ion-no-padding">
                        <ion-row>
                          <ion-col>
                            <div className="circle ion-float-left">{index + 1}</div>
                          </ion-col>
                          <ion-col>
                            <span className="ion-float-right">{formatDistance(v.distance)}</span>
                          </ion-col>
                        </ion-row>
                        <ion-row>
                          <ion-col>
                            <h1 className="ion-text-nowrap ion-text-start ion-float-clear">{v.name}</h1>
                          </ion-col>
                        </ion-row>

                      </IonCardContent>
                    </div>
                  </BackgroundImage>
                </IonCard>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        {!nearby && (
          <IonProgressBar type="indeterminate" />
        )}
      </IonCardContent>
    </IonCard>
  );
};

export default NearbyListings;
