import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { fromLonLat } from 'ol/proj';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';

const buildMarker = (venue, index, depth) => {
  // const pinColor =  % 4 ? "#999999" : "#000000"//"#9F0500"
  const unverifiedMarker = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // color: pinColor,
      crossOrigin: 'anonymous',
      src: '/assets/unverifiedMarker.png',
      scale: 1,
    }),
    text: new Text({
      text: index.toString(),
      scale: 2,
      offsetY: -32,
      offsetX: 0.5,
      fill: new Fill({ color: '#000' }),
      textAlign: 'center',
      textBaseline: 'top',

    }),
    zIndex: depth - index,
  });
  const verifiedMarker = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // color: pinColor,
      crossOrigin: 'anonymous',
      src: '/assets/verifiedMarker.png',
      scale: 1,
    }),
    text: new Text({
      text: index.toString(),
      scale: 2,
      offsetY: -32,
      offsetX: 0.5,
      fill: new Fill({ color: '#000' }),
      textAlign: 'center',
      textBaseline: 'top',

    }),
    zIndex: depth - index,
  });
  const claimedMarker = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // color: pinColor,
      crossOrigin: 'anonymous',
      src: '/assets/claimedMarker.png',
      scale: 1,
    }),
    text: new Text({
      text: index.toString(),
      scale: 2,
      offsetY: -46,
      offsetX: 0,
      fill: new Fill({ color: '#000' }),
      textAlign: 'center',
      textBaseline: 'top',

    }),
    zIndex: index,
  });
  const featuredMarker = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // color: pinColor,
      crossOrigin: 'anonymous',
      src: '/assets/sponsoredMarker.png',
      scale: 1,
    }),
    text: new Text({
      text: index.toString(),
      scale: 2,
      offsetY: -46,
      offsetX: 0,
      fill: new Fill({ color: '#FFF' }),
      textAlign: 'center',
      textBaseline: 'top',

    }),
    zIndex: index,
  });

  const matchedVerifiedMarker = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // color: pinColor,
      crossOrigin: 'anonymous',
      src: '/assets/matchVerifiedMarker.png',
      scale: 1,
    }),
    text: new Text({
      text: index.toString(),
      scale: 2,
      offsetY: -33,
      offsetX: -1.5,
      fill: new Fill({ color: '#000' }),
      textAlign: 'center',
      textBaseline: 'top',
      stroke: new Stroke({ color: '#FFF' }),
    }),
    zIndex: depth - index,
  });

  const matchedClaimedMarker = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // color: pinColor,
      crossOrigin: 'anonymous',
      src: '/assets/matchClaimedMarker.png',
      scale: 1,
    }),
    text: new Text({
      text: index.toString(),
      scale: 2,
      offsetY: -46,
      offsetX: -2,
      fill: new Fill({ color: '#000' }),
      textAlign: 'center',
      textBaseline: 'top',
      stroke: new Stroke({ color: '#FFF' }),
    }),
    zIndex: index,
  });

  const matchedFeaturedMarker = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      // color: pinColor,
      crossOrigin: 'anonymous',
      src: '/assets/matchSponsoredMarker.png',
      scale: 1,
    }),
    text: new Text({
      text: index.toString(),
      scale: 2,
      offsetY: -46,
      offsetX: -2,
      fill: new Fill({ color: '#FFF' }),
      textAlign: 'center',
      textBaseline: 'top',
      stroke: new Stroke({ color: '#000' }),
    }),
    zIndex: index,
  });

  const markerFeature = new Feature({
    // geometry: new Point(fromLonLat([-123.009566, 45.279232])) // alit
    geometry: new Point(fromLonLat([venue.lng, venue.lat])),
    name: venue.name,
    index,
    status: venue.status,
  });
  markerFeature.setId(venue.venueId);

  if (venue.status === 'unverified') {
    markerFeature.setStyle(unverifiedMarker);
  } else if (venue.status === 'verified' && venue.exactMatch) {
    markerFeature.setStyle(matchedVerifiedMarker);
  } else if (venue.status === 'verified') {
    markerFeature.setStyle(verifiedMarker);
  } else if (venue.status === 'claimed' && venue.exactMatch) {
    markerFeature.setStyle(matchedClaimedMarker);
  } else if (venue.status === 'claimed') {
    markerFeature.setStyle(claimedMarker);
  } else if (venue.status === 'featured' && venue.exactMatch) {
    markerFeature.setStyle(matchedFeaturedMarker);
  } else if (venue.status === 'featured') {
    markerFeature.setStyle(featuredMarker);
  }

  return markerFeature;
};

export default buildMarker;
