import { useMemo } from 'react';
import { useIonRouter } from '@ionic/react';

// A custom hook that builds on ionic's useIonRouter,
// parses the search parameters of the current route/URL.
// Returns a URLSearchParams object.
//
// Access URL query key/value pairs with the get() method,
// ie:
// where the current URL might be:
// https://www.example.com/?name=John
// we access our search params with:
// const params = useURLSearchParams();
// const name = params.get('name');

const useURLSearchParams = () => {
  const { routeInfo: { search } } = useIonRouter();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useURLSearchParams;
