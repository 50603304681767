import { IonButtons, IonButton, IonIcon, IonBackButton, IonPage, IonTitle, IonToolbar, IonContent, IonActionSheet } from '@ionic/react';
import { ellipsisHorizontal, earth, peopleOutline, eyeOffOutline, closeCircle } from 'ionicons/icons';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Zoom } from 'swiper';
import { setCheckinAttachments } from '../lib/features/listingsSlice';
import EditPhotoCaptionAndPrivacyForm from '../components/EditPhotoCaptionandPrivacyForm';
import client from '../lib/feathers';

const ListingCheckinPhoto = (props) => {
  const dispatch = useDispatch();
  const { match, history } = props;
  const { id: checkinId, attachmentId } = match.params;
  const checkin = useSelector((state) => state.listings?.selectedCheckin);
  const attachments = useSelector((state) => state.listings?.checkinAttachments);
  const user = useSelector((state) => state.auth?.user);

  const [overlay, setOverlay] = useState(true);
  const [actionSheetIsOpen, setActionSheetIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const cai = attachments.indexOf(attachments.find((att) => att.id === attachmentId));
  const [currentAttachmentIndex, setCurrentAttachmentIndex] = useState(cai);
  const currentAttachment = attachments[currentAttachmentIndex];

  const isOwner = user?.id === currentAttachment?.userId;

  if (!checkin) {
    history.push(`/listing/checkin/${checkinId}`);
    return null;
  }

  const formattedDate = (createdAt) => {
    const fd = new Date(createdAt).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
    return fd;
  };

  let privacyIcon = earth;
  if (currentAttachment) {
    if (currentAttachment.privacy === 'private') {
      privacyIcon = eyeOffOutline;
    } else if (currentAttachment.privacy === 'protected') {
      privacyIcon = peopleOutline;
    }
  }

  const deletePhoto = async (attId) => {
    const proceed = confirm('Are you sure you want to delete this file?'); // eslint-disable-line no-restricted-globals
    if (proceed) {
      const attachmentService = client.service('client/attachment');
      await attachmentService.remove(attId);
      const newAttachmentsArray = attachments.filter((entry) => entry.id !== attId);
      dispatch(setCheckinAttachments(newAttachmentsArray));
      setCurrentAttachmentIndex(0);
      history.push(`/listing/checkin/${checkin.id}`);
    }
  };

  const downloadPhoto = async (downloadAttachment) => {
    // console.log('ca', downloadAttachment);
    const extension = downloadAttachment.mime.split('/')[1];
    const imageURL = `${process.env.REACT_APP_ATTACHMENT_URL}/${downloadAttachment.path}?o=true`;

    try {
      if (navigator.share) {
        navigator.share({
          url: imageURL,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error));
      } else {
        await fetch(imageURL).then((imgResponse) => {
          imgResponse.blob().then((blob) => {
            const fileURL = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `${downloadAttachment.id}.${extension}`;
            link.click();
          });
        });
      }
    } catch (err) {
      throw new Error('Image download failed.');
    }
  };

  const onSlideChange = (event) => {
    setCurrentAttachmentIndex(event.activeIndex);
    // setCurrentAttachment(attachments[event.activeIndex]);
  };

  return (
    <IonPage>
      <IonContent>
        <Swiper
          className={`centeredContent ${!overlay ? 'hide-nav' : ''}`}
          modules={[Zoom, Navigation]}
          initialSlide={currentAttachmentIndex}
          onSlideChange={onSlideChange}
          zoom
          navigation
          observer
          observeParents
        >
          {attachments.map((attachment) => (
            <SwiperSlide key={attachment.path} onClick={() => setOverlay((currentState) => !currentState)}>
              <div className="swiperImageContainer">
                <div className="swiper-zoom-container">
                  <img src={`${process.env.REACT_APP_ATTACHMENT_URL}/${attachment.path}?w1920&h=1080&f=inside`} alt="" />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {overlay && currentAttachment && (
          <>
            <div className="topOverlay">
              <div>
                <IonToolbar className="customToolbar">
                  <IonButtons slot="start">
                    <IonBackButton defaultHref={`/listing/checkin/${checkinId}`} text="" icon={closeCircle} color="tertiary" />
                  </IonButtons>
                  <IonTitle style={{ textAlign: 'center', color: 'white' }}>{`${attachments.findIndex((a) => a.id === currentAttachment.id) + 1} of ${attachments.length}`}</IonTitle>
                  <IonButtons slot="end">
                    <IonButton
                      color="tertiary"
                      onClick={() => {
                        setActionSheetIsOpen(true);
                      }}
                    >
                      <IonIcon ios={ellipsisHorizontal} md={ellipsisHorizontal} size="large" />
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </div>
            </div>
            <div className="overlay">
              <h3>{checkin.listing.name}</h3>
              <h6>
                {formattedDate(currentAttachment.createdAt)}
                <IonIcon style={{ marginLeft: '4px' }} slot="start" size="medium" icon={privacyIcon} />
              </h6>
              <p style={{ fontSize: 12 }}>{currentAttachment.caption}</p>
            </div>
          </>
        )}
        <IonActionSheet
          isOpen={actionSheetIsOpen}
          buttons={[
            {
              text: 'Download',
              handler: () => downloadPhoto(currentAttachment),
            },
            ...(isOwner
              ? [
                {
                  text: 'Delete',
                  role: 'destructive',
                  handler: () => deletePhoto(currentAttachment.id),
                },
                {
                  text: 'Edit',
                  handler: () => setEditModalIsOpen(true),
                },
              ] : []
            ),
            {
              text: 'Cancel',
              role: 'cancel',
              data: {
                action: 'cancel',
              },
            },
          ]}
          onDidDismiss={() => setActionSheetIsOpen(false)}
        />

        {editModalIsOpen && <EditPhotoCaptionAndPrivacyForm closeModal={setEditModalIsOpen} photo={currentAttachment} attachments={attachments} />}
      </IonContent>
    </IonPage>
  );
};

export default ListingCheckinPhoto;
