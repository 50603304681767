import { Geolocation } from '@capacitor/geolocation';

let lastCheck;
const refreshTime = 20 * 60 * 1000;
let lastGeo;

const getUserLocation = async (force = false) => {
  const staleTime = (new Date()).getTime() - refreshTime;
  const geoPermission = await Geolocation.checkPermissions();

  if (geoPermission.location === 'denied') { // 'granted' 'prompt' are other values
    console.log('denied browser', geoPermission);
    throw (new Error(0)); // user denied
  } else {
    if (!lastCheck || force || lastCheck < staleTime) {
      try {
        lastGeo = await Geolocation.getCurrentPosition();
      } catch (e) {
        throw (new Error(e.code)); // 1. system denied 2. location unavailalbe 3. timeout
      }
      lastCheck = (new Date()).getTime();
    }

    return lastGeo;
  }
};

export default getUserLocation;
