import React, { useState } from 'react';
import {
  IonCardContent,
  IonIcon,
  // IonItem,
  // IonList,
} from '@ionic/react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination } from 'swiper';
import { camera } from 'ionicons/icons';
import './PhotoList.scss';
import ListingGalleryModal from '../pages/ListingGalleryModal';

const PhotoList = (props) => {
  const { attachments, editable } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openModal = (i) => {
    setCurrentImageIndex(i);
    setIsOpen(true);
  };

  return (
    <>
      <IonCardContent className="attachmentCard">
        {attachments.map((attachment, i) => (
          <div className="attachementWrapper" key={attachment.path}>
            <div className="loader">
              <IonIcon src={camera} size="large" />
            </div>
            <button
              type="button"
              // onClick={() => onPhotoClick(attachment)}
              onClick={() => openModal(i)}
            >
              <img
                className="photo"
                alt="gallery item"
                src={`${process.env.REACT_APP_ATTACHMENT_URL}/${attachment.path}?w=1080&h=1080`}
              />
            </button>
          </div>
        ))}
      </IonCardContent>
      <ListingGalleryModal
        editable={editable}
        attachments={attachments}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        genericAltText="No description available."
        currentImageIndex={currentImageIndex}
      />
    </>
  // <IonList>
  //   <IonItem>
  //     <Swiper
  //       slidesPerView="auto"
  //       modules={[Pagination]}
  //       pagination={{ type: 'bullets', clickable: 'true' }}
  //       style={{ '--swiper-pagination-color': '#800' }}
  //     >
  //       {attachments.map((attachment, i) => (
  //         <SwiperSlide className="swiper-glide" key={attachment.path} onClick={() => openModal(i)}>
  //           <img className="swiperImage" src={`${process.env.REACT_APP_ATTACHMENT_URL}/${attachment.path}`} alt={attachment.caption ? attachment.caption : 'No description available.'} />
  //         </SwiperSlide>
  //       ))}
  //     </Swiper>
  //   </IonItem>
  // </IonList>
  );
};

export default PhotoList;
