/* eslint-disable consistent-return, import/prefer-default-export */
import moment from 'moment-timezone';
import client from './feathers';

const loadCheckins = async (listingId) => {
  const service = client.service('client/checkin');
  // const stabletime = (new Date()).getTime() - 1000 * 60 * 60 * 4;
  // console.log('stabletime', stabletime, new Date(stabletime));

  const query = {
    $limit: 100,
    $sort: {
      createdAt: 1,
    },
    listingId,
    // createdAt: {
    //   $lt: new Date(stabletime),
    // },
  };

  try {
    const result = await service.find({
      query,
    });

    return result.data;
  } catch (e) {
    console.log('failed to load past checkins');
    return [];
  }
};

const loadCheckin = async (checkinId) => {
  const service = client.service('client/checkin');

  try {
    const result = await service.get(checkinId);

    return result;
  } catch (e) {
    console.log('failed to load check-in');
    throw new Error('Failed to load Check-In: ', e);
  }
  // return [];
};

const makeCheckinGroups = (checkins = []) => {
  const gs = {};
  checkins.forEach((checkin) => {
    const ago = moment(checkin.createdAt).fromNow();
    if (!gs[ago]) {
      gs[ago] = [];
    }
    gs[ago].push(checkin);
  });
  return gs;
};

const loadListingListListings = async (listingId) => {
  const service = client.service('client/listing-list-listing');

  const query = {
    $limit: 100,
    $sort: {
      createdAt: -1,
    },
    listingId,
  };

  try {
    const result = await service.find({
      query,
    });

    return result.data;
  } catch (e) {
    console.log('failed to load the listing-list-listings for this listing');
  }
  return [];
};

const loadSips = async (listingId) => {
  const service = client.service('client/sip');
  const query = {
    $sort: {
      createdAt: -1,
    },
  };

  if (listingId !== undefined) {
    query['$libation.listingId$'] = listingId;
  }

  try {
    const result = await service.find({
      query,
    });

    return result.data;
  } catch (e) {
    console.log('failed to load the sips for this listing');
  }
  return [];
};

export {
  loadCheckins,
  loadCheckin,
  makeCheckinGroups,
  loadListingListListings,
  loadSips,
};
