import {
  IonAlert,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { lockOpenOutline } from 'ionicons/icons';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import client from '../lib/feathers';

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState();
  const [showSuccess, setShowSuccess] = useState(false);

  const { keyboardHeight } = useKeyboardState();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setShowSuccess(true);

    try {
      const service = client.service('client/forgot-password');
      await service.create({ email });

      setShowSuccess(true);
    } catch (err) {
      console.log('error resetting password', err);
    }
  };

  return (
    <IonPage>
      <IonAlert
        isOpen={showSuccess}
        onDidDismiss={() => { setShowSuccess(false); history.push('/login'); }}
        cssClass="my-custom-class"
        header="Reset Success"
        subHeader="Check Your Email"
        message="We have sent you an email with reset instructions."
        buttons={['OK']}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>Forgot Password</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        style={{
          paddingBottom: keyboardHeight,
        }}
      >
        <form action="#" onSubmit={handleFormSubmit} method="POST">
          <IonRow>
            <IonCol className="ion-text-center">
              <IonIcon
                color="primary"
                style={{ fontSize: '70px' }}
                icon={lockOpenOutline}
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              Enter the email address associated with your account and we will send you instructions to reset your password
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonItem>
                <IonInput
                  type="email"
                  label="Email"
                  labelPlacement="floating"
                  value={email}
                  inputMode="email"
                  autocomplete="email"
                  onIonChange={(e) => setEmail(e.detail.value)}
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  title="Must be a valid email address"
                  required
                />
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="ion-text-center">
              <IonButton expand="block" type="submit">Send Reset Instructions</IonButton>

              Don&apos;t have an account?
              &nbsp;
              <Link to="/register">Join for Free</Link>
            </IonCol>
          </IonRow>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;
