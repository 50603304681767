import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFriendInvites } from '../lib/api/friend-services';
import { setInvites } from '../lib/features/friendsSlice';

const useFriendInvites = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth?.user);
  const { invites } = useSelector((state) => state.friends);

  const sent = useMemo(() => (
    [...invites].filter((req) => req.userId === user.id)
  ), [invites]);

  const received = useMemo(() => (
    [...invites].filter((req) => req.recipientId === user.id)
  ), [invites]);

  const [isLoading, setIsLoading] = useState(false);

  const loadAllFriendInvites = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = await getAllFriendInvites();
      dispatch(setInvites(results));
    } catch (err) {
      console.log('error loading invitations', err);
    }
    setIsLoading(false);
  }, [dispatch]);

  const updateInvite = useCallback((requestId) => {
    const updatedList = [...invites].filter((req) => req.id !== requestId);
    dispatch(setInvites(updatedList));
  }, [invites, dispatch]);

  useEffect(() => {
    if (user.id) {
      loadAllFriendInvites();
    }
  }, [user.id]);

  return {
    isLoading,
    invites: { sent, received },
    refetch: loadAllFriendInvites,
    updateInvite,
  };
};

export default useFriendInvites;
