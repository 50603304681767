import React, { useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonList,
  IonItem,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from '@ionic/react';
import { personCircle, eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import client from '../lib/feathers';

const Register = (props) => {
  const { history } = props;
  const [present, dismiss] = useIonLoading();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [privacyTerms, setPrivacyTerms] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordDisplay, setPasswordDisplay] = useState(false);
  let hideOrShow = 'password';
  let iconType = eyeOutline;

  const showErrorWithMessage = (msg) => {
    setErrorMessage(msg);
    setShowError(true);
  };
  const resetInputs = () => {
    setFirstName('');
    setLastName('');
    setUsername('');
    setEmail('');
    setPassword('');
    setPasswordConfirm('');
    setPrivacyTerms(false);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (firstName.length < 1) {
      showErrorWithMessage('Please enter a First Name');
      return;
    }
    if (lastName.length < 1) {
      showErrorWithMessage('Please enter a Last Name');
      return;
    }
    if (username.length < 4) {
      showErrorWithMessage('Please enter a profile name that has at least 4 characters');
      return;
    }
    if (email === '') {
      showErrorWithMessage('Please enter a email');
      return;
    }

    // Validators.required,
    // Validators.minLength(8),
    // Validators.maxLength(100),
    // Validators.pattern(/[A-Z]/),
    // Validators.pattern(/[a-z]/),
    // Validators.pattern(/[\d]/)
    const passStr = password.toString();
    if (passStr.length < 8 || passStr.length > 100 || /[A-Z]/.test(passStr) === false || /[a-z]/.test(passStr) === false || /[\d]/.test(passStr) === false) {
      showErrorWithMessage(
        'Please enter a password that is:<br/><br/><ol><li>At least 8 characters</li><li>less than 100 characters</li><li>Has at least one (1) Capital letter, lowercase letter, number</li></ol>',
      );
      return;
    }
    if (passStr !== passwordConfirm) {
      showErrorWithMessage('Your passwords must match, please try again');
      return;
    }
    if (privacyTerms === false) {
      showErrorWithMessage('You must agree to the VinoSeeker Privacy Policy and Terms and Conditions to register.');
      return;
    }
    console.log('sent it', firstName, lastName, username, email, password.length, privacyTerms);
    const data = {
      firstName,
      lastName,
      username,
      email,
      password,
    };

    try {
      const service = client.service('client/user');
      present('Registering You Now');
      await service.create(data);
      // console.log('new user', user);
      dismiss();
      setShowSuccess(true);
      resetInputs();
    } catch (err) {
      const issue = err.errors[0];
      console.log('error registering', issue.path);
      dismiss();
      switch (issue.path) {
        case 'username':
          alert('It appears that username is already in use. Please try another.');
          break;
        case 'email':
          alert('It appears that email is already in use. Please login to access your account.');
          break;
        default:
          alert('Sorry, we had an issue creating your account. Please try again later. If the issue persists contact service@vinoseeker.com');
          break;
      }
    }
  };

  if (!passwordDisplay) {
    hideOrShow = 'password';
  } else {
    hideOrShow = 'text';
    iconType = eyeOffOutline;
  }

  return (
    <IonPage>
      <IonAlert isOpen={showError} onDidDismiss={() => setShowError(false)} cssClass="my-custom-class" header="Registration Error" message={errorMessage} buttons={['OK']} />
      <IonAlert
        isOpen={showSuccess}
        onDidDismiss={() => {
          setShowSuccess(false);
          history.push('/login');
        }}
        cssClass="my-custom-class"
        header="Registration Success"
        subHeader="Welcome to VinoSeeker!"
        message="Your account has been created! Click ok below and login to proceed!"
        buttons={['OK']}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Create your Account</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form action="#" onSubmit={handleFormSubmit} method="POST">
          <IonList lines="full">
            <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center' }}>
              <IonIcon color="primary" style={{ fontSize: '70px' }} icon={personCircle} />
            </div>
            <IonItem>
              <IonInput
                type="text"
                value={firstName}
                autocapitalize="words"
                autocomplete="given-name"
                minlength="1"
                maxlength="30"
                title="Must only contain letters and must be at least 1 characters long"
                onIonChange={(e) => setFirstName(e.detail.value)}
                labelPlacement="floating"
                required
                label="First Name"
              />
            </IonItem>
            <br />
            <IonItem>
              <IonInput
                type="text"
                value={lastName}
                autocapitalize="words"
                autocomplete="family-name"
                minlength="1"
                maxlength="50"
                title="Must only contain letters and must be at least 1 characters long"
                onIonChange={(e) => setLastName(e.detail.value)}
                required
                labelPlacement="floating"
                label="Last Name"
              />
            </IonItem>
            <br />
            <IonItem>
              <IonInput
                type="text"
                value={username}
                pattern="[a-zA-Z0-9]+"
                minlength="4"
                maxlength="32"
                title="Must be at least 4 characters and can only include numbers and letters"
                onIonChange={(e) => setUsername(e.detail.value)}
                required
                helperText="Must be at least 4 characters and can only include numbers and letters"
                label="Profile Name"
                labelPlacement="floating"
              />
            </IonItem>
            <br />
            <IonItem>
              <IonInput
                type="email"
                value={email}
                inputMode="email"
                autocomplete="email"
                onIonChange={(e) => setEmail(e.detail.value)}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                title="Must be a valid email address"
                labelPlacement="floating"
                label="Email"
                required
              />
            </IonItem>
            <br />
            <IonItem>
              <IonInput
                type={hideOrShow}
                value={password}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                onIonChange={(e) => setPassword(e.detail.value)}
                required
                helperText="Must contain at least one number, one uppercase letter, lowercase letter, and be at least 8 characters."
                label="Password"
                labelPlacement="floating"
              />
              {/* <div slot="label">Must contain at least one number, one uppercase letter, lowercase letter, and be at least 8 characters.</div> */}
              <IonIcon slot="end" icon={iconType} style={{ float: 'right', cursor: 'pointer' }} onClick={() => setPasswordDisplay(!passwordDisplay)} />
            </IonItem>
            <br />
            <IonItem>
              <IonInput
                type={hideOrShow}
                value={passwordConfirm}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must match password above"
                onIonChange={(e) => setPasswordConfirm(e.detail.value)}
                required
                helperText="Must match the password above."
                labelPlacement="floating"
                label="Confirm Password"
              />
              <IonIcon slot="end" icon={iconType} style={{ float: 'right', cursor: 'pointer' }} onClick={() => setPasswordDisplay(!passwordDisplay)} />
            </IonItem>
            <br />
            <IonItem>
              <IonCheckbox checked={privacyTerms} value={privacyTerms} onIonChange={(e) => setPrivacyTerms(e.detail.checked)} required justify="start" labelPlacement="end">
                <div className="ion-text-wrap">
                  I agree to the VinoSeeker&nbsp;
                  <a href="https://vinoseeker.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                  </a>
                  &nbsp; and&nbsp;
                  <a href="https://vinoseeker.com/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                    Terms and Conditions
                  </a>
                </div>
              </IonCheckbox>
            </IonItem>
            <br />
            <IonItem>
              <div style={{ diplay: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%', justifyContent: 'center' }}>
                <IonButton expand="block" size="default" type="submit">
                  Register
                </IonButton>
                {/* fixes submit on enter bug */}
                <input type="submit" style={{ display: 'none' }} />
                <p style={{ fontSize: 'medium' }}>
                  Already have an account? &nbsp;
                  <Link to="/login">Sign in!</Link>
                </p>
              </div>
            </IonItem>
          </IonList>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default Register;
