const levels = {
  LOGO: 1,
  SHORT_DESCRIPTION: 2,
  REMOVE_NEARBY: 4,
  UPLOAD_GALLERY_PHOTOS: 8,
  RESERVATION_LINK: 16,
  WELCOME_MESSAGE: 32,
  WINE_CLUB_PROMOTION: 64,
};

export default levels;
