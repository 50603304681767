import React, { useState, useEffect, createRef } from 'react';

// openlayers
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';

import XYZ from 'ol/source/XYZ';
import { fromLonLat, transform } from 'ol/proj';

import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { defaults as defaultInteractions } from 'ol/interaction';
// import Fill from 'ol/style/Fill';

import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import styles from './ListingMapWrapper.module.css';

const drawFeatures = (map, featuresLayer, features, mapCenter) => {
  if (featuresLayer && features) {
    if (features.length === 0) {
      featuresLayer.getSource().clear();
    } else {
      featuresLayer.setSource(
        new VectorSource({
          features,
        }),
      );
      // console.log('draw', mapCenter);
      if (mapCenter && mapCenter === 'fit') {
        map.getView().fit(featuresLayer.getSource().getExtent(), {
          padding: [50, 10, 10, 10],
        });
      }
    }
  }
};

const ListingMapWrapper = (props) => {
  const {
    homeMarker, selectedVenue, setLocation, features, mapCenter,
  } = props;
  const [map, setMap] = useState();
  const [featuresLayer, setFeaturesLayer] = useState();
  const [homeLayer, setHomeLayer] = useState();
  const [, setView] = useState();
  const [newHomeMarker, setNewHomeMarker] = useState();

  const mapElement = createRef();

  useEffect(() => {
    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
    });
    const homeFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const view = new View({
      projection: 'EPSG:3857',
      center: fromLonLat([-101.309360, 39.400122]),
      zoom: 4,
      maxZoom: 19,
    });
    // const attribution = new Attribution({
    //   collapsible: true
    // });

    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attributions: 'Tiles by Open Street Maps',
          }),

        }),
        initalFeaturesLayer,
        homeFeaturesLayer,
      ],
      view,
      controls: [],
      interactions: defaultInteractions({
        dragPan: true,
        pinchZoom: true,
        doubleClickZoom: false,
        mouseWheelZoom: false,
      }),
    });

    setMap(initialMap);
    setFeaturesLayer(initalFeaturesLayer);
    setHomeLayer(homeFeaturesLayer);
    setView(view);

    drawFeatures(initialMap, initalFeaturesLayer, features, mapCenter);

    // initialMap.on("moveend", (e) => {
    //   onMapMove(toLonLat(getCenter(initialMap.getView().calculateExtent())))
    // });
    if (setLocation) {
      console.log('set loc', setLocation);
      initialMap.on('singleclick', async (event) => {
        // const features = await homeFeaturesLayer.getFeatures(event.pixel);
        const lonlat = transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
        console.log('click on map', event, lonlat);

        setNewHomeMarker({
          lat: lonlat[1],
          lon: lonlat[0],
        });

        if (setLocation) {
          setLocation({ longitude: lonlat[0], latitude: lonlat[1] });
        }
      });
    }

    function renderMap() {
      // console.log('renderMap', mapElement.current);
      if (mapElement.current?.clientWidth === 0) {
        setTimeout(renderMap, 50);
      } else {
        initialMap.updateSize();
      }
    }
    renderMap();
  }, []); // eslint-disable-line

  //   useEffect( () => {
  //     drawFeatures(featuresLayer, features)
  //   },[featuresLayer, features])

  useEffect(() => {
    if (homeLayer && homeMarker) {
      // console.log('duh', homeLayer, homeMarker);
      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'https://cdn.mapmarker.io/api/v1/fa/stack?size=30&icon=fa-star&color=%23FFFFFF&on=fa-circle&oncolor=%23194D33&hoffset=0&voffset=-1&iconsize=15&',
        }),
      });
      const markerFeature = new Feature({
        geometry: new Point(fromLonLat([homeMarker.lon, homeMarker.lat])),
      });
      markerFeature.setStyle(iconStyle);
      homeLayer.setSource(
        new VectorSource({
          features: [markerFeature],
        }),
      );

      // console.log('marker', markerFeature, iconStyle);
      const options = {
        center: fromLonLat([homeMarker.lon, homeMarker.lat]),
        zoom: 12,
      };
      map.getView().animate(options);
    }
  }, [homeLayer, homeMarker]); // eslint-disable-line

  useEffect(() => {
    if (newHomeMarker && featuresLayer) {
      // console.log('duh', homeLayer, homeMarker);
      // const iconStyle = new Style({
      //   image: new Icon({
      //     anchor: [0.5, 0.5],
      //     src: 'https://cdn.mapmarker.io/api/v1/fa/stack?size=30&icon=fa-star&color=%23FFFFFF&on=fa-circle&oncolor=%23194D33&hoffset=1&voffset=0&iconsize=15&',
      //   }),
      // });
      // debugger; // eslint-disable-line
      const verifiedMarker = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          // color: pinColor,
          crossOrigin: 'anonymous',
          // src: 'http://app.vinoseeker.com/assets/verifiedMarker.png',
          src: 'http://app.vinoseeker.com/assets/claimedMarker.png',
          scale: 1,
        }),
        zIndex: 10,
      });
      const markerFeature = new Feature({
        geometry: new Point(fromLonLat([newHomeMarker.lon, newHomeMarker.lat])),
      });
      markerFeature.setStyle(verifiedMarker);
      featuresLayer.setSource(
        new VectorSource({
          features: [markerFeature],
        }),
      );

      // console.log('marker', markerFeature, iconStyle);
      const options = {
        center: fromLonLat([newHomeMarker.lon, newHomeMarker.lat]),
        zoom: 15,
      };
      map.getView().animate(options);
    }
  }, [newHomeMarker, featuresLayer]); // eslint-disable-line

  useEffect(() => {
    if (selectedVenue) {
      const options = {
        center: fromLonLat([selectedVenue.lng, selectedVenue.lat]),
      };

      if (map.getView().getZoom() < 12) {
        options.zoom = 12;
      }

      map.getView().animate(options);
    }
  }, [map, selectedVenue]);

  return (
    <div ref={mapElement} className={styles.mapContainer} />
  );
};

export default ListingMapWrapper;
