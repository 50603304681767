import React from 'react';
import { IonModal } from '@ionic/react';

const Dialog = (props) => {
  const {
    isOpen,
    onDidDismiss,
    children,
  } = props;
  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDidDismiss}
      className="dialogModal"
    >
      <div className="wrapper">
        {children}
      </div>
    </IonModal>
  );
};

export default Dialog;
