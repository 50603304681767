/* eslint no-nested-ternary: 0 */
import React, { useEffect, useRef, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  useIonLoading,
  IonNote,
  IonPage,
  IonPopover,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonReorder,
  IonReorderGroup,
  IonTitle,
  IonToolbar,
  useIonAlert,
  IonAlert,
} from '@ionic/react';
import { add, chevronDownCircleOutline, checkmarkCircle, copy, create, shareOutline, addCircle, personCircleOutline } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import client from '../lib/feathers';
import './listingList.scss';
import MapWrapper from '../components/MapWrapper/MapWrapper';
import buildMarker from '../lib/buildMapMarker';
import { updateListItem } from '../lib/features/profileSlice';
import VinoMarkdown from '../components/VinoMarkdown';

const ListingList = (props) => {
  let shareUrl;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const user = useSelector((state) => state.auth.user);
  const { history, match } = props;
  const ionListRef = useRef();
  const [listId, setListId] = useState();
  const [list, _setList] = useState();
  const [listingListListings, setListingListListings] = useState();
  const [features, setFeatures] = useState([]);
  const [mapCenter, setMapCenter] = useState();
  const [checkFollowing, setCheckFollowing] = useState(false);
  const [noAuthWarning, setNoAuthWarning] = useState(false);
  const [followers, setFollowers] = useState(0);
  const [clones, setClones] = useState(0);
  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonLoading();

  const popover = useRef(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (list) {
    shareUrl = `${process.env.REACT_APP_CLIENT_URL}/lists/${list.id}`;
  }

  const setList = async (l) => {
    _setList(l);
    if (l.source === null) {
      setListingListListings(l.listingListListings);
    } else {
      setListingListListings(l.source.listingListListings);
    }
  };

  const loadList = async () => {
    // load the lists
    try {
      const service = client.service('client/listing-list-share');
      await present('Loading...');
      const result = await service.get(listId);
      setList(result);

      try {
        const following = await client.service('client/listing-list-follow').get(listId);
        setCheckFollowing(following.following);
        setFollowers(following.followers);
        setClones(following.clones);
      } catch (e) {
        setCheckFollowing(0);
        setFollowers(0);
        setClones(0);
      }

      await dismiss();
    } catch (e) {
      await dismiss();
      console.log('err getting list', e);
      if (e.code === 401) {
        presentAlert({
          header: 'Sorry, this is a private list.',
          buttons: [
            {
              text: 'OK',
              role: 'confirm',
              handler: async () => {
                history.push('/');
              },
            },
          ],
        });
      } else {
        alert(e.message);
        history.push('/me/lists');
      }
    }
  };

  const makeListMarkers = () => {
    const listMarkers = [];
    const venues = listingListListings || [];
    venues.forEach((v, i) => {
      const venue = {
        ...v.listing,
        lat: v.listing.location.coordinates[1],
        lng: v.listing.location.coordinates[0],
      };
      listMarkers.push(buildMarker(venue, i + 1, venues.length));
    });

    setMapCenter('fit');
    setFeatures(listMarkers);
  };

  const onListClick = async (listing) => {
    history.push(`/listing/${listing.id}`);
  };

  const onDeleteList = async (listingListListing) => {
    presentAlert({
      onDidDismiss: () => {
        ionListRef.current.closeSlidingItems();
      },
      cssClass: 'my-custom-class',
      header: 'Warning',
      message: `Are you sure you want to remove ${listingListListing.listing.name} from ${list.name}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ionListRef.current.closeSlidingItems();
          },
        },
        {
          text: 'OK',
          handler: async () => {
            const service = client.service('client/listing-list-listing');
            await service.remove(listingListListing.id);

            const listingListCopy = [...listingListListings];
            const newListingList = listingListCopy.filter((listingListItem) => listingListItem.id !== listingListListing.id);
            const updatedList = { ...list, listingListListings: newListingList };
            dispatch(updateListItem(updatedList));

            await loadList();
          },
        },
      ],
    });
  };

  const onListRefresh = async (e) => {
    await loadList();
    e.detail.complete();
  };

  const onEditListClick = async (l) => {
    history.push(`/me/lists/listForm/${l.id}`);
  };

  const onAddListingClick = async () => {
    history.push(`/me/lists/${list.id}/search`);
  };
  const onReorderItems = async (e, listListings) => {
    const listings = [...listListings];
    const draggedItem = listings.splice(e.detail.from, 1)[0];
    listings.splice(e.detail.to, 0, draggedItem);

    const idlist = listings.map((listing) => listing.id);
    const service = client.service('client/listing-list-reorder');
    await service.update(list.id, idlist);

    e.detail.complete(false);

    const newList = {
      ...list,
      listingListListings: listings,
    };

    setList(newList);
  };

  const noOpFunction = () => { };

  const loadingListing = () => {
    if (match && match.params && match.params.id) {
      setListId(match.params.id);
    }
  };

  const onShareProfileClick = (e) => {
    if (navigator.share) {
      // setPopoverOpen(true);
      navigator
        .share({
          title: 'My List',
          text: `Check out ${user.firstName} ${user.lastName}'s ${list.name} List on VinoSeeker.`,
          url: shareUrl,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
    } else {
      if (popover.current) {
        popover.current.event = e;
      }
      setPopoverOpen(true);
    }
  };

  const onClipboardCopy = () => {
    navigator.clipboard.writeText(shareUrl);
    setPopoverOpen(false);
  };

  const onCloneListClick = async () => {
    if (user.id) {
      presentAlert({
        header: 'Make a copy of this list?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: async () => {
              const service = client.service('client/listing-list-clone');
              const data = {
                name: list.name,
                sourceId: listId,
              };
              const result = await service.create(data);
              history.push(`/me/lists/${result.id}`);
            },
          },
        ],
      });
    } else {
      setNoAuthWarning(true);
    }
  };

  const onFollowListClick = async () => {
    if (user.id) {
      presentAlert({
        header: 'Follow this list?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'OK',
            role: 'confirm',
            handler: async () => {
              const service = client.service('client/listing-list-follow');
              if (checkFollowing === true) {
                await service.remove(listId);
                setCheckFollowing(false);
              } else {
                const data = {
                  name: list.name,
                  sourceId: listId,
                };
                try {
                  await service.create(data);
                  setCheckFollowing(true);
                } catch (e) {
                  console.error(e);
                }
              }
            },
          },
        ],
      });
    } else {
      setNoAuthWarning(true);
    }
  };

  useEffect(loadingListing, [match]);

  const listingId = () => {
    if (listId) {
      loadList();
    }
  };
  useEffect(listingId, [listId]);

  const listMarkers = () => {
    if (list) {
      makeListMarkers(list);
    }
  };
  useEffect(listMarkers, [list]);

  const loadHistory = () => {
    if (history.action === 'POP' && listId) {
      loadList();
    }
  };
  useEffect(loadHistory, [history.action]);

  const ListItem = (prps) => {
    const { listing, index } = prps;
    return (
      <IonItem
        detail
        onClick={(e) => {
          e.preventDefault();
          onListClick(listing);
        }}
      >
        {auth.user.id && list.userId === auth.user.id && <IonReorder slot="start" />}
        <IonLabel>
          {`${index + 1}. ${listing.name}`}
        </IonLabel>
      </IonItem>
    );
  };

  const SlidingListItem = (prps) => {
    const { listingsList, index } = prps;

    return (
      <IonItemSliding>
        <ListItem listing={listingsList.listing} index={index} />
        <IonItemOptions side="end">
          <IonItemOption onClick={() => onDeleteList(listingsList)}>Delete</IonItemOption>
        </IonItemOptions>
      </IonItemSliding>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">{auth.user.id ? <IonBackButton defaultHref="/me/lists" /> : <IonBackButton defaultHref="/" />}</IonButtons>
          <IonTitle>
            {list ? list.name : 'Loading List Data'}
            &nbsp;
          </IonTitle>
          <IonButtons slot="end">
            {auth.user.id && list && list.userId === auth.user.id ? (
              <>
                <IonNote>{list ? list.access[0].toUpperCase() + list.access.slice(1) : ''}</IonNote>
                <IonButton
                  onClick={
                    list.access === 'private'
                      ? () => presentAlert({
                        header: 'Alert',
                        message: 'Private lists cannot be shared.',
                        buttons: ['OK'],
                      })
                      : onShareProfileClick
                  }
                >
                  <IonIcon icon={shareOutline} />
                </IonButton>
              </>
            ) : list ? (
              <IonButton
                onClick={
                  list.access === 'private'
                    ? () => presentAlert({
                      header: 'Alert',
                      message: 'Private lists cannot be shared.',
                      buttons: ['OK'],
                    })
                    : onShareProfileClick
                }
              >
                <IonIcon icon={shareOutline} />
              </IonButton>
            ) : (
              ''
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonAlert
          isOpen={noAuthWarning}
          onDidDismiss={() => setNoAuthWarning(false)}
          cssClass="my-custom-class"
          message="Silly Seeker, following lists and cloning lists are for users. Please login and try again."
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
            },
            {
              text: 'Login',
              id: 'confirm-button',
              handler: () => {
                history.push('/login');
              },
            },
          ]}
        />
        <IonRefresher slot="fixed" onIonRefresh={onListRefresh}>
          <IonRefresherContent pullingIcon={chevronDownCircleOutline} pullingText="Pull to refresh" refreshingSpinner="circles" refreshingText="Refreshing..." />
        </IonRefresher>
        {list ? (
          <>
            <IonCard>
              <IonItem lines="full">
                <IonLabel>Description</IonLabel>

                {auth.user.id && list.userId === auth.user.id ? (
                  <IonButton expand="block" onClick={() => onEditListClick(list)}>
                    <IonIcon icon={create} size="small" slot="icon-only" className="hover-effect" />
                  </IonButton>
                ) : (
                  <IonButton onClick={() => onFollowListClick(list)}>
                    {
                      checkFollowing !== true
                        ? <IonIcon icon={addCircle} size="small" slot="icon-only" className="hover-effect" />
                        : <IonIcon icon={checkmarkCircle} size="small" slot="icon-only" className="hover-effect" />
                    }
                  </IonButton>
                )}
                <IonButton
                  expand="block"
                  onClick={() => onCloneListClick(list)}
                >
                  <IonIcon icon={copy} size="small" slot="icon-only" className="hover-effect" />
                </IonButton>

              </IonItem>
              <IonItem lines="none">
                <IonLabel className="ion-text-wrap">
                  <VinoMarkdown>{list.description}</VinoMarkdown>
                </IonLabel>
              </IonItem>
            </IonCard>
            <IonPopover className="custom-popover" ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
              <IonItem>
                <IonInput value={shareUrl} />
                <IonButton slot="end" onClick={onClipboardCopy} className="custom-button ion-button-hover" title="Copy Link">
                  <IonIcon src={copy} />
                </IonButton>
              </IonItem>
            </IonPopover>
            <IonCard className="mapCard">
              <MapWrapper onMapMove={noOpFunction} features={features} onMapMarkerClick={noOpFunction} mapCenter={mapCenter} />
            </IonCard>
            <div className="ion-flex ion-justify-content-center ion-wrap">
              <IonChip onClick={() => history.push(`/u/${list.user.username}`)}>
                <IonIcon icon={personCircleOutline} />
                <IonLabel>
                  {list.user.username}
                </IonLabel>
              </IonChip>
              <IonChip>
                <IonLabel>
                  {followers}
                  &nbsp;Following
                </IonLabel>
              </IonChip>
              <IonChip>
                <IonLabel>
                  {clones}
                  &nbsp;Clones
                </IonLabel>
              </IonChip>
            </div>
            <IonCard>
              <IonItem lines="full">
                <IonLabel>Locations</IonLabel>
                {auth.user.id && list.userId === auth.user.id && match.path === '/me/lists/:id' && (
                  <IonButtons slot="end">
                    <IonButton onClick={() => onAddListingClick()}>
                      <IonIcon src={add} />
                    </IonButton>
                  </IonButtons>
                )}
              </IonItem>
              <IonList id="labels-list" lines="full" ref={ionListRef}>
                <IonReorderGroup onIonItemReorder={(e) => onReorderItems(e, listingListListings)} disabled="false">
                  {!listingListListings || listingListListings.length === 0 ? (
                    <IonItem lines="none">
                      <IonNote>No Listings Found.</IonNote>
                    </IonItem>
                  ) : (
                    <>
                      {listingListListings.map((listingsList, index) => {
                        const { listing } = listingsList;
                        if (auth.user.id && list.userId === auth.user.id) {
                          return <SlidingListItem key={listing.id} listingsList={listingsList} index={index} />;
                        }
                        return <ListItem key={listing.id} listing={listing} index={index} />;
                      })}
                    </>
                  )}
                </IonReorderGroup>
              </IonList>
            </IonCard>
          </>
        ) : (
          <>
            <IonProgressBar type="indeterminate" />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ListingList;
