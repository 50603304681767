import React, {
  useEffect,
} from 'react';
import {
  useIonLoading, IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonProgressBar, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, useIonRouter, IonMenuButton,
} from '@ionic/react';

import { useHistory } from 'react-router';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { useSelector, useDispatch } from 'react-redux';
import client from '../lib/feathers';
import CheckinList from '../components/CheckinList';
import { setSelectedCheckin } from '../lib/features/listingsSlice';
import { makeCheckinGroups } from '../lib/api-utils';
import { setCheckins } from '../lib/features/profileSlice';

const ManageCheckins = () => {
  const router = useIonRouter();
  const history = useHistory();

  const [present, dismiss] = useIonLoading();

  const auth = useSelector((state) => state.auth);
  const checkins = useSelector((state) => state.profile.checkins);

  const dispatch = useDispatch();

  const loadCheckins = async () => {
    // load the entire list of checkins
    await present('Loading...');
    const service = client.service('client/checkin');

    const query = {
      $limit: 1000000,
      $sort: {
        createdAt: -1,
      },
    };

    try {
      const result = await service.find({
        query,
      });
      dispatch(setCheckins(result.data));
    } catch (e) {
      alert(e.message);
      history.push('/me');
    }

    await dismiss();
  };

  const onCheckinClick = async (checkin) => {
    dispatch(setSelectedCheckin(checkin));
    history.push(`/listing/checkin/${checkin.id}`);
  };

  const onListRefresh = async (e) => {
    await loadCheckins();
    e.detail.complete();
  };

  const loadUserCheckins = () => {
    if (auth.authCheckComplete) {
      if (auth.authenticated) {
        loadCheckins();
      } else {
        history.push('/me');
      }
    }
  };
  useEffect(loadUserCheckins, [auth]);

  return (

    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {router.canGoBack()
              ? <IonBackButton defaultHref="/" />
              : <IonMenuButton />}
          </IonButtons>
          <IonTitle>Review My Check-Ins</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={onListRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        {checkins
          ? (
            <CheckinList checkins={checkins} onCheckinClick={onCheckinClick} />
          ) : (
            <IonProgressBar type="indeterminate" />
          )}
      </IonContent>
    </IonPage>
  );
};

// export default ManageCheckins;
export { ManageCheckins as default, makeCheckinGroups };
