import React, { useState, useRef } from 'react';
import {
  IonContent,
  IonModal,
  IonList,
  IonLabel,
  IonSearchbar,
  IonItem,
  IonAvatar,
  IonIcon,
  IonSpinner,
  IonRow,
  IonNote,
} from '@ionic/react';
import { wineOutline } from 'ionicons/icons';
import client from '../lib/feathers';

function ListingCheckinSipSearchModal(props) {
  const { isOpen, listingId, onDismiss, handleCreateSip, onAddNewWineClick } = props;

  const modal = useRef();
  const searchInput = useRef();

  const [keywords, setKeywords] = useState('');
  const [wineResults, setWineResults] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const loadListingWinesByName = async (e) => {
    e.preventDefault();

    const keyboard = searchInput.current.getElementsByClassName('searchbar-input');
    keyboard[0].blur();

    const service = await client.service('client/libation-search');

    const query = {
      keywords,
    };

    if (listingId) {
      query.listingId = listingId;
    }

    setIsLoading(true);
    try {
      const response = await service.find({ query });
      setWineResults(response);
    } catch (err) {
      alert(err.message);
    }
    setIsLoading(false);
  };

  const dismissModal = () => {
    if (onDismiss) {
      onDismiss();
      setWineResults();
    }
  };

  const handleNewWineForm = () => {
    dismissModal();
    onAddNewWineClick();
  };

  return (
    <IonModal
      ref={modal}
      isOpen={isOpen}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      onDidDismiss={dismissModal}
      style={{ '--width': '100%', '--height': '75%' }}
      className="sip-search"
    >
      <IonContent>
        <form action="#" method="POST" onSubmit={loadListingWinesByName}>
          <IonSearchbar
            ref={searchInput}
            // onClick={() => modal.current.setCurrentBreakpoint(1)}
            onIonInput={(e) => setKeywords(e.target.value)}
            inputMode="search"
          />
        </form>
        {isLoading
          && (
            <IonRow className="ion-justify-content-center">
              <IonSpinner />
            </IonRow>
          )}
        {wineResults && !isLoading
          && (
            <IonList id="labels-list" lines="full">
              {wineResults.length === 0
                ? (
                  <>
                    <IonItem lines="none">
                      <IonLabel> No wines found. Please refine your search.</IonLabel>
                    </IonItem>
                  </>
                )
                : (
                  <>
                    {wineResults.map((wine) => (
                      <IonItem button detail="false" key={wine.id} onClick={() => handleCreateSip(wine)}>
                        <IonAvatar slot="start">
                          <IonIcon icon={wineOutline} size="large" color="primary" />
                        </IonAvatar>
                        <IonLabel>
                          {`${wine.name}`}
                          <p>{`${wine.vintage} ${wine.varietal?.name || ''}`}</p>
                          <p>{wine.listing?.name || wine.listingName}</p>
                        </IonLabel>
                      </IonItem>
                    ))}
                  </>
                )}

              <IonItem button detail lines="none" onClick={handleNewWineForm}>
                <IonAvatar slot="start">
                  <IonIcon icon={wineOutline} size="large" color="primary" />
                </IonAvatar>
                <IonLabel className="ion-text-wrap">
                  <h2>Didn&apos;t find your wine?</h2>
                  <p>
                    Tap here to add a new wine.
                  </p>
                </IonLabel>
              </IonItem>
            </IonList>
          )}
        {!wineResults && !isLoading
          && (
            <div className="ion-padding ion-text-center">
              <IonNote className="ion-text-wrap">
                Enter any keywords to search
                {listingId ? " through this venue's wines." : ' wines.'}
              </IonNote>
            </div>
          )}
      </IonContent>
    </IonModal>
  );
}

export default ListingCheckinSipSearchModal;
