import React from 'react';
import {
  IonModal,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonItem,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import { arrowBackSharp } from 'ionicons/icons';
import VinoMarkdown from './VinoMarkdown';

const ListingPromotionsModal = (props) => {
  const { isOpen, promotions, onDismiss } = props;

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onDismiss}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismiss} slot="start">
              <IonIcon icon={arrowBackSharp} className="ion-size-md-sm" color="primary" />
            </IonButton>
          </IonButtons>
          <IonTitle class="ion-text-center">
            Promotions
          </IonTitle>
          {/* Invisible Button for formatting the Toolbar Header */}
          <IonButtons slot="end">
            <IonButton disabled>
              {' '}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonCard>
          <IonCardHeader mode="ios">
            <IonCardTitle className="ion-flex ion-justify-content-between ion-align-items-center">
              {promotions?.currentPromo?.title}
            </IonCardTitle>
          </IonCardHeader>
          <IonItem lines="none" className="ion-margin-bottom">
            <VinoMarkdown className="line-break">
              {promotions?.currentPromo?.description}
            </VinoMarkdown>
          </IonItem>
        </IonCard>
      </IonContent>
    </IonModal>
  );
};

export default ListingPromotionsModal;
