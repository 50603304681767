import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  authenticated: false,
  authCheckComplete: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.authenticated = action.payload !== undefined;
    },

    setAuthCheckComplete: (state, action) => {
      state.authCheckComplete = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setAuthCheckComplete } = authSlice.actions;

export default authSlice.reducer;
