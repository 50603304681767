import {
  IonButtons,
  IonButton,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
  IonModal,
  IonItem,
  IonTextarea,
  IonRadioGroup,
  IonRadio,
  IonNote,
  IonItemDivider,
} from '@ionic/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setCheckinAttachments,
} from '../lib/features/listingsSlice';
import client from '../lib/feathers';

const EditPhotoCaptionAndPrivacyForm = (props) => {
  const { photo, closeModal, attachments } = props;
  const dispatch = useDispatch();
  const [newCaption, setNewCaption] = useState(photo.caption);
  const [newPrivacy, setNewPrivacy] = useState(photo.privacy);

  const editPhotoSubmissionHandler = async (event) => {
    event.preventDefault();
    const attachmentService = client.service('client/attachment');
    await attachmentService.patch(photo.id, { caption: newCaption, privacy: newPrivacy });
    const newAttachmentArray = attachments.map((a) => {
      if (a.id === photo.id) {
        a = { ...a, caption: newCaption, privacy: newPrivacy };
      }
      return a;
    });
    dispatch(setCheckinAttachments(newAttachmentArray));
    closeModal(false);
  };

  return (
    <IonModal isOpen="true">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit Photo</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => closeModal(false)}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <form onSubmit={editPhotoSubmissionHandler}>
          <IonRadioGroup value={newPrivacy} onIonChange={(e) => setNewPrivacy(e.detail.value)}>
            <IonItemDivider>Who can see this photo?</IonItemDivider>
            <IonItem lines="full">
              <IonRadio labelPlacement="end" justify="start" value="public">
                Public&nbsp;
                <IonNote>Visible to everyone</IonNote>
              </IonRadio>
            </IonItem>
            <IonItem lines="full">
              <IonRadio labelPlacement="end" justify="start" value="protected" slot="start">
                Protected&nbsp;
                <IonNote>Visible to friends</IonNote>
              </IonRadio>
            </IonItem>
            <IonItem lines="full">
              <IonRadio labelPlacement="end" justify="start" value="private" slot="start">
                Private&nbsp;
                <IonNote>Visible to only you</IonNote>
              </IonRadio>
            </IonItem>
          </IonRadioGroup>
          <IonItemDivider>Edit Caption</IonItemDivider>
          <IonItem>
            <IonTextarea
              placeholder="Tell us more about this photo"
              aria-label="Caption"
              position="floating"
              value={newCaption}
              onIonChange={(e) => setNewCaption(e.detail.value)}
              autocapitalize="sentences"
            />
          </IonItem>
          <IonButton expand="block" type="submit">Submit</IonButton>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default EditPhotoCaptionAndPrivacyForm;
