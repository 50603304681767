import React, {
  useEffect,
  useState,
} from 'react';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from '@ionic/react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setListingFlights, setListingLibations } from '../lib/features/listingsSlice';
import client from '../lib/feathers';
import ListingWineList from './ListingWineList';
import ListingFlightList from './ListingFlightList';

// ! CURRENTLY ONLY DISPLAYED IN VERIFIED LISTINGS
const ListingLibations = () => {
  const { id: listingId } = useParams();

  const dispatch = useDispatch();
  const flights = useSelector((state) => state.listings.listingFlights);
  const libations = useSelector((state) => state.listings.listingLibations);
  const listing = useSelector((state) => state.listings.selectedListing);

  const [cardContent, setCardContent] = useState('flights');

  useEffect(() => {
    const loadListingLibations = async () => {
      const service = client.service('client/libation');
      const query = {
        listingId,
        status: {
          $ne: 'inactive', // find all that are NOT 'inactive'
        },
        $limit: 100,
      };
      const response = await service.find({ query });
      dispatch(setListingLibations(response.data));
    };

    const loadListingFlights = async () => {
      const service = client.service('client/flight');
      const query = {
        listingId,
        status: 'active',
      };
      const response = await service.find({ query });
      dispatch(setListingFlights(response.data));
    };

    loadListingLibations();
    loadListingFlights();
  }, [listingId, listing]);

  return (
    <>
      {!(flights.length === 0 && libations.length === 0)
        && (
          <IonCard>
            <IonCardHeader className="ion-no-padding">
              <IonSegment
                value={cardContent}
                scrollable
                onIonChange={(e) => setCardContent(e.detail.value)}
                style={{ display: 'flex', borderRadius: 0, justifyContent: 'center' }}
              >
                <IonSegmentButton value="flights">
                  <IonLabel>
                    <h2><b>Flights</b></h2>
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="libations">
                  <IonLabel>
                    <h2><b>Wine List</b></h2>
                  </IonLabel>
                </IonSegmentButton>
              </IonSegment>
            </IonCardHeader>
            <IonCardContent className="ion-no-padding">
              {cardContent === 'libations'
                && <ListingWineList wines={libations} />}
              {cardContent === 'flights'
                && <ListingFlightList flights={flights} />}
            </IonCardContent>
          </IonCard>
        )}
    </>
  );
};

export default ListingLibations;
