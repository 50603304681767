import React, { useRef } from 'react';
import {
  IonChip,
  IonIcon,
  IonItem,
  IonItemSliding,
  IonLabel,
  IonList,
  IonNote,
  IonItemOption,
  IonItemOptions,
  useIonAlert,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { personCircleOutline } from 'ionicons/icons';
import client from '../lib/feathers';
import { removeListById } from '../lib/features/profileSlice';

const UserLists = (props) => {
  const {
    lists,
    onListsClick,
    onPublicListsClick,
    allowDelete = true,
    atListing = false, // passed if user is looking at specific listing
  } = props;

  const history = useHistory();
  const [present] = useIonAlert();
  const ionListRef = useRef();

  const dispatch = useDispatch();

  const listsClick = (list) => {
    if (onListsClick) {
      onListsClick(list);
    } else {
      onPublicListsClick(list);
    }
  };

  const onDeleteList = async (list) => {
    present({
      onDidDismiss: () => { ionListRef.current.closeSlidingItems(); },
      cssClass: 'my-custom-class',
      header: 'Warning',
      message: `Are you sure you want to delete ${list.name}?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => { ionListRef.current.closeSlidingItems(); },
        },
        {
          text: 'OK',
          handler: async () => {
            const service = client.service('client/listing-list');
            await service.remove(list.id);

            dispatch(removeListById(list.id));
          },
        },
      ],
    });
  };

  const onEditList = async (list) => {
    ionListRef.current.closeSlidingItems();
    history.push(`/me/lists/listForm/${list.id}`);
  };

  const sliderItem = (list) => (
    <IonItemOptions side="end">
      <IonItemOption onClick={() => onDeleteList(list)}>{list.source !== null ? 'Unfollow' : 'Delete'}</IonItemOption>
      {list.source === null && (
        <IonItemOption color="secondary" onClick={() => onEditList(list)}>Edit</IonItemOption>)}
    </IonItemOptions>
  );

  return (
    <IonList id="labels-list" ref={ionListRef} lines="full">
      {lists.length === 0 && !atListing
        ? (
          // No lists have been made and you are not looking from a specific listing
          <IonItem lines="none">
            <IonNote> No lists found.</IonNote>
          </IonItem>
        )
        : (
          <>
            {lists.map((list) => (
              <IonItemSliding key={list.id}>
                <IonItem button onClick={(e) => { e.preventDefault(); listsClick(list); }}>
                  <IonGrid className="ion-no-padding-left">
                    <IonRow>
                      <IonCol className="ion-flex ion-align-items-center ion-no-padding-left">
                        <IonLabel>{(list.source !== null && list.source) ? list.source.name : list.name}</IonLabel>
                      </IonCol>
                      {list.source && (
                        <IonCol>
                          <IonChip className="ion-float-end">
                            <IonIcon icon={personCircleOutline} />
                            <IonLabel>
                              {list.source ? list.source.user.username : 'User'}
                            </IonLabel>
                          </IonChip>
                        </IonCol>
                      )}
                      {(allowDelete)
                        ? (
                          <IonCol size="auto">
                            <IonRow>
                              <IonChip className="ion-float-end">
                                {list.source !== null && list.source !== undefined && (
                                  <>
                                    g
                                    {list.source.listingListListings ? `${list.source.listingListListings.length}` : '0'}
                                  </>
                                )}
                                {list.source === null && (
                                  <>
                                    d
                                    {list.listingListListings ? `${list.listingListListings.length}` : '0'}
                                  </>
                                )}
                              </IonChip>
                            </IonRow>
                          </IonCol>
                        )
                        : (
                          <IonCol size="auto">
                            <IonChip className="ion-float-end">
                              {list.source !== null && (
                                <>
                                  {list.source.listingListListings ? `${list.source.listingListListings.length}` : '0'}
                                </>
                              )}
                              {list.source === null && (
                                <>
                                  {list.listingListListings ? `${list.listingListListings.length}` : '0'}
                                </>
                              )}
                            </IonChip>
                          </IonCol>
                        )}
                    </IonRow>
                  </IonGrid>
                </IonItem>
                {allowDelete ? sliderItem(list) : ''}
              </IonItemSliding>
            ))}
          </>
        )}
    </IonList>
  );
};

export default UserLists;
