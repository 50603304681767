import {
  IonItem,
  IonItemGroup,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonNote,
  useIonAlert,
  useIonLoading,
} from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import React, {
  useRef,
} from 'react';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';
import { setRecentCheckins, setSelectedCheckin } from '../lib/features/listingsSlice';
import { removeCheckinById } from '../lib/features/profileSlice';
import client from '../lib/feathers';
import VinoMarkdown from './VinoMarkdown';

const ListingCheckins = ({ checkins }) => {
  const history = useHistory();
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [present] = useIonAlert();

  const ionListRef = useRef();
  const [presentLoading, dismiss] = useIonLoading();

  const onCheckinClick = (selectedCheckin) => {
    dispatch(setSelectedCheckin(selectedCheckin));
    history.push(`/listing/checkin/${selectedCheckin.id}`);
  };

  const onDeleteCheckin = async (checkin) => {
    present({
      onDidDismiss: () => {
        ionListRef.current?.closeSlidingItems(); // eslint-disable-line no-unused-expressions
      },
      cssClass: 'my-custom-class',
      header: 'Warning',
      message: `Are you sure you want to delete your check-in at ${
        checkin.listing.name
      } on ${moment(checkin.createdAt).tz(auth.user.locale).format('l')}? This will also delete all photos attached to this check-in.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ionListRef.current?.closeSlidingItems(); // eslint-disable-line no-unused-expressions
          },
        },
        {
          text: 'OK',
          handler: async () => {
            await presentLoading('Loading...');
            const service = client.service('client/checkin');
            await service.remove(checkin.id);
            dispatch(removeCheckinById(checkin.id));
            dispatch(setRecentCheckins([...checkins].filter((item) => item.id !== checkin.id)));
            // if removing your most recent checkin, set the selected checkin to undefined.
            if (checkin.id === ([...checkins].reverse())[0].id) {
              dispatch(setSelectedCheckin(undefined));
            }
            await dismiss();
          },
        },
      ],
    });
  };

  return (
    <>
      {checkins && checkins.length > 0 && (
        <>
          <IonList lines="full" ref={ionListRef}>
            {[...checkins].reverse().map((checkin) => (
              <IonItemGroup key={checkin.id}>
                <IonItemSliding disabled={checkin.showNote}>
                  <IonItem button onClick={(e) => { e.preventDefault(); onCheckinClick(checkin); }}>
                    <IonLabel>
                      {moment(checkin.createdAt).tz(auth.user.locale).calendar()}
                    </IonLabel>
                    <IonNote slot="end" className="ion-text-capitalize">{moment(checkin.createdAt).fromNow()}</IonNote>
                  </IonItem>
                  <IonItem className={checkin.showNote ? 'checkin-note show' : 'checkin-note'}>
                    <IonLabel className="ion-text-wrap">
                      <VinoMarkdown>{checkin.notes || 'No Notes Add Yet'}</VinoMarkdown>
                    </IonLabel>
                  </IonItem>
                  <IonItemOptions side="end">
                    <IonItemOption onClick={() => onDeleteCheckin(checkin)}>Delete</IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              </IonItemGroup>
            ))}
          </IonList>
        </>
      )}
    </>
  );
};

export default ListingCheckins;
