import React, { useState } from 'react';
import {
  IonContent,
  IonLoading,
  IonAvatar,
  IonPage,
  IonHeader,
  IonToolbar,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonBackButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonItem,
  IonNote,
  IonList,
} from '@ionic/react';
import { chevronDownCircleOutline, qrCode } from 'ionicons/icons';
import { useHistory } from 'react-router';

import QRModal from '../components/QRModal';
import useFriendInvites from '../hooks/useFriendInvites';
import useFriends from '../hooks/useFriends';
import FriendInviteActionButtons from '../components/friends/FriendInviteActionButtons';
import FriendActionSheet from '../components/friends/FriendActionSheet';

const FriendInfo = ({ contact }) => {
  const history = useHistory();
  return (
    <>
      <IonAvatar slot="start" className="ion-flex ion-align-items-center" style={{ marginRight: '8px' }}>
        <div
          className="friend-avatar"
          title={contact.username}
        >
          {contact.firstName.charAt(0) + contact.lastName.charAt(0)}
        </div>
      </IonAvatar>
      <IonLabel onClick={() => history.push(`/u/${contact.username}`)} style={{ cursor: 'pointer' }}>
        <h2>
          {contact.firstName}
          {' '}
          {contact.lastName}
        </h2>
        <p>
          {`@${contact.username}`}
        </p>
      </IonLabel>
    </>
  );
};

const ManageFriends = () => {
  const [qrModalOpen, setQRModalOpen] = useState(false);
  const [inviteFilter, setInviteFilter] = useState('received');
  const [friendFilter, setFriendFilter] = useState('default');

  const {
    isLoading: loadingInvites,
    refetch: refetchInvites,
    invites: { sent, received },
    updateInvite,
  } = useFriendInvites();

  const {
    isLoading: loadingFriends,
    refetch: refetchFriends,
    data: { friends, muted, blocked },
    updateFriend,
    unfollowFriend,
  } = useFriends();

  let displayedFriends = friends;
  if (friendFilter === 'muted') {
    displayedFriends = muted;
  } else if (friendFilter === 'blocked') {
    displayedFriends = blocked;
  }

  const displayedInvites = inviteFilter === 'sent' ? sent : received;

  const handleUpdateInvite = async (request, status) => {
    updateInvite(request.id, status);
    if (status === 'accepted') await refetchFriends();
  };

  const handleManualRefresh = async (e) => {
    await refetchInvites();
    await refetchFriends();
    e.detail.complete();
  };

  const handleInviteSegmentSelect = (e) => {
    e.preventDefault();
    setInviteFilter(e.detail.value);
  };

  const handleFriendSegmentSelect = (e) => {
    e.preventDefault();
    setFriendFilter(e.detail.value);
  };

  return (
    <IonPage>
      <IonLoading
        isOpen={loadingInvites || loadingFriends}
        message={`Loading ${loadingInvites ? 'invites' : 'friends'}...`}
      />
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton defaultHref="/me" />
          </IonButtons>
          <IonTitle className="ion-text-center">
            Friends
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setQRModalOpen(true)}>
              <IonIcon icon={qrCode} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={handleManualRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Invitations
            </IonCardTitle>
          </IonCardHeader>
          <IonSegment
            value={inviteFilter}
            scrollable
            onIonChange={handleInviteSegmentSelect}
            style={{ display: 'flex', borderRadius: 0, justifyContent: 'center' }}
          >
            <IonSegmentButton value="received" style={{ position: 'relative' }}>
              <IonLabel>Received</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="sent">
              <IonLabel>Sent</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonList>
            {displayedInvites?.length > 0
              ? (displayedInvites.map((request) => (
                <IonItem key={request.id} lines="full" mode="ios">
                  <FriendInfo
                    contact={inviteFilter === 'sent'
                      ? request.recipient
                      : request.user}
                  />
                  <FriendInviteActionButtons
                    invite={request}
                    isSent={inviteFilter === 'sent'}
                    onUpdate={(status) => handleUpdateInvite(request, status)}
                    isDisabled={loadingInvites}
                  />
                </IonItem>
              ))
              ) : (
                <IonItem lines="none">
                  <IonNote>
                    {`No ${inviteFilter === 'sent' ? 'pending' : 'incoming'} friend requests.`}
                  </IonNote>
                </IonItem>
              )}
          </IonList>
        </IonCard>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle>
              Friends
            </IonCardTitle>
          </IonCardHeader>
          <IonSegment
            value={friendFilter}
            scrollable
            onIonChange={handleFriendSegmentSelect}
            style={{ display: 'flex', borderRadius: 0, justifyContent: 'center' }}
          >
            <IonSegmentButton value="default" style={{ position: 'relative' }}>
              <IonLabel>Friends</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="muted">
              <IonLabel>Muted</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="blocked">
              <IonLabel>Blocked</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonList>
            {displayedFriends?.length > 0
              ? (displayedFriends.map((friend) => (
                <IonItem key={friend.id} lines="full" mode="ios">
                  <FriendInfo contact={friend.friend} />
                  <IonButtons>
                    <FriendActionSheet
                      friend={friend}
                      onUpdate={(status) => updateFriend(friend.id, status)}
                      onUnfollow={() => unfollowFriend(friend.id)}
                      isDisabled={loadingFriends}
                    />
                  </IonButtons>
                </IonItem>
              ))
              ) : (
                <IonItem lines="none">
                  <IonNote>
                    {friendFilter === 'default'
                      ? 'Add friends to share your wine tasting experiences with!'
                      : `No ${friendFilter === 'muted' ? 'muted friends.' : 'blocked users.'}`}
                  </IonNote>
                </IonItem>
              )}
          </IonList>
        </IonCard>
      </IonContent>
      {qrModalOpen && <QRModal qrOpen={qrModalOpen} setModalOpen={setQRModalOpen} />}
    </IonPage>
  );
};

export default ManageFriends;
