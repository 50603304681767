import React from 'react';
import {
  IonButton,
  IonIcon,
  useIonActionSheet,
  useIonAlert,
} from '@ionic/react';
import { ellipsisHorizontal } from 'ionicons/icons';
import { patchFriend, removeFriend } from '../../lib/api/friend-services';

const FriendActionSheet = (props) => {
  const {
    friend,
    isDisabled,
    onUpdate: updateFriendState,
    onUnfollow: unfollowFriend,
  } = props;

  const [presentSheet] = useIonActionSheet();
  const [presentAlert] = useIonAlert();

  const friendInfo = friend.friend;

  const isMuted = friend.status === 'muted';
  const isBlocked = friend.status === 'blocked';

  const handleToggleMute = async () => {
    const mutedStatus = isMuted ? 'default' : 'muted';
    await patchFriend(friend.id, mutedStatus);
    updateFriendState(mutedStatus);
  };

  const handleToggleBlock = async () => {
    const blockedStatus = isBlocked ? 'default' : 'blocked';
    await patchFriend(friend.id, blockedStatus);
    updateFriendState(blockedStatus);
  };

  const handleUnfollow = async () => {
    await removeFriend(friend.id);
    unfollowFriend();
  };

  const confirmAction = (handler, action) => {
    presentAlert({
      header: `${action} ${friendInfo.firstName}`,
      message: `Are you sure you want to ${action} @${friendInfo.username}? They will not be notified.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: action,
          role: 'confirm',
          handler,
        },
      ],
    });
  };

  const getFriendOptions = () => {
    const muteAction = isMuted ? 'Unmute' : 'Mute';
    const blockAction = isBlocked ? 'Unblock' : 'Block';

    let options = [
      {
        text: 'Unfollow',
        role: 'destructive',
        handler: () => confirmAction(handleUnfollow, 'Unfollow'),
      },
      {
        text: muteAction,
        role: 'mute',
        handler: () => confirmAction(handleToggleMute, muteAction),
      },
      {
        text: blockAction,
        role: 'block',
        handler: () => confirmAction(handleToggleBlock, blockAction),
      },
    ];

    if (isBlocked) {
      options = [...options].filter((button) => button.role === 'block');
    }

    return options;
  };

  const handleActions = () => {
    presentSheet({
      header: `@${friendInfo.username}`,
      buttons: [
        ...getFriendOptions(),
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
    });
  };

  return (
    <IonButton
      className="action-sheet-button"
      onClick={handleActions}
      disabled={isDisabled}
    >
      <IonIcon src={ellipsisHorizontal} />
    </IonButton>
  );
};

export default FriendActionSheet;
