import React, { useEffect, useRef, useState } from 'react';
import { IonBackButton, IonButton, IonNote, IonLabel, IonButtons, IonContent, IonHeader, IonItem, IonList, IonPage, IonSearchbar, IonTitle, IonToolbar, IonAvatar, IonIcon, useIonAlert } from '@ionic/react';
import { pinOutline } from 'ionicons/icons';
import client from '../lib/feathers';

const ListingListListingSearch = (props) => {
  const { match, selectLocation } = props;
  // If selectLocation is passed as a prop,
  // then we're on the WineForm / SipForm:
  const isOnWineForm = !!selectLocation;
  const searchInputRef = useRef();
  const [present] = useIonAlert();
  const [keywords, setKeywords] = useState();
  const [listings, setListings] = useState();
  const [listId, setListId] = useState();

  const loadListingsByName = async (e) => {
    e.preventDefault();

    const service = client.service('client/listing');

    if (keywords && keywords.length) {
      const query = {
        $limit: 25,
        $sort: {
          name: 1,
        },
        name: { $iLike: `%${keywords}%` },
        // categoryId: '8e67dd09-ad9e-4855-86e6-71f02cd05bb5',
      };
      const result = await service.find({
        query,
      });

      setListings(result.data);
    } else {
      setListings();
    }
  };
  const onListingClick = async (listing) => {
    try {
      const service = client.service('client/listing-list-listing');
      const data = {
        listingId: listing.id,
        listingListId: listId,
        order: 100,
      };

      await service.create(data);

      present({
        header: 'Added To List',
        message: `Ok, ${listing.name} has been added to list`,
        buttons: [
          {
            text: 'OK',
            handler: async () => {
              // console.log('listingListListing', vlv)
              // history.goBack();
            },
          },
        ],
      });
    } catch (e) {
      alert(e.message);
    }
  };

  const handleSelectListing = (listing) => {
    if (isOnWineForm) {
      selectLocation(listing, 'confirm');
    } else {
      onListingClick(listing);
    }
  };

  const setListIdFromMatch = () => {
    if (!listId && match && match.params && match.params.id) {
      setListId(match.params.id);
    }
  };
  useEffect(setListIdFromMatch, [match]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {!isOnWineForm ? (
              <IonBackButton defaultHref="/" />
            ) : (
              <IonButton fill="clear" onClick={() => selectLocation(null, 'cancel')}>
                Back
              </IonButton>
            )}
          </IonButtons>
          <IonTitle>Listing Search</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <form action="#" method="POST" onSubmit={loadListingsByName}>
          <IonToolbar color="primary" className="more-padding">
            <IonSearchbar ref={searchInputRef} placeholder="Search Listings" value={keywords} onIonChange={(e) => setKeywords(e.detail.value)} enterkeyhint="search" inputmode="text" />
          </IonToolbar>
        </form>
        {listings && (
          <IonList id="labels-list" lines="full">
            {listings.length === 0 && !isOnWineForm ? (
              <IonItem lines="none">
                <IonLabel lines="none" className="ion-test-wrap">
                  <h2>No wineries found, please refine your search.</h2>
                  <p>
                    If you can&apos;t find what you are looking for send us an email at&nbsp;
                    <a href="mailto:service@vinoseeker.com?subject=Missing Winery Listing">service@vinoseeker.com</a>
                    &nbsp;and we will get it added to our system.
                  </p>
                </IonLabel>
              </IonItem>
            ) : (
              <>
                {listings.map((listing) => (
                  <IonItem
                    button
                    detail="false"
                    key={listing.id}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSelectListing(listing);
                    }}
                  >
                    <IonAvatar slot="start">
                      <IonIcon icon={pinOutline} size="large" color="primary" />
                    </IonAvatar>
                    <IonLabel>
                      {listing.name}
                      <br />
                      {listing.status === 'unverified' ? (
                        <IonNote>
                          {listing.meta.location.city}
                          {listing.meta.location.city && listing.meta.location.state ? ', ' : ''}
                          {listing.meta.location.state}
                        </IonNote>
                      ) : (
                        <IonNote>
                          {listing.address.city}
                          {listing.address.city && listing.address.state ? ', ' : ''}
                          {listing.address.state}
                        </IonNote>
                      )}
                    </IonLabel>
                  </IonItem>
                ))}
              </>
            )}
            {isOnWineForm && (
              <IonItem button detail lines="none" onClick={() => selectLocation(null, 'custom')}>
                <IonAvatar slot="start">
                  <IonIcon icon={pinOutline} size="large" color="primary" />
                </IonAvatar>
                Can&apos;t find your location? Tap here to add a new location!
              </IonItem>
            )}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ListingListListingSearch;
