import React, { useState } from 'react';
import {
  IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonLabel, IonGrid, IonRow, IonCol,
} from '@ionic/react';
import './ListingDetail.scss';
import { Link } from 'react-router-dom';
// import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
// import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
import ListingButtons from './ListingButtons';
import ListingUserContent from './ListingUserContent';
import NearbyListings from './NearbyListings';
import RequestAccess from './RequestAccess';
import ListingMapWrapper from './ListingMapWrapper/ListingMapWrapper';
import ListingHeader from './ListingHeader';
// import ListingLibations from './ListingLibations';

// import { AppContext } from '../lib/State';

const ListingDetailUnverified = (props) => {
  const { venue, listing } = props;

  // const history = useHistory();
  // const { state, dispatch } = useContext(AppContext);
  // const [venue, setVenue] = useState();
  // const [loading, setLoading] = useState(false);
  const [showHours, setShowHours] = useState(false);
  const [statusLabel] = useState('Un-Verified');

  const homeMarker = {
    lat: listing.location.coordinates[1],
    lon: listing.location.coordinates[0],
  };

  return (

    <>
      {/* {venue.bestPhoto
        && (
          <div style={{
            backgroundImage: `url(${venue.bestPhoto.prefix}300x300${venue.bestPhoto.suffix})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '8vh',
            width: '100%',
            position: 'relative',
          }}
          >
            <h2 className="statusLabel">{statusLabel}</h2>
          </div>
        )} */}

      <IonCard>
        {/*
        <IonCardHeader>
          <IonCardSubtitle>
            {venue.location.city}
            {venue.location.city && venue.location.state ? ', ' : ''}
            {venue.location.state}
          </IonCardSubtitle>
          <IonCardTitle>{venue.name}</IonCardTitle>
        </IonCardHeader> */}
        <div>
          <ListingHeader listingData={listing} height="10vh" />
        </div>
        {/* <IonCardContent>
                        Keep close to Nature's heart... and break clear away, once in awhile,
                        and climb a mountain or spend a week in the woods. Wash your spirit clean.
                    </IonCardContent> */}

        <ListingButtons
          listingId={listing.id}
          phone={venue.contact ? venue.contact.phone : ''}
          website={venue.url}
          location={venue.location}
        />
      </IonCard>

      <ListingUserContent />

      {/* // Wine List and Flights are currently not displayed for Unverified listings. */}
      {/* <ListingLibations /> */}

      {venue.popular
        && (
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                Hours
                {showHours
                  ? <IonButton fill="outline" size="small" className="ion-float-right" onClick={(e) => { e.preventDefault(); setShowHours(false); }}>Less</IonButton>
                  : <IonButton fill="outline" size="small" className="ion-float-right" onClick={(e) => { e.preventDefault(); setShowHours(true); }}>More</IonButton>}
              </IonCardSubtitle>
              <IonCardTitle>
                {venue.popular.timeframes[0].days}
                <br />
                {venue.popular.timeframes[0].open[0].renderedTime}
              </IonCardTitle>
            </IonCardHeader>

            {venue.popular && showHours && (
              <IonCardContent>
                <IonGrid>

                  {venue.popular.timeframes.map((tf, index) => {
                    if (index !== 0) {
                      return (
                        <IonRow class="nowrap">
                          <IonCol class="ion-align-self-start">
                            {tf.days}
                          </IonCol>
                          <IonCol size="8" class="ion-align-self-end">
                            <p>{tf.open[0].renderedTime}</p>
                          </IonCol>
                        </IonRow>
                      );
                    }

                    return '';
                  })}

                </IonGrid>
              </IonCardContent>
            )}
          </IonCard>
        )}

      <NearbyListings
        location={{ latitude: venue.location.lat, longitude: venue.location.lng }}
        listingId={listing.id}
      />

      <IonCard>
        <IonItem>
          <IonLabel className="ion-text-capitalize">
            Location
          </IonLabel>
        </IonItem>
        <IonCardContent>
          <div className="listing-map-container">
            <ListingMapWrapper homeMarker={homeMarker} className="listingMap" />
          </div>
        </IonCardContent>
      </IonCard>

      <IonCard>
        <IonItem>
          <IonLabel>
            {statusLabel}
            {' '}
            Listing
          </IonLabel>
          {/* <IonButton fill="outline" slot="end">View</IonButton> */}
        </IonItem>

        <IonCardContent>
          We are working on building out our library of community and business verified listings. Do you know something about this business? Make a suggestion and help us improve this listing.
          <br />
          <br />
          <Link to={`/listing/suggestion/${listing.id}`}>
            Suggest An Edit
          </Link>
        </IonCardContent>
      </IonCard>

      <RequestAccess listing={listing} />

    </>

  );
};

export default ListingDetailUnverified;
