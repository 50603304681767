import React from 'react';
import {
  IonLabel,
  IonButtons,
  IonButton,
  IonRow,
  IonGrid,
} from '@ionic/react';
import {
  faFaceSmile,
  faFaceFrown,
  faFaceGrinHearts,
  faFaceMeh,
  // faThumbsUp,
  // faThumbsDown,
  // faHeart,
} from '@fortawesome/free-regular-svg-icons';
// import {
//   faWineGlass,
//   faWineGlassEmpty, // todo: checkout the broken glass on pro
//   faChampagneGlasses,
//   faWineBottle,
// } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const RatingIcon = ({ rating, className }) => {
  switch (rating) {
    case -1:
      return <FontAwesomeIcon icon={faFaceFrown} className={className} />;
    case 0:
      return <FontAwesomeIcon icon={faFaceMeh} className={className} />;
    case 1:
      return <FontAwesomeIcon icon={faFaceSmile} className={className} />;
    case 2:
      return <FontAwesomeIcon icon={faFaceGrinHearts} className={className} />;
    default:
      return null;
  }
};

const SipRating = ({ rating, handleChangeRating, isDisabled = false }) => (
  <IonButtons
    className="rating-buttons ion-justify-content-around"
  >
    <IonButton
      onClick={() => handleChangeRating(-1)}
      disabled={isDisabled}
      color={rating === -1 ? 'primary' : 'medium'}
      size="small"
    >
      <IonGrid className="ion-no-padding">
        <IonRow class="ion-justify-content-center" style={{ paddingBottom: '5px' }}>
          <RatingIcon rating={-1} className="fa-2xl" />
        </IonRow>
        <IonRow>
          <IonLabel>Dislike</IonLabel>
        </IonRow>
      </IonGrid>
    </IonButton>

    <IonButton
      onClick={() => handleChangeRating(0)}
      disabled={isDisabled}
      color={rating === 0 ? 'primary' : 'medium'}
      size="small"
    >
      <IonGrid>
        <IonRow class="ion-justify-content-center" style={{ paddingBottom: '5px' }}>
          <RatingIcon rating={0} className="fa-2xl" />
        </IonRow>
        <IonRow>
          <IonLabel>Meh</IonLabel>
        </IonRow>
      </IonGrid>
    </IonButton>

    <IonButton
      onClick={() => handleChangeRating(1)}
      disabled={isDisabled}
      color={rating === 1 ? 'primary' : 'medium'}
      size="small"
    >
      <IonGrid>
        <IonRow class="ion-justify-content-center" style={{ paddingBottom: '5px' }}>
          <RatingIcon rating={1} className="fa-2xl" />
        </IonRow>
        <IonRow>
          <IonLabel>Like</IonLabel>
        </IonRow>
      </IonGrid>
    </IonButton>

    <IonButton
      onClick={() => handleChangeRating(2)}
      disabled={isDisabled}
      color={rating === 2 ? 'primary' : 'medium'}
      size="small"
    >
      <IonGrid>
        <IonRow class="ion-justify-content-center" style={{ paddingBottom: '5px' }}>
          <RatingIcon rating={2} className="fa-2xl" />
        </IonRow>
        <IonRow>
          <IonLabel>Love</IonLabel>
        </IonRow>
      </IonGrid>
    </IonButton>

  </IonButtons>
);

export default SipRating;
