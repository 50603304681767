import React from 'react';
import {
  IonCard, IonIcon, IonItem, IonLabel, IonButton,
} from '@ionic/react';
// import { useSelector } from 'react-redux';
import { arrowForward } from 'ionicons/icons';
import { useHistory } from 'react-router';
import UserLists from './UserLists';
import './RecentCheckins.scss';

const RecentLists = (props) => {
  // const recentLists = useSelector((state) => state.profile.lists.slice(0, 5));
  const { lists: recentLists } = props;

  const history = useHistory();

  const onListsClick = async (list) => {
    history.push(`/me/lists/${list.id}`);
  };

  return (
    <>
      <IonCard className="ion-no-margin">
        <IonItem lines="full">
          <IonLabel>Recent Lists</IonLabel>
          <IonButton expand="block" onClick={() => history.push('/me/lists')}>
            <IonIcon icon={arrowForward} size="small" slot="icon-only" className="hover-effect" />
          </IonButton>
        </IonItem>
        <UserLists lists={recentLists} onListsClick={onListsClick} />
      </IonCard>
    </>
  );
};

export default RecentLists;
