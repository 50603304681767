import React, { useState } from 'react';
import {
  IonCardContent,
  IonSearchbar,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonChip,
} from '@ionic/react';
import {
  wine,
} from 'ionicons/icons';
import { useDispatch } from 'react-redux';
import { setSelectedLibation } from '../lib/features/listingsSlice';
import WineDetail from './WineDetail';

const ListingWineList = ({ wines }) => {
  const dispatch = useDispatch();

  const [keywords, setKeywords] = useState('');

  const [showDetails, setShowDetails] = useState(false);

  const searchFilter = (item) => (
    keywords === ''
    || item.name.toLowerCase().includes(keywords)
    || item.vintage.toString().includes(keywords)
    || item.varietal?.name.toLowerCase().includes(keywords)
  );

  const handleShowDetails = (libationToShow) => {
    setShowDetails(true);
    dispatch(setSelectedLibation(libationToShow));
  };

  return (
    <>
      {wines.length > 0
        && (
          <IonCardContent style={{ paddingBottom: '0' }} className="ion-no-padding">
            <IonSearchbar
              debounce={100}
              onIonInput={(e) => setKeywords(e.target.value.toLowerCase())}
              autocapitalize="off"
            />
          </IonCardContent>
        )}
      <IonCardContent style={{ maxHeight: '300px', overflow: 'scroll', paddingTop: '5px', paddingBottom: '0' }} className="ion-no-padding">
        <IonList lines="full" className="ion-no-padding">
          {wines.length > 0
            && (wines.filter(searchFilter).map((libation) => (
              <IonItem
                button
                key={libation.id}
                onClick={() => handleShowDetails(libation)}
              >
                <IonChip
                  slot="start"
                  className="wine-chip"
                >
                  <IonIcon
                    icon={wine}
                    size="large"
                    className="ion-no-margin"
                    style={{ color: libation.varietal ? `var(--${libation?.varietal?.color.replace(' ', '-')})` : 'primary-contrast' }}
                  />
                </IonChip>
                <IonLabel style={{ marginTop: '4px', marginBottom: '4px' }}>
                  {`${libation.name}`}
                  <p>{`${libation.vintage} ${libation.varietal?.name || ''}`}</p>
                  <p>{libation.listing?.name || libation.listingName}</p>
                </IonLabel>
              </IonItem>
            )))}
          {wines.length > 0 && wines.filter(searchFilter).length === 0
            && (
              <IonNote className="ion-flex ion-justify-content-center ion-padding-bottom">No wines found. Please refine your search.</IonNote>
            )}
          {wines.length === 0
            && (
              <IonNote className="ion-flex ion-justify-content-center">There are currently no wines listed.</IonNote>
            )}
        </IonList>
      </IonCardContent>
      <IonNote className="ion-flex ion-justify-content-end ion-padding-end">
        <span style={{ paddingTop: '5px', paddingBottom: '5px' }}>
          {wines.filter(searchFilter).length}
          {' '}
          Wines
        </span>
      </IonNote>
      {/* MODAL Wine Detail MODAL */}
      <WineDetail
        isOpen={showDetails}
        onDismiss={() => setShowDetails(false)}
      />
    </>
  );
};

export default ListingWineList;
