import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  IonIcon, IonItem, IonLabel, IonCardContent, IonChip, IonNote, IonList,
} from '@ionic/react';
import { wine } from 'ionicons/icons';
import {
  setSelectedFlight,
  setSelectedLibation,
} from '../lib/features/listingsSlice';
import FlightDetail from './FlightDetail';
import WineDetail from './WineDetail';

const ListingFlightList = (props) => {
  const { flights, dismissFlightListModal } = props;
  const dispatch = useDispatch();

  const [flightDetailIsOpen, setFlightDetailIsOpen] = useState(false);
  const [wineDetailIsOpen, setWineDetailIsOpen] = useState(false);

  const handleShowFlight = (flightToShow) => {
    setFlightDetailIsOpen(true);
    dispatch(setSelectedFlight(flightToShow));
  };

  const handleShowWine = (wineToShow) => {
    dispatch(setSelectedLibation(wineToShow));
    setWineDetailIsOpen(true);
  };

  return (
    <>
      <IonCardContent className="ion-no-padding">
        <IonList lines="full" className="ion-no-padding">
          {flights?.length > 0
            && (flights.map((flight) => (
              <IonItem button detail="false" onClick={() => handleShowFlight(flight)} key={flight.id}>
                <div>
                  {flight.name}
                </div>
                <div slot="end">
                  <IonChip>
                    <IonLabel>{flight.flightLibations ? `${flight.flightLibations.length}` : '0'}</IonLabel>
                    <IonIcon icon={wine} size="small" color="primary" />
                  </IonChip>
                </div>
              </IonItem>
            )))}
          {flights?.length === 0
            && (
              <IonNote className="ion-flex ion-justify-content-center">There are currently no flights listed.</IonNote>
            )}
        </IonList>
      </IonCardContent>

      <FlightDetail
        isOpen={flightDetailIsOpen}
        handleShowWine={handleShowWine}
        onDismiss={() => setFlightDetailIsOpen(false)}
        dismissFlightListModal={dismissFlightListModal}
      />

      <WineDetail
        isOpen={wineDetailIsOpen}
        onDismiss={() => setWineDetailIsOpen(false)}
      />
    </>
  );
};

export default ListingFlightList;
