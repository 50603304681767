import React, { useRef } from 'react';
import {
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonLabel,
  IonList,
  IonNote,
  IonItemOption,
  IonItemOptions,
  useIonAlert,
} from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import client from '../lib/feathers';

import { removeCheckinById } from '../lib/features/profileSlice';
import { makeCheckinGroups } from '../lib/api-utils';

const CheckinList = (props) => {
  const {
    checkins, onCheckinClick, onPublicCheckinClick, allowDelete = true,
  } = props;
  const [present] = useIonAlert();
  const ionListRef = useRef();
  const dispatch = useDispatch();
  const locale = useSelector((state) => state.auth.user.locale || Intl.DateTimeFormat().resolvedOptions().timeZone);

  // Group our Checkins by date:
  const groups = makeCheckinGroups(checkins);

  const checkinClick = (checkin) => {
    if (onCheckinClick) {
      onCheckinClick(checkin);
    } else {
      onPublicCheckinClick(checkin);
    }
  };

  const onDeleteCheckin = async (checkin) => {
    present({
      onDidDismiss: () => {
        ionListRef.current.closeSlidingItems();
      },
      cssClass: 'my-custom-class',
      header: 'Warning',
      message: `Are you sure you want to delete your check-in at ${
        checkin.listing.name
      } on ${moment(checkin.createdAt)
        .tz(locale)
        .format('l')}? This will also delete all photos attached to this check-in.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ionListRef.current.closeSlidingItems();
          },
        },
        {
          text: 'OK',
          handler: async () => {
            const service = client.service('client/checkin');
            await service.remove(checkin.id);

            dispatch(removeCheckinById(checkin.id));
          },
        },
      ],
    });
  };

  const checkinItem = (checkin) => (
    <IonItem
      button
      onClick={(e) => {
        e.preventDefault();
        checkinClick(checkin);
      }}
      lines="none"
    >
      <IonLabel>{checkin.listing.name}</IonLabel>
      <IonNote slot="end">
        {moment(checkin.createdAt)
          .tz(locale)
          .format('l')}
      </IonNote>
    </IonItem>
  );

  const sliderItem = (checkin) => (
    <IonItemSliding key={checkin.createdAt + checkin.listing.name}>
      {checkinItem(checkin)}
      <IonItemOptions side="end">
        <IonItemOption onClick={() => onDeleteCheckin(checkin)}>
          Delete
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );

  return (
    <IonList id="labels-list" lines="full" ref={ionListRef} className={Object.keys(groups).length > 0 && 'ion-no-padding-top'}>
      {Object.keys(groups).length === 0 ? (
        <IonItem lines="none">
          <IonNote> No check-ins found.</IonNote>
        </IonItem>
      ) : (
        <>
          {Object.keys(groups).map((ago) => (
            <IonItemGroup key={ago}>
              <IonItemDivider
                mode="ios"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <IonLabel className="ion-text-capitalize">
                  {ago}
                  &nbsp;-&nbsp;
                  {groups[ago].length}
                  &nbsp;Check-Ins
                </IonLabel>
              </IonItemDivider>
              {groups[ago].map((checkin) => (
                <div key={checkin.createdAt + checkin.listing.name}>
                  {allowDelete ? sliderItem(checkin) : checkinItem(checkin)}
                </div>
              ))}
            </IonItemGroup>
          ))}
        </>
      )}
    </IonList>
  );
};

export default CheckinList;
