import React, { useEffect, useState } from 'react';
import {
  IonAlert, IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonNote, IonPage, IonProgressBar, IonSearchbar, IonTitle, IonToolbar,
} from '@ionic/react';

import { useHistory } from 'react-router';
import {
  add, lockClosed, lockClosedOutline, lockOpenOutline, lockOpenSharp,
} from 'ionicons/icons';
import { useSelector } from 'react-redux';
import client from '../lib/feathers';

const SaveToList = () => {
  const auth = useSelector((state) => state.auth);
  const listings = useSelector((state) => state.listings);
  const history = useHistory();
  const searchInputRef = React.useRef();
  const [keywords, setKeywords] = useState();
  const [lists, setLists] = useState([]);
  const [list, setList] = useState();
  const [showListAdded, setShowListAdded] = useState(false);

  const loadListingLists = async () => {
    // load the lists
    const service = client.service('client/listing-list');

    const query = {
      $limit: 10,
      $sort: {
        createdAt: 1,
      },
      userId: auth.user.id,
    };

    if (keywords && keywords.length) {
      query.name = { $iLike: `%${keywords}%` };
    }

    const result = await service.find({
      query,
    });

    setLists(result.data);
  };

  const loadListsByName = (e) => {
    e.preventDefault();
    loadListingLists();
  };

  const onListClick = async (l) => {
    setList(l);
    const service = client.service('client/listing-list-listing');
    const data = {
      listingId: listings.focusItem.id,
      listingListId: l.id,
      order: l.listingListListings.length,
    };

    await service.create(data);

    setShowListAdded(true);
  };

  const getAccessIcon = (access) => {
    switch (access) {
      case 'private':
        return lockClosed;
      case 'protected':
        return lockClosedOutline;
      case 'unlisted':
        return lockOpenOutline;
      default:
        return lockOpenSharp;
    }
  };

  const onNewListClick = () => {
    history.push('/listing/listForm/new');
  };

  const loadListingListsForUser = () => {
    if (!listings.focusItem) {
      console.log('no focus item in state');
      history.goBack();
      return;
    }

    if (auth.authenticated) {
      loadListingLists();
    }
  };

  const refreshListingListsOnBack = () => {
    if (history.action === 'POP') {
      loadListingLists();
    }
  };

  useEffect(loadListingListsForUser, [listings]);
  useEffect(refreshListingListsOnBack, [history.action]);

  return (

    <IonPage>
      {listings.focusItem && listings.focusItem.name && list && list.name
        && (
          <IonAlert
            isOpen={showListAdded}
            onDidDismiss={() => { setShowListAdded(false); history.goBack(); }}
            cssClass="my-custom-class"
            header="Added To List"
            // subHeader="{listings.focusItem.name}"
            message={`Ok, ${listings.focusItem.name} has been added to ${list.name}`}
            buttons={['OK']}
          />
        )}

      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{listings.focusItem ? listings.focusItem.name : 'Loading Winery Data'}</IonTitle>
          <IonButtons slot="primary">
            <IonButton type="button" tabIndex="0" onClick={onNewListClick}>
              <IonIcon slot="icon-only" icon={add} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>

        {lists
          ? (
            <>
              <form action="#" method="POST" onSubmit={loadListsByName}>
                <IonToolbar color="primary" className="more-padding">
                  <IonSearchbar ref={searchInputRef} placeholder="Search Lists" value={keywords} onIonChange={(e) => setKeywords(e.detail.value)} enterkeyhint="search" inputmode="text" />
                </IonToolbar>
              </form>
              <IonList id="labels-list" lines="full">
                <IonListHeader>My Lists</IonListHeader>
                {lists.length === 0
                  ? (
                    <IonItem lines="none">
                      <IonNote> No lists found. Please create one or refine your search.</IonNote>
                    </IonItem>
                  )
                  : (
                    <>
                      {lists.map((lst) => (
                        <IonItem button key={lst.name} onClick={(e) => { e.preventDefault(); onListClick(lst); }}>
                          <IonLabel>{lst.name}</IonLabel>
                          <IonIcon icon={getAccessIcon(lst.access)} slot="end" />
                        </IonItem>
                      ))}
                    </>
                  )}

              </IonList>
            </>
          )

          : (
            <>
              <IonProgressBar type="indeterminate" />
            </>
          )}
      </IonContent>
    </IonPage>
  );
};

export default SaveToList;
