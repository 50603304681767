import React, { useState } from 'react';
import {
  IonCardContent,
  IonReorderGroup,
  IonList,
  useIonAlert,
  IonItem,
  IonNote,
  useIonLoading,
  IonLabel,
} from '@ionic/react';
import { useDispatch } from 'react-redux';
import { setSelectedCheckin, setSelectedLibation, updateRecentCheckinItem } from '../lib/features/listingsSlice';
import { updateCheckinItem, updateSipItem, removeSipById, addNewSip } from '../lib/features/profileSlice';
import Sip from './Sip';
import SipForm from './SipForm';
import client from '../lib/feathers';
import ListingCheckinSipSearchModal from './ListingCheckinSipSearchModal';
import WineForm from './WineForm';
import WineDetail from './WineDetail';
import VinoMarkdown from './VinoMarkdown';

// AKA, SipCONTROL
const SipList = (props) => {
  const {
    sips,
    checkin,
    searchIsOpen,
    closeSearch,
    allowDelete: isOwner = true,
    updateListingSips,
    atListing = false, // passed if user is looking at specific listing
  } = props;
  const [presentAlert] = useIonAlert();

  const [sipFormIsOpen, setSipFormIsOpen] = useState(false);
  const [wineFormIsOpen, setWineFormIsOpen] = useState(false);
  const [selectedSip, setSelectedSip] = useState();
  const [present, dismiss] = useIonLoading();

  const [wineDetailIsOpen, setWineDetailIsOpen] = useState(false);

  const hasCheckin = !!checkin;

  const dispatch = useDispatch();

  const handleShowSipForm = (sipToShow) => {
    setSipFormIsOpen(true);
    setSelectedSip(sipToShow);
  };

  const handleShowWineDetail = (wineToShow) => {
    setWineDetailIsOpen(true);
    dispatch(setSelectedLibation(wineToShow));
  };

  const updateSipState = (sipData) => {
    let newSipList = [...sips];

    if (sipData.toDelete) {
      dispatch(removeSipById(sipData.id));
      newSipList = newSipList.filter((sip) => sip.id !== sipData.id);
    } else {
      const sIndex = sips.findIndex((item) => item.id === sipData.id);
      if (sIndex === -1) {
        newSipList.push(sipData);
        dispatch(addNewSip(sipData));
      } else {
        newSipList[sIndex] = sipData;
        dispatch(updateSipItem(sipData));
      }
    }

    if (sipData.checkinId) {
      // update profile slice
      dispatch(updateCheckinItem({ id: sipData.checkinId, sips: newSipList }));
      dispatch(updateRecentCheckinItem({ id: sipData.checkinId, sips: newSipList }));
      if (hasCheckin) {
        // update listing slice
        dispatch(setSelectedCheckin({ ...checkin, sips: newSipList }));
      }
    }

    // update sips at a listing
    if (updateListingSips) {
      updateListingSips(newSipList);
    }
  };

  // handle updating state and displaying changes if updating wine details
  const handleUpdateSipWine = (updatedWine) => {
    const updatedSip = { ...selectedSip, libation: updatedWine };
    setSelectedSip(updatedSip);
    updateSipState(updatedSip);
  };

  // handle updating sip in the selected checkin
  const handleUpdateSip = async (updatedSip) => {
    try {
      const service = await client.service('client/sip');
      await service.patch(updatedSip.id, updatedSip);
      updateSipState(updatedSip);
    } catch (err) {
      console.log('Error updating sip:', err);
    }
  };

  // Handle adding selected/new Wine to Sips:
  const handleCreateSip = async (wine) => {
    await present('Creating Sip...');
    let hasExistingSip;

    if (hasCheckin) {
      hasExistingSip = sips.some((sip) => sip.libationId === wine.id);
    }

    if (hasExistingSip) {
      presentAlert({
        header: 'Hold Up!',
        message: `You have already added ${wine.name} to your list of Sips.`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              setWineFormIsOpen(false);
            },
          },
        ],
      });
    } else {
      try {
        const service = await client.service('client/sip');

        const newSipData = {
          libationId: wine.id,
          checkinId: checkin?.id,
          // location: checkin ? checkin.listing.name : null,
        };

        const response = await service.create(newSipData);

        const newSip = { ...response, libation: wine };

        updateSipState(newSip);

        presentAlert({
          header: 'Success!',
          message: `${wine.name} has been added to your list of Sips.`,
          buttons: [
            {
              text: 'OK',
              handler: () => {
                closeSearch();
                setWineFormIsOpen(false);
                if (!hasCheckin) {
                  handleShowSipForm(newSip);
                }
              },
            },
          ],
        });
      } catch (err) {
        console.log('Error updating sip:', err);
      }
    }
    await dismiss();
  };

  // Handle deleting Sip from th Sip list:
  const handleDeleteSip = async (sipToRemove) => {
    try {
      const service = await client.service('client/sip');
      await service.remove(sipToRemove.id);

      updateSipState({ ...sipToRemove, toDelete: true });
    } catch (err) {
      console.log('Error updating sip:', err);
    }
  };

  // Handle re-ordering Sips in Sip List:
  const onReorderItems = async (e, sipsList) => {
    if (hasCheckin) {
      try {
        const sipsListCopy = [...sipsList];
        const draggedSip = sipsListCopy.splice(e.detail.from, 1)[0];
        sipsListCopy.splice(e.detail.to, 0, draggedSip);

        const idSipList = sipsListCopy.map((sip) => sip.id);
        const service = await client.service('client/sip-list-reorder');
        await service.update(checkin.id, idSipList);

        const updatedCheckin = { ...checkin, sips: sipsListCopy };
        // Update Selected Checkin in Listing slice:
        dispatch(setSelectedCheckin(updatedCheckin));
        // Then, update the Checkin in Profile slice:
        dispatch(updateCheckinItem(updatedCheckin));
        e.detail.complete(false);
      } catch (err) {
        console.log('Error updating sip:', err);
      }
    }
  };

  return (
    <>
      <IonList className="ion-no-padding">
        <IonReorderGroup disabled={!hasCheckin || !isOwner} onIonItemReorder={(e) => onReorderItems(e, sips)}>
          {sips?.length > 0
            && (sips.map((sip) => (
              <Sip
                key={sip.id}
                sip={sip}
                showAllInfo={hasCheckin}
                allowDelete={isOwner}
                handleUpdateSip={handleUpdateSip}
                handleDeleteSip={handleDeleteSip}
                showSipDetails={() => handleShowSipForm(sip)}
                showWineDetails={() => handleShowWineDetail(sip.libation)}
              />
            )))}
        </IonReorderGroup>
      </IonList>

      {/* No sips have been made and you are not looking from at a specific listing */}
      {!sips?.length && !atListing
        && (
          <>
            {/* Viewing from a checkin */}
            {hasCheckin ? (
              <IonCardContent>
                <IonLabel>
                  <VinoMarkdown>
                    {isOwner ? 'Never forget a wine you love again' : 'No sips were added from this check-in.'}
                  </VinoMarkdown>
                </IonLabel>
              </IonCardContent>
            ) : (
              // Viewing from a profile (public or private)
              <IonList>
                <IonItem lines="none">
                  <IonNote>No sips found.</IonNote>
                </IonItem>
              </IonList>
            )}
          </>
        )}

      <SipForm
        sip={selectedSip}
        isOpen={sipFormIsOpen}
        allowEdit={isOwner}
        handleUpdateSip={handleUpdateSip}
        handleShowWineDetail={handleShowWineDetail}
        onDismissForm={() => setSipFormIsOpen(false)}
      />

      <WineDetail
        isOpen={wineDetailIsOpen}
        onDismiss={() => setWineDetailIsOpen(false)}
        onDismissSip={() => setSipFormIsOpen(false)}
        handleUpdateSipWine={handleUpdateSipWine}
      />

      {/* conditionally render search and wineform if closeSearch is passed as a prop */}
      {
        !!closeSearch
        && (
          <>
            <ListingCheckinSipSearchModal
              isOpen={searchIsOpen}
              listingId={checkin?.listingId}
              handleCreateSip={handleCreateSip}
              onAddNewWineClick={() => setWineFormIsOpen(true)}
              onDismiss={closeSearch}
            />
            <WineForm
              isOpen={wineFormIsOpen}
              checkin={checkin}
              handleCreateSip={handleCreateSip}
              onDismissForm={() => setWineFormIsOpen(false)}
            />
          </>
        )
      }
    </>
  );
};

export default SipList;
