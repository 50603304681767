import React, { useState, useRef, useEffect } from 'react';
import {
  IonContent,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonItem,
  IonItemDivider,
  IonInput,
  IonLabel,
  useIonAlert,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonIcon,
  useIonLoading,
  useIonModal,
} from '@ionic/react';
import { searchOutline, close } from 'ionicons/icons';
import getVintageList from '../lib/getVintageList';
import client from '../lib/feathers';
import ListingListListingSearch from '../pages/ListingListListingSearch';
import './WineForm.scss';

const WineForm = (props) => {
  const {
    isOpen,
    checkin,
    wineToEdit,
    onDismissForm,
    handleCreateSip,
    handleUpdateWine,
  } = props;

  // state for wine form data:
  const [wineName, setWineName] = useState('');
  const [wineVintage, setWineVintage] = useState();
  const [wineVarietal, setWineVarietal] = useState();
  const [wineType, setWineType] = useState();
  const [wineListing, setWineListing] = useState();
  const [isCustomLocation, setIsCustomLocation] = useState(false);

  const locationRef = useRef();

  const [present, dismiss] = useIonLoading();

  const [varietals, setVarietals] = useState();
  const [types, setTypes] = useState();
  const commonTypes = ['Red', 'White', 'White - Sparkling', 'Rosé', 'Rosé - Sparkling'];
  const vintageList = getVintageList();

  const [presentWarningAlert] = useIonAlert();

  useEffect(() => {
    const setFormDefaultValues = () => {
      setWineListing(wineToEdit?.listing ? { name: wineToEdit?.listing?.name, id: wineToEdit?.listingId } : { name: wineToEdit?.listingName, id: null });
      setIsCustomLocation(!wineToEdit?.listing);
      setWineName(wineToEdit?.name || '');
      setWineVintage(wineToEdit?.vintage || '');
      setWineVarietal(wineToEdit.varietal ? { id: wineToEdit?.varietalId, name: wineToEdit?.varietal?.name, color: wineToEdit?.varietal?.color } : null);
      setWineType(wineToEdit.libationTypeId ? { id: wineToEdit?.libationTypeId, name: wineToEdit.libationType?.name } : null);
    };

    // If we're updating an existing wine,
    // set all default values for the form inputs:
    if (wineToEdit) {
      setFormDefaultValues();
    }

    // If we're creating a new wine from a Check-In,
    // set the default value of wine's listing to the current location:
    if (checkin) {
      setWineListing({ name: checkin?.listing?.name, id: checkin?.listingId });
    }

    // If we have not yet requested the type and varietal data, do so,
    // otherwise we already have the lists and do not need to make another request.
    if (!types || !varietals) {
      const loadVarietals = async () => {
        const service = client.service('client/varietal');
        const response = await service.find();
        setVarietals(response.data);
      };

      const loadTypes = async () => {
        const service = client.service('client/libation-type');
        const response = await service.find();
        setTypes(response.data);
      };

      const loadWineSelectors = () => {
        loadVarietals();
        loadTypes();
      };

      loadWineSelectors();
    }
  }, [wineToEdit]);

  // Modal controller for Listing Search:
  const [presentSearch, dismissSearch] = useIonModal(ListingListListingSearch,
    { // pass the following handler as a prop:
      selectLocation: (data, role) => dismissSearch(data, role),
    });

  // Handle opening search modal, setting location data:
  const openListingSearch = () => {
    presentSearch({
      onWillDismiss: (e) => {
        if (e.detail.role === 'custom') {
          setIsCustomLocation(true);
          setWineListing();
        } else if (e.detail.role === 'confirm') {
          const { name, id } = e.detail.data;
          const listingData = { name, id };
          setIsCustomLocation(false);
          setWineListing(listingData);
        }
      },
    });
  };

  // Clear form state, called on dismissing form:
  const resetForm = () => {
    setWineName();
    setWineVarietal();
    setWineType();
    setWineVintage();
    setWineListing(checkin ? checkin.listing : null);
    setIsCustomLocation(false);
  };

  const showAlert = (textToDisplay) => {
    presentWarningAlert({
      header: 'Hold Up!',
      message: textToDisplay,
      buttons: ['OK'],
    });
  };

  const handleCreateWine = async (newWineData) => {
    await present('Loading...');
    const service = client.service('client/libation');
    const response = await service.create(newWineData);

    // Before creating Sip,
    // add related Varietal, Type, possible Listing(?) data, and createdBy object:
    const newSip = {
      ...response,
      varietal: wineVarietal,
      libationType: wineType,
      listing: response.listingId ? wineListing : null,
      createdBy: { id: response.createdById },
    };

    await dismiss();
    // dispatch -> update listing libations (?)
    handleCreateSip(newSip);
  };

  // Check our database for a Wine/Libation that already exists with these properties:
  const checkSimilarWines = async (wineData) => {
    const service = client.service('client/libation');

    // Create an array of iLike for each word in the Wine's name:
    const terms = wineData.name.split(' ');
    const nameLike = terms.map((term) => ({ name: { $iLike: `%${term}%` } }));

    // Query for any existing wines that contain a given keyword AND the same vintage,
    // and conditionally, are only from the same location; otherwise compare all wines:
    const query = {
      $and: [
        { $or: [...nameLike] },
        { vintage: wineData.vintage },
      ],
    };

    if (checkin) {
      query.$and.push({ listingId: checkin.listingId });
    } else if (wineListing.id) {
      query.$and.push({ listingId: wineListing.id });
    }

    const queryResult = await service.find({ query });
    return queryResult.data || [];
  };

  const checkFormValidation = () => {
    if (!wineListing || wineListing.length === 0) {
      showAlert('Please select a Location for the wine.');
      return false;
    }
    if (!wineName || wineName.length === 0) {
      showAlert('Please enter a Name for the wine.');
      return false;
    }
    if (!wineVintage || wineVintage.length === 0) {
      showAlert('Please enter a Vintage for the wine.');
      return false;
    }
    return true;
  };

  const showSimilarWineAlert = async (newWineData, existingWines) => {
    presentWarningAlert({
      header: 'Heads up!',
      message: `A similar ${newWineData.vintage} wine already exists. Would you like to continue, or create a Sip with one of the following wines instead?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (data) => { // data from selected option:
            if (data.id) { // if selecting an existing wine
              handleCreateSip(data);
            } else { // if continuing with form data
              handleCreateWine(data);
            }
          },
        },
      ],
      inputs: [
        {
          label:
            `Continue with: 
            '${newWineData.name}' • 
            ${newWineData.vintage} •
            ${newWineData.varietalId ? wineVarietal.name : '[No Varietal]'} 
            ${newWineData.libationTypeId ? `[${wineType.name}]` : '[No Type]'}`,
          type: 'radio',
          value: newWineData,
          checked: true,

        },
        ...existingWines.map((wine) => ({
          label:
            `${wine.name} • ${wine.vintage} 
            ${wine.varietalId ? `• ${wine.varietal?.name}` : '[No Varietal]'}
            ${wine.libationTypeId ? `[${wine.libationType.name}]` : '[No Type]'}`,
          type: 'radio',
          value: wine,
        })),
      ],
    });
  };

  const handleSubmitWine = async (e) => {
    e.preventDefault();

    if (!checkFormValidation()) {
      return;
    }

    await present('Loading...');
    try {
      const newWineData = {
        name: wineName,
        vintage: wineVintage,
        needsReview: true,
        varietalId: wineVarietal?.id,
        libationTypeId: wineType?.id,
        // listingId: wineListing.id ? wineListing.id : null,
        // listingName: wineListing.id ? null : wineListing.name,
      };

      // If an existing listing is selected:
      if (wineListing.id) {
        newWineData.listingId = wineListing.id;
        newWineData.listingName = null;
      // Else, user is creating custom listing, add to listingName field:
      } else {
        newWineData.listingId = null;
        newWineData.listingName = wineListing.name;
      }

      // If we're not editing an existing wine:
      if (!wineToEdit) {
        // Check for any wines with similar properties, before creation:
        const similarWines = await checkSimilarWines(newWineData);

        if (similarWines.length) {
          showSimilarWineAlert(newWineData, similarWines);
          await dismiss();
          return;
        }
        handleCreateWine(newWineData);

        // Else, we're editing an existing wine:
      } else {
        await dismiss();

        // When we update the wine, we need the type, varietal, and listing(?) info to be updated in global state as well,
        // so we'll pass through a data object with the necessary updates for our PATCH service,
        // as well as the necessary varietal, type, and possible listing data we'll need to pass to our global state
        const data = {
          updates: newWineData,
          libationType: wineType,
          varietal: wineVarietal,
          listing: wineListing.id ? wineListing : null,
        };
        handleUpdateWine(wineToEdit.id, data);
      }
    } catch (err) {
      showAlert('Whoops! Something went wrong. Try again.');
      console.log('err', err);
      await dismiss();
    }
  };

  // Dismiss the Wine Form, but not our Search modal:
  const dismissForm = () => {
    onDismissForm();
    if (!wineToEdit) {
      resetForm();
    }
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={dismissForm}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={dismissForm}>Cancel</IonButton>
          </IonButtons>
          <IonTitle class="ion-text-center">
            {wineToEdit ? 'Update Wine' : 'Add New Wine'}
          </IonTitle>
          <IonButtons slot="primary">
            <IonButton strong type="button" onClick={handleSubmitWine}>Submit</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h2>{wineToEdit ? 'Updating your wine?' : "Can't find what you had in mind?"}</h2>
        <p>
          When
          {wineToEdit ? ' updating a wine' : ' adding a new wine'}
          , please follow the guidelines below:
        </p>
        <h5>Naming</h5>
        <ul>
          <li>Don&apos;t include the name of the Winery with the name of the wine.</li>
          <li>Don&apos;t include the vintage within the wine&apos;s name. Keep it separate!</li>
          <li>Please Name Your Wine Using Proper Case.</li>
        </ul>
        <br />

        <form onSubmit={handleSubmitWine}>
          <IonItemDivider>Winery</IonItemDivider>
          {!isCustomLocation
            && (
              <IonItem lines="full" onClick={openListingSearch}>
                <IonLabel style={{ '--color': wineListing?.name ? 'primary' : 'grey' }}>
                  {wineListing?.name || 'Search Winery Listings'}
                </IonLabel>
                <IonIcon slot="end" size="small" icon={searchOutline} />
              </IonItem>
            )}
          {isCustomLocation
            && (
              <IonItem lines="full">
                <IonInput
                  ref={locationRef}
                  value={wineListing?.name}
                  onIonChange={(e) => setWineListing({ name: e.detail.value })}
                  placeholder="Winery Name"
                  type="text"
                  autocapitalize="on"
                  required
                />
                <IonIcon slot="end" size="small" icon={close} onClick={() => { setIsCustomLocation(false); setWineListing(); }} />
              </IonItem>
            )}

          <IonItemDivider>Name</IonItemDivider>
          <IonItem lines="full">
            <IonInput
              value={wineName}
              onIonChange={(e) => setWineName(e.detail.value)}
              placeholder="Wine Name"
              type="text"
              autocapitalize="words"
              required
            />
          </IonItem>

          <IonItemDivider>
            Vintage
          </IonItemDivider>
          <IonItem lines="full">
            <IonSelect
              placeholder="Select a Year"
              interfaceOptions={{ header: 'Vintage', message: 'Select the Year' }}
              value={wineVintage}
              onIonChange={(e) => setWineVintage(e.detail.value)}
            >
              {vintageList.map((year) => (
                <IonSelectOption key={year} value={year}>{year}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItemDivider>
            Type (Optional)
          </IonItemDivider>
          <IonItem lines="full">
            {types
              ? (
                <IonSelect
                  interfaceOptions={{ header: 'Type', message: 'Select the Type of Wine' }}
                  placeholder="Select the Type of Wine"
                  value={wineType}
                  onIonChange={(e) => setWineType(e.detail.value)}
                  compareWith={(t1, t2) => (t1 && t2 ? t1.id === t2.id : t1 === t2)}
                >
                  <IonSelectOption disabled class="option-group-label">Common</IonSelectOption>
                  {commonTypes.map((label) => {
                    const type = types.find((t) => t.name === label);
                    return (
                      <IonSelectOption key={type.id} value={type}>{type?.name}</IonSelectOption>
                    );
                  })}
                  <IonSelectOption disabled class="option-group-label">All</IonSelectOption>
                  {types.map((type) => (
                    <IonSelectOption key={type.id} value={type}>{type?.name}</IonSelectOption>
                  ))}
                </IonSelect>
              )
              : (
                <IonItem lines="full">
                  <IonLabel>No types found at the moment... Please continue</IonLabel>
                </IonItem>
              )}
          </IonItem>

          <IonItemDivider>
            Varietal (Optional)
          </IonItemDivider>
          <IonItem lines="full">
            {varietals
              ? (
                <IonSelect
                  interfaceOptions={{ header: 'Varietal', message: 'Select the Varietal' }}
                  placeholder="Select the Varietal"
                  value={wineVarietal}
                  onIonChange={(e) => setWineVarietal(e.detail.value)}
                  compareWith={(v1, v2) => (v1 && v2 ? v1.id === v2.id : v1 === v2)}
                >
                  {varietals.map((varietal) => (
                    <IonSelectOption key={varietal.id} value={varietal}>{varietal?.name}</IonSelectOption>
                  ))}
                </IonSelect>
              )
              : (
                <IonItem lines="full">
                  <IonLabel> No varietals found at the moment... Please continue</IonLabel>
                </IonItem>
              )}
          </IonItem>

        </form>

      </IonContent>
    </IonModal>
  );
};

export default WineForm;
