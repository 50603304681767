import React, { useState, useEffect, createRef } from 'react';
import { useIonViewWillEnter } from '@ionic/react';

// openlayers
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat, toLonLat } from 'ol/proj';
import { DragZoom, DoubleClickZoom, defaults as defaultInteractions } from 'ol/interaction';

import './MapWrapper.css';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { getCenter } from 'ol/extent';
// import Fill from 'ol/style/Fill';
import { Overlay } from 'ol';
// import XYZ from 'ol/source/XYZ';

const drawFeatures = (map, featuresLayer, features, mapCenter) => {
  if (featuresLayer && features) {
    if (features.length === 0) {
      featuresLayer.getSource().clear();
    } else {
      featuresLayer.setSource(
        new VectorSource({
          features,
        }),
      );
      // console.log('draw', mapCenter);
      if (mapCenter && mapCenter === 'fit') {
        map.getView().fit(featuresLayer.getSource().getExtent(), {
          padding: [50, 10, 10, 10],
        });
      }
    }
  }
};

const MapWrapper = (props) => {
  const {
    onMapMarkerClick, homeMarker, features, onMapMove, selectedVenue, mapCenter,
  } = props;
  const [map, setMap] = useState();
  const [featuresLayer, setFeaturesLayer] = useState();
  const [homeLayer, setHomeLayer] = useState();
  const [dirtyMap, setDirtyMap] = useState(false);

  const draggingStateRef = React.useRef(false);
  // const [, setView ] = useState()

  const mapElement = createRef();
  const popupElement = createRef();

  const layoutMap = async () => {
    const initalFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
    });
    const homeFeaturesLayer = new VectorLayer({
      source: new VectorSource(),
    });

    const view = new View({
      projection: 'EPSG:3857',
      center: fromLonLat([-101.309360, 39.400122]),
      zoom: 4,
      maxZoom: 19,
      enableRotation: false,
    });

    const popup = new Overlay({
      id: 'popup',
      element: popupElement.current,
      positioning: 'top-center',
    });

    const raster = new TileLayer({
      source: new OSM(),
    });

    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        // new TileLayer({
        //   source: new XYZ({
        //     url: "http://localhost:5000/styles/basic-preview/{z}/{x}/{y}.png",
        //     attributions: "Tiles by Open Street Maps",
        //   }),
        // }),
        // new TileLayer({
        //   source: new XYZ({
        //     url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        //     attributions: "Tiles by Open Street Maps",
        //   }),
        // }),
        // new TileLayer({
        //   source: new XYZ({
        //     // url: "https://tile.thunderforest.com/landscape/{z}/{x}/{y}.png?apikey=c35a9a5391864187a941331cbb24be36",
        //     url: "https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=c35a9a5391864187a941331cbb24be36",
        //     // url: "https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=c35a9a5391864187a941331cbb24be36",
        //     // url: 'https://api.maptiler.com/maps/outdoor/{z}/{x}/{y}.RenderingFormat.PNG?key=lk10DS7SH4CTGf9H4lfH',
        //     attributions: "Tiles by Open Street Maps",
        //   }),
        // }),

        // new TileLayer({
        //   source: new XYZ({
        //     format: new MVT(),
        //     url: "https://{a-b}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
        //     // url: "https://{a-d}.tiles.mapbox.com/v4/mapbox.mapbox-streets-v6/{z}/{x}/{y}.vector.png?access_token=pk.eyJ1Ijoic2ltYmNvIiwiYSI6ImNra2lwam9icjA2cnIyd2toM2o2azVyZ3oifQ.aKRr50hm52OfZ4Ahzw_PIg",
        //   }),
        // }),
        raster,
        initalFeaturesLayer,
        homeFeaturesLayer,
      ],
      view,
      controls: [],
      overlays: [popup],
      interactions: defaultInteractions().extend([new DragZoom(), new DoubleClickZoom()]),
    });

    setMap(initialMap);
    setFeaturesLayer(initalFeaturesLayer);
    setHomeLayer(homeFeaturesLayer);
    // setView(view);

    drawFeatures(initialMap, initalFeaturesLayer, features, mapCenter);

    initialMap.on('moveend', () => {
      if (draggingStateRef.current) {
        onMapMove(toLonLat(getCenter(initialMap.getView().calculateExtent())));
      }
      draggingStateRef.current = false;
    });

    initialMap.on('pointermove', (evt) => {
      if (evt.dragging) {
        draggingStateRef.current = true;
      }
    });

    initialMap.on('singleclick', async (event) => {
      const feature = await initalFeaturesLayer.getFeatures(event.pixel);
      if (!feature[0]) {
        popup.setPosition(undefined);
        return;
      }
      const venueId = feature[0].getId();
      const coordinates = feature[0].getGeometry().getCoordinates();
      popup.setPosition(coordinates);
      onMapMarkerClick(venueId);
    });

    setDirtyMap(true);
  };
  useEffect(layoutMap, []);

  useIonViewWillEnter(() => {
    setDirtyMap(true);
  });

  function renderMap() {
    if (mapElement.current && mapElement.current.clientWidth === 0) {
      setTimeout(renderMap, 50);
    } else {
      map.updateSize();
      setDirtyMap(false);
    }
  }

  const renderMapWhenDirty = () => {
    if (dirtyMap) {
      renderMap();
    }
  };
  useEffect(renderMapWhenDirty, [dirtyMap]);

  const drawFeaturesOnDataChange = async () => {
    if (featuresLayer && features) {
      drawFeatures(map, featuresLayer, features, mapCenter);
    }
  };
  useEffect(drawFeaturesOnDataChange, [featuresLayer, features]);

  const setHomeAndCenter = async () => {
    if (homeLayer && homeMarker) {
      homeLayer.setSource(
        new VectorSource({
          features: [homeMarker],
        }),
      );
      const point = homeMarker.getGeometry();
      map.getView().fit(point, { padding: [150, 150, 150, 150], minResolution: 50 });
    }
  };
  useEffect(setHomeAndCenter, [homeLayer, homeMarker, mapCenter]);

  const setMapToSelectedListing = () => {
    if (selectedVenue) {
      features.forEach((f) => {
        if (selectedVenue.name === f.get('name')) {
          map.getOverlayById('popup').setPosition(f.getGeometry().getCoordinates());
        }
      });

      const options = {
        center: fromLonLat([selectedVenue.lng, selectedVenue.lat]),
      };

      if (map.getView().getZoom() < 12) {
        options.zoom = 12;
      }

      map.getView().animate(options);
    }
  };
  useEffect(setMapToSelectedListing, [map, selectedVenue]);

  useEffect(() => {
    // console.log('center', mapCenter);
  }, [mapCenter]);

  return (
    <>
      <div ref={mapElement} className="map-container" />
      <div ref={popupElement} id="popup" className="popup">
        <div id="popup-content">
          <h5>{selectedVenue && selectedVenue.name}</h5>
        </div>
      </div>
    </>
  );
};

export default MapWrapper;
