import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lists: [],
  checkins: [],
  sips: [],
  counts: undefined,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      const { listingLists, checkins, sips, attachments, counts } = action.payload;
      state.lists = listingLists;
      state.checkins = checkins;
      state.sips = sips;
      state.attachments = attachments;
      state.counts = counts;
    },
    // *** LISTS ***
    setLists: (state, action) => {
      state.lists = action.payload;
    },
    removeListById: (state, action) => {
      const deletedId = action.payload;
      const updatedList = [...state.lists].filter((listItem) => listItem.id !== deletedId);
      state.lists = updatedList;
    },
    updateListItem: (state, action) => {
      const updatedListItem = action.payload;

      const listIndex = state.lists.findIndex((list) => list.id === updatedListItem.id);
      const updatedList = [...state.lists];
      const oldListItem = updatedList[listIndex];

      // Update the object in our List at its original index, while making sure to maintain necessary properties.
      updatedList[listIndex] = { ...oldListItem, ...updatedListItem };

      state.lists = updatedList;
    },
    addNewListToLists: (state, action) => {
      const listToAdd = action.payload;
      const updatedList = [...state.lists];
      updatedList.unshift(listToAdd);
      state.lists = updatedList;
    },
    // *** CHECKINS ***
    setCheckins: (state, action) => {
      state.checkins = action.payload;
    },
    removeCheckinById: (state, action) => {
      const deletedId = action.payload;
      const updatedCheckins = [...state.checkins].filter((listItem) => listItem.id !== deletedId);
      state.checkins = updatedCheckins;
    },
    updateCheckinItem: (state, action) => {
      const updatedCheckinItem = action.payload;

      const checkinIndex = state.checkins.findIndex((checkin) => checkin.id === updatedCheckinItem.id);
      const updatedCheckins = [...state.checkins];
      const oldCheckinItem = updatedCheckins[checkinIndex];

      // Update the object in our Checkin at its original index, while making sure to maintain necessary properties.
      updatedCheckins[checkinIndex] = { ...oldCheckinItem, ...updatedCheckinItem };
      state.checkins = updatedCheckins;
    },
    addNewCheckin: (state, action) => {
      const checkinToAdd = action.payload;
      const updatedCheckins = [...state.checkins];
      updatedCheckins.unshift(checkinToAdd);
      state.checkins = updatedCheckins;
    },
    // *** SIPS ***
    setSips: (state, action) => {
      state.sips = action.payload;
    },
    removeSipById: (state, action) => {
      const deletedId = action.payload;
      const updatedSips = [...state.sips].filter((sip) => sip.id !== deletedId);
      state.sips = updatedSips;
    },
    updateSipItem: (state, action) => {
      const updatedSipItem = action.payload;

      const sipIndex = state.sips.findIndex((sip) => sip.id === updatedSipItem.id);
      const updatedSips = [...state.sips];
      const oldSipItem = updatedSips[sipIndex];

      // Update the object in our Sip at its original index, while making sure to maintain necessary properties.
      updatedSips[sipIndex] = { ...oldSipItem, ...updatedSipItem };
      state.sips = updatedSips;
    },
    addNewSip: (state, action) => {
      const sipToAdd = action.payload;
      const updatedSips = [...state.sips];
      updatedSips.unshift(sipToAdd);
      state.sips = updatedSips;
    },
  },
});

export const {
  setProfile,
  setLists,
  removeListById,
  updateListItem,
  addNewListToLists,
  setCheckins,
  removeCheckinById,
  updateCheckinItem,
  addNewCheckin,
  setSips,
  removeSipById,
  updateSipItem,
  addNewSip,
} = profileSlice.actions;

export default profileSlice.reducer;
