import React, { useState } from 'react';
import {
  IonModal,
  IonContent,
  IonButtons,
  IonButton,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  useIonAlert,
  IonNote,
  useIonLoading,
} from '@ionic/react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { add, arrowBackSharp } from 'ionicons/icons';
import {
  setSelectedCheckin,
  updateLibation,
} from '../lib/features/listingsSlice';
import { updateCheckinItem, addNewSip } from '../lib/features/profileSlice';
import client from '../lib/feathers';
import VinoMarkdown from './VinoMarkdown';
import WineForm from './WineForm';
import ListingHeader from './ListingHeader';

// If using WineDetail modal, be sure to dispatch(setSelectedLibation)
const WineDetail = (props) => {
  const {
    isOpen,
    onDismiss,
    onDismissSip,
    handleUpdateSipWine,
  } = props;

  // paramID is either: listingId, or, checkinId
  const { id: paramId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    selectedCheckin: checkin,
    hasRecentCheckin,
    recentCheckins,
    selectedLibation: wine,
  } = useSelector((state) => state.listings);

  const auth = useSelector((state) => state.auth);
  const mostRecentCheckin = ([...recentCheckins].reverse())[0];

  const [wineFormIsOpen, setWineFormIsOpen] = useState(false);

  const [present, dismiss] = useIonLoading();

  // todo: make these inline alerts
  const [presentCheckinAlert] = useIonAlert();
  const [presentConfirm] = useIonAlert();
  const [presentAlert] = useIonAlert();

  const handleUpdateWine = async (wineId, data) => {
    await present('Loading...');

    const { updates, varietal, libationType, listing } = data;

    const service = client.service('client/libation');
    const response = await service.patch(wineId, updates);
    const newWineData = {
      ...response,
      varietal,
      libationType,
      listing,
      createdBy: { id: response.createdById },
    };

    dispatch(updateLibation(newWineData));
    // dispatch to update appropriate slices to reflect new changes

    if (handleUpdateSipWine) {
      handleUpdateSipWine(newWineData);
    }

    await dismiss();

    presentConfirm({
      header: 'Success!',
      message: `${newWineData.name} has been updated. You may need to manually refresh to reflect the latest changes.`,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            setWineFormIsOpen(false);
            onDismiss();
          },
        },
      ],
    });
  };

  const handleCreateSip = async (checkinToUpdate = undefined) => {
    await present('Loading...');
    let hasExistingSip;

    if (checkinToUpdate) {
      hasExistingSip = checkinToUpdate.sips.some((sip) => sip.libationId === wine.id);
    }

    if (hasExistingSip) {
      presentAlert({
        header: 'Hold Up!',
        message: `You have already added ${wine.name} to your list of Sips.`,
        buttons: [
          {
            text: 'OK',
          },
        ],
      });
    } else {
      const service = await client.service('client/sip');

      const newSipData = {
        libationId: wine.id,
        checkinId: checkinToUpdate?.id,
      };

      const response = await service.create(newSipData);

      const newSip = { ...response, libation: wine };

      // If creating through a Check-In, update that Check-In:
      if (checkinToUpdate) {
        const updatedCheckin = { ...checkinToUpdate, sips: [...checkinToUpdate.sips, newSip] };
        dispatch(setSelectedCheckin(updatedCheckin)); // update listing slice
        dispatch(updateCheckinItem(updatedCheckin)); // update profile slice
      }
      dispatch(addNewSip(newSip)); // update profile slice

      presentAlert({
        header: 'Success!',
        message: `${wine.name} has been added to your list of Sips.`,
        buttons: [
          {
            text: 'OK',
            handler: () => {
              onDismiss();
            },
          },
        ],
      });
    }
    await dismiss();
  };

  const showCheckinAlert = () => {
    presentCheckinAlert({
      header: 'Add Sip',
      message: 'Would you like to associate this Sip with your current Check-In?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            // Add to overall Sips (profile).
            handleCreateSip();
          },
        },
        {
          text: 'Yes',
          handler: () => {
            // Add to Check-In Sips.
            handleCreateSip(checkin ?? mostRecentCheckin);
          },
        },
      ],
    });
  };

  // Check if the user has a recent check in, or is adding to a previous check-in
  const handleCheckinStatus = () => {
    const hasCheckinToUpdate = (checkin?.id === paramId) || (hasRecentCheckin && (mostRecentCheckin.listingId === paramId));
    if (hasCheckinToUpdate) {
      if (checkin?.id === paramId) {
        handleCreateSip(checkin);
      } else {
        showCheckinAlert();
      }
    } else {
      handleCreateSip();
    }
  };

  const showAddSipAlert = () => {
    presentConfirm({
      header: 'Add Sip',
      message: 'Would you like to add this wine to your Sips?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: () => {
            handleCheckinStatus();
          },
        },
      ],
    });
  };

  const showAuthAlert = () => {
    presentConfirm({
      header: 'Hold Up!',
      message: 'Silly Seeker, Sips are for users. Login to manage your Sips.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Login',
          handler: () => {
            history.push('/login');
          },
        },
      ],
    });
  };

  const checkUser = () => {
    if (!auth.authenticated) {
      // Need to log in.
      showAuthAlert();
    } else {
      // Want to add this to your Sips?
      showAddSipAlert();
    }
  };

  const dismissModals = () => {
    onDismiss();
    if (onDismissSip) {
      onDismissSip();
    }
  };

  const handleNavigateListing = () => {
    if (wine.listing?.id) {
      dismissModals();
      history.push(`/listing/${wine.listing.id}`);
    }
  };

  const DetailItem = ({ label, value }) => (
    <IonItem lines="full">
      <IonLabel slot="start">{label}</IonLabel>
      <IonLabel>{value}</IonLabel>
    </IonItem>
  );

  const canBeEdited = wine && auth.user && (auth.user.id === wine.createdBy?.id) && wine.needsReview;

  return (
    <>
      <IonModal isOpen={isOpen}>
        {wine
          && (
            <>
              <IonHeader>
                <IonToolbar>
                  <IonButtons slot="start">
                    <IonButton onClick={onDismiss}>
                      <IonIcon icon={arrowBackSharp} className="ion-size-md-sm" color="primary" />
                    </IonButton>
                  </IonButtons>
                  <IonTitle class="ion-text-center">
                    Wine Detail
                  </IonTitle>
                  <IonButtons slot="primary">
                    <IonButton strong type="button" onClick={checkUser}>
                      <IonIcon icon={add} size="large" color="primary" />
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonHeader>
              <IonContent>
                {/* WINE CARD */}
                <IonCard>
                  <IonCardHeader mode="ios">
                    <IonCardTitle className="ion-flex ion-justify-content-between ion-align-items-center">
                      {wine.name || 'Wine'}
                    </IonCardTitle>
                    <IonNote>
                      {wine.listing?.name || wine.listingName}
                    </IonNote>
                  </IonCardHeader>
                  {/* DESCRIPTION */}
                  {wine.description !== null
                    && (
                      <IonCardContent>
                        <IonItem lines="none" className="ion-no-padding">
                          <VinoMarkdown>
                            {wine.description}
                          </VinoMarkdown>
                        </IonItem>
                      </IonCardContent>
                    )}
                  {wine.tastingNotes !== null
                    && (
                      <>
                        <IonCardContent className="ion-no-padding-top">
                          <IonNote>Tasting Notes</IonNote>
                          <IonItem lines="none" className="ion-no-padding">
                            <VinoMarkdown>
                              {wine.tastingNotes}
                            </VinoMarkdown>
                          </IonItem>
                        </IonCardContent>
                      </>
                    )}
                  <IonCardContent className="ion-no-padding-top">
                    <IonNote>Details</IonNote>
                    <IonList>
                      <DetailItem label="Vintage" value={wine.vintage} />
                      <DetailItem label="Type" value={wine.libationType?.name || '-'} />
                      <DetailItem label="Variety" value={wine.varietal?.name || '-'} />
                      <DetailItem label="AVA" value={wine.ava || '-'} />
                      <DetailItem label="Alcohol" value={wine.abv ? `${wine.abv}%` : '-'} />
                      <DetailItem label="Price" value={wine.msrp ? `$${wine.msrp}` : '-'} />
                    </IonList>
                  </IonCardContent>
                </IonCard>
                {/* LISTING TITLE CARD */}
                <IonCard onClick={handleNavigateListing}>
                  <ListingHeader listingData={wine?.listing || wine?.listingName} listingId={wine?.listingId} height="13vh" />
                </IonCard>
                {canBeEdited
                  && (
                    <div className="ion-padding">
                      <IonButton expand="block" onClick={() => setWineFormIsOpen(true)}>Edit</IonButton>
                    </div>
                  )}
              </IonContent>
            </>
          )}
      </IonModal>
      {isOpen && canBeEdited
        && (
          <WineForm
            isOpen={wineFormIsOpen}
            onDismissForm={() => setWineFormIsOpen(false)}
            wineToEdit={wine}
            handleUpdateWine={handleUpdateWine}
          />
        )}
    </>
  );
};

export default WineDetail;
