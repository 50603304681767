import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  friends: [],
  invites: [],
};

export const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    setFriends: (state, action) => {
      state.friends = action.payload;
    },
    setInvites: (state, action) => {
      state.invites = action.payload;
    },
  },
});

export const { setFriends, setInvites } = friendsSlice.actions;

export default friendsSlice.reducer;
