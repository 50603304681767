import React from 'react';
import '../InfoModal.scss';
import { Swiper, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import { Pagination } from 'swiper';

import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonLabel,
} from '@ionic/react';

const Checkins = () => (
  <div className="wrapper">
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonLabel>
            <IonText class="ion-text-center">
              <h1><strong>Check-in when you arrive</strong></h1>
            </IonText>
            <IonText class="ion-text-center">
              Save your visit and remember your experience
            </IonText>
          </IonLabel>
        </IonCol>
      </IonRow>
      <Swiper
        modules={[Pagination]}
        pagination
      >
        <SwiperSlide>
          <IonRow>
            <IonCol>
              <IonImg src="../../assets/infoPopup/checkinSummary.png" alt="checkin summary" class="checkin-summary-individual" />
            </IonCol>
            {/* <IonCol className="image-with-subslides">
              <IonImg src="../../assets/infoPopup/checkinList.png" alt="checkin list" class="md" />
            </IonCol> */}
          </IonRow>
        </SwiperSlide>
        <SwiperSlide className="image-with-subslides">
          <IonRow>
            <IonCol>
              <IonImg src="../../assets/infoPopup/checkinList.png" alt="checkin list" class="md checkin-list-individual" />
            </IonCol>
          </IonRow>
        </SwiperSlide>
      </Swiper>

      <IonRow class="ion-justify-content-center">
        <IonLabel>
          <IonText class="ion-text-center">
            <h2><strong>And you can take notes about your visit</strong></h2>
          </IonText>
          <IonText class="ion-text-center">
            <p><em>Remind yourself what you enjoyed, your favorite dish and who you enjoyed your visit with</em></p>
          </IonText>
        </IonLabel>
      </IonRow>
    </IonGrid>
  </div>
);

export default Checkins;
